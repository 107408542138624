import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import {
  GrantDashboard,
  GrantDetailsDashboard,
  ProgramDashboard,
  ProgramDetailsDashboard,
} from "../interfaces/interfaces";

const getDashboards = async (
  companyId: string | undefined
): Promise<{
  programDashboard: ProgramDashboard[];
  grantDashboard: GrantDashboard[];
}> => {
  return httpClient
    .get(`company/${companyId}/dashboard`)
    .then((response: AxiosResponse) => response.data);
};

const getProgramDetails = async ({
  companyId,
  programCode,
}: {
  companyId: string | undefined;
  programCode: string | undefined;
}): Promise<ProgramDetailsDashboard> => {
  return httpClient
    .get(`company/${companyId}/dashboard/details-by-program/${programCode}`)
    .then((response: AxiosResponse) => response.data);
};
const getGrantsDetails = async ({
  companyId,
  grantCode,
  periodStart,
  periodEnd,
}: {
  companyId: string | undefined;
  grantCode: string | undefined;
  periodStart?: string;
  periodEnd?: string;
}): Promise<GrantDetailsDashboard> => {
  return httpClient
    .get(
      `company/${companyId}/dashboard/details-by-grant/${grantCode}/${
        periodStart ? `?periodStart=${periodStart}&periodEnd=${periodEnd}` : ""
      }`
    )
    .then((response: AxiosResponse) => response.data);
};

const dashboardApi = {
  getDashboards,
  getProgramDetails,
  getGrantsDetails,
};
export default dashboardApi;
