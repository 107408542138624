import React, { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AxiosResponse } from "axios";
import { USER_ROLE_LABELS, USER_ROLES, ROUTE_PATHS } from "../../../../consts";
import documentsApi from "../../../../api/documents-api";
import fileApi from "../../../../api/file-api";
import { getCompanyUrl } from "../../../companies/utils";
import { CompanyContext } from "../../../../context/company-context";
import { UserContext } from "../../../../context/user.context";
import { DownloadButtonComponent } from "../../../../components/shared/download-button/download-button.component";
import UploadButtonComponent from "../../../../components/shared/upload-button/upload-button.component";
import "./action-header.component.scss";

interface ActionHeaderProps {
  periodsToGrants: { [key: string]: Array<string> };
  selectedPeriod: string;
  setSelectedPeriod: (period: string) => void;
  selectedGrant: string;
  setSelectedGrant: (grant: string) => void;
  setShowUploadStatus: (status: boolean) => void;
  setFoundInvoices: (invoices: { number: number }) => void;
}

export default function ActionHeaderComponent({
  periodsToGrants,
  selectedPeriod,
  setSelectedPeriod,
  selectedGrant,
  setSelectedGrant,
  setShowUploadStatus,
  setFoundInvoices,
}: ActionHeaderProps): JSX.Element {
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isUserAdmin = user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN];
  const areActionsEnabled = useMemo(
    () => selectedGrant && selectedPeriod,
    [selectedGrant, selectedPeriod]
  );
  return (
    <div className="documents__header">
      <div className="d-flex justify-content-end align-items-end gap-5">
        <div>
          <label htmlFor="period">{t("company.tabs.documents.period")}</label>
          <div className="select-wrapper">
            <select
              onChange={(e) => {
                setShowUploadStatus(false);
                setSelectedPeriod(e.target.value);
              }}
              className="form-select"
              id="period"
            >
              <option value="">None</option>
              {Object.keys(periodsToGrants).map((period) => (
                <option key={period} value={period.toString()}>
                  {period}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="grant">{t("company.tabs.documents.grant")}</label>
          <div className="select-wrapper">
            <select
              onChange={(e) => {
                setShowUploadStatus(false);
                setSelectedGrant(e.target.value);
              }}
              className="form-select"
              id="grant"
            >
              <option value="">None</option>
              {selectedPeriod &&
                periodsToGrants[selectedPeriod]?.map((grant) => (
                  <option key={grant} value={grant}>
                    {grant}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <UploadButtonComponent
          title={t("company.tabs.documents.upload")}
          disabled={!areActionsEnabled}
          multiple
          upload={(files): Promise<AxiosResponse> => {
            const formData = new FormData();
            const fileLength = files.length;
            for (let x = 0; x < fileLength; x++) {
              formData.append("file", files[x]);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return documentsApi
              .uploadInvoices(
                formData,
                company.id,
                selectedPeriod,
                selectedGrant
              )
              .then((data) => {
                setShowUploadStatus(true);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setFoundInvoices(data);
              });
          }}
        />
        <DownloadButtonComponent
          label={t("company.tabs.documents.download")}
          disabled={!areActionsEnabled}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${company.id}/document-management/download-file?accountingPeriod=${selectedPeriod}&grantCode=${selectedGrant}`
            )
          }
        />
        <button
          onClick={() =>
            navigate(
              isUserAdmin
                ? `${getCompanyUrl(company.id)}/documents/settings`
                : "/documents/settings"
            )
          }
          className="btn btn-primary"
        >
          {t("general.settings")}
        </button>
      </div>
    </div>
  );
}
