import React from "react";
import { formatNumber } from "../../../../utils";
import { TableProps } from "../table.component";
import { useTranslation } from "react-i18next";

interface TotalRowProps<T> {
  columns: TableProps<T>["columns"];
  title?: string;
  columnsPositions: Record<number, string | number>;
  totalCounts: Record<number, number>;
  freezeColumnsNumber?: number;
}

export default function TotalRow<T>({
  columns,
  title,
  totalCounts,
  columnsPositions,
  freezeColumnsNumber,
}: TotalRowProps<T>): JSX.Element {
  const { t } = useTranslation();

  return (
    <tr className="total-data">
      {columns.map(({ freeze, textAlign }, index) => {
        const getValue = () => {
          if (index === 0) {
            return title || t("general.total");
          }
          return totalCounts[index] ? formatNumber(totalCounts[index]) : "-";
        };
        return (
          <td
            style={{
              ...(freeze && { left: columnsPositions[index] }),
              ...(textAlign && { textAlign }),
            }}
            className={`${freeze ? "freeze" : ""} ${
              freezeColumnsNumber && freezeColumnsNumber - 1 === index
                ? "show-shadow"
                : ""
            }`}
            key={index}
          >
            {getValue()}
          </td>
        );
      })}
    </tr>
  );
}
