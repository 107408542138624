import React from "react";
import "./modal.component.scss";
import Modal from "../modal/modal.component";

interface CompanyModalInterface {
  title: string;
  bodyData: string;
  onCancel: () => void;
  onSubmit: () => void;
  submitTitle: string;
  cancelTitle: string;
  show: boolean;
}

export default function CreateEntityModal({
  show,
  title,
  bodyData,
  onCancel,
  onSubmit,
  submitTitle,
  cancelTitle,
}: CompanyModalInterface): JSX.Element {
  return (
    <Modal show={show} title={title}>
      <div>{bodyData}</div>
      <div className="gap-2 modal-buttons">
        <button onClick={onCancel} className="btn w-50 btn-secondary">
          {cancelTitle}
        </button>
        <button onClick={onSubmit} className="btn w-50 btn-primary">
          {submitTitle}
        </button>
      </div>
    </Modal>
  );
}
