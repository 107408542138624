import React, { useState } from "react";
import { TableProps } from "../table.component";
import Row from "../row/row.component";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

interface ExpandableRowProps<T> {
  columns: TableProps<T>["columns"];
  columnsPositions: Record<number, string | number>;
  row: T;
  onRowClick?: (row: T) => void;
  isRowActive?: (row: T) => boolean;
  rowIndex: number;
  expandedContent?: ({ data }: { data: T }) => JSX.Element;
  className?: (data: T) => string;
  freezeColumnsNumber?: number;
}
export default function ExpandableRow<T>({
  columns,
  columnsPositions,
  row,
  isRowActive,
  rowIndex,
  expandedContent,
  className,
  freezeColumnsNumber,
}: ExpandableRowProps<T>): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <>
      <Row
        freezeColumnsNumber={freezeColumnsNumber}
        onRowClick={() => setIsExpanded((state) => !state)}
        columns={columns}
        columnsPositions={columnsPositions}
        row={row}
        rowIndex={rowIndex}
        isRowActive={isRowActive}
        extraCell={() => (isExpanded ? FaAngleUp({}) : FaAngleDown({}))}
        className={className}
      />
      {isExpanded && expandedContent && expandedContent({ data: row })}
    </>
  );
}
