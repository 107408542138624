import ReactDOM from "react-dom/client";
import "bootstrap/dist/js/bootstrap";

import App from "./app";
import { UserProvider } from "./context/user.context";
import "./i18n";

const documentRoot = document.getElementById("root");

if (documentRoot) {
  const root = ReactDOM.createRoot(documentRoot);
  root.render(
    <UserProvider>
      <App />
    </UserProvider>
  );
} else {
  throw new Error("Document root not found.");
}
