import React, { useState, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FieldValues } from "react-hook-form";
import {
  TOAST_TYPES,
  RELOAD_UPLOAD_TYPE,
  BILLING_ALLOCATION_METHODS,
} from "../../consts";
import { Payroll, Program } from "../../interfaces/interfaces";
import { formatNumber } from "../../utils";
import payrollApi from "../../api/payroll-api";
import programsApi from "../../api/programs-api";
import { ToastContext } from "../../context/toast.context";
import { CompanyContext } from "../../context/company-context";
import { useLoadData } from "../../hooks/useLoadData";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { getColumns } from "./helpers";
import ActionHeaderComponent from "./action-header/action-header.component";
import EmptyListComponent from "../../components/shared/empty-list/empty-list.component";
import PayrollFormComponent from "../../components/forms/payroll/payroll-form.component";
import Table from "../../components/shared/table/table.component";
import Modal from "../../components/shared/modal/modal.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import "./payroll-register.component.scss";

export default function PayrollRegisterComponent(): JSX.Element | null {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { addToast } = useContext(ToastContext);
  const { company, chosenMonth } = useContext(CompanyContext);
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const [payrolls, arePayrollsLoading, refetchPayrolls] = useLoadData({
    fetcher: useCallback(
      () => payrollApi.getPayrolls(company.id, activePeriod),
      [activePeriod, company.id]
    ),
  });
  const [shortPrograms, areShortProgramsLoading] = useLoadData<Program[]>({
    fetcher: useCallback(
      () => programsApi.getShortPrograms(company.id, activePeriod),
      [activePeriod, company.id]
    ),
  });

  const [allPayrollsCount, , refetchPayrollCounts] = useLoadData({
    fetcher: useCallback(
      () => payrollApi.getPayrollsCount(company.id),
      [company]
    ),
  });

  const [
    allocationPrograms,
    areAllocationProgramsLoading,
    loadAllocationPrograms,
  ] = useLoadData<Program[]>({
    fetcher: useCallback(
      () => programsApi.getPRAllocationPrograms(company.id, activePeriod),
      [activePeriod, company.id]
    ),
  });
  const [purgePrograms, arePurgeProgramsLoading, loadPurgePrograms] =
    useLoadData<Program[]>({
      fetcher: useCallback(
        () => programsApi.getPRPurgePrograms(company.id, activePeriod),
        [activePeriod, company.id]
      ),
    });
  const [showUploadedResultModal, setShowUploadedResultModal] =
    useState<boolean>(false);
  const [payrollCount, setPayrollCount] = useState<string>("");
  const [totalPayrollAllocation, setTotalPayrollAllocation] =
    useState<string>("");
  const [showPayrollEditForm, setShowPayrollEditForm] =
    useState<boolean>(false);
  const [payrollToEdit, setPayrollToEdit] = useState<
    (Payroll & { allocated: boolean }) | undefined
  >(undefined);
  const [selectedAllocationProgram, setSelectedAllocationProgram] =
    useState<string>("all");
  const [selectedPurgeProgram, setSelectedPurgeProgram] =
    useState<string>("all");
  const [expandedRows, setExpandedRows] = useState(
    {} as Record<string, boolean>
  );
  const hasAllocationBeenMade = useMemo(
    () => payrolls?.allocated?.length > 0,
    [payrolls]
  );

  const isLoading = useMemo(
    () =>
      arePayrollsLoading ||
      areShortProgramsLoading ||
      arePurgeProgramsLoading ||
      areAllocationProgramsLoading,
    [
      arePayrollsLoading,
      areShortProgramsLoading,
      arePurgeProgramsLoading,
      areAllocationProgramsLoading,
    ]
  );

  // const isPayrollAllocated = useCallback(
  //   (payroll: Payroll): boolean => {
  //     for (const allocatedEntry of payrolls.allocated) {
  //       if (Array.isArray(allocatedEntry.children)) {
  //         const childrenIds = allocatedEntry.children.map((e) => e.id);
  //         if (childrenIds.includes(payroll.id)) return true;
  //       } else if (allocatedEntry.id === payroll.id) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [payrolls]
  // );

  const runAllocation = useCallback(() => {
    const allocationProgramId =
      selectedAllocationProgram === "all"
        ? selectedAllocationProgram
        : +selectedAllocationProgram;
    const data = {
      companyId: company.id,
      programId: allocationProgramId,
      grantOffsetCode: company.offsetGrantCode,
      type: BILLING_ALLOCATION_METHODS.SAM,
    };
    return payrollApi.allocatePayrolls(data, company.id, activePeriod).then(
      (res) => {
        if (res.status == "ERROR") {
          const messages = res.errors
            ?.map(({ message }) => message)
            .join("/br");
          errorHandler(undefined, messages);
        } else {
          addToast({
            type: TOAST_TYPES.success,
            message: t("company.tabs.payrollRegister.allocateSuccess"),
          });
        }
        loadAllocationPrograms();
        refetchPayrollCounts();
        loadPurgePrograms();
        refetchPayrolls();
      },
      (err) => errorHandler(err)
    );
  }, [
    company.id,
    company.offsetGrantCode,
    selectedAllocationProgram,
    activePeriod,
    refetchPayrolls,
    refetchPayrollCounts,
    loadAllocationPrograms,
    loadPurgePrograms,
    errorHandler,
    addToast,
    t,
  ]);

  const purgeProgramCode = useMemo(
    () =>
      purgePrograms.find(
        (program) => program.id.toString() === selectedPurgeProgram
      )?.code,
    [purgePrograms, selectedPurgeProgram]
  );

  const runPurge = useCallback(() => {
    const programId =
      selectedPurgeProgram === "all" ? "" : selectedPurgeProgram;
    return payrollApi.purgePayrolls(company.id, programId, activePeriod).then(
      (res) => {
        if (res.errors?.length) {
          errorHandler(undefined, res.errors?.[0]?.message);
        } else {
          const toastMessage =
            selectedPurgeProgram === "all"
              ? t("company.tabs.payrollRegister.purgeAllSuccess")
              : t("company.tabs.payrollRegister.purgeProgramSuccess", {
                  purgeProgram: purgeProgramCode,
                });
          addToast({
            type: TOAST_TYPES.success,
            message: toastMessage,
          });
          loadAllocationPrograms();
          loadPurgePrograms();
          refetchPayrollCounts();
          refetchPayrolls();
        }
      },
      (err) => errorHandler(err)
    );
  }, [
    selectedPurgeProgram,
    purgeProgramCode,
    company.id,
    activePeriod,
    errorHandler,
    t,
    addToast,
    refetchPayrolls,
    refetchPayrollCounts,
    loadAllocationPrograms,
    loadPurgePrograms,
  ]);

  const submitHandler = useCallback(
    (values: FieldValues) => {
      const data: {
        programAllocationId: number;
        jobFunctionId: number;
        payrollProgramAllocation: number;
        prBenefitProgramAllocation: number;
      } = {
        programAllocationId: +values.programAllocationId,
        jobFunctionId: +values.jFACR,
        payrollProgramAllocation: +values.payrollProgramAllocation,
        prBenefitProgramAllocation: +values.prBenefitProgramAllocation,
      };
      payrollApi.editPayroll(company.id, values.id, data).then(
        () => {
          addToast({
            type: TOAST_TYPES.success,
            message: t("company.tabs.payrollRegister.editSuccess"),
          });
          setPayrollToEdit(undefined);
          setShowPayrollEditForm(false);
          loadAllocationPrograms();
          loadPurgePrograms();
          refetchPayrollCounts();
          refetchPayrolls();
        },
        (err) => errorHandler(err)
      );
    },
    [
      t,
      addToast,
      errorHandler,
      company.id,
      refetchPayrolls,
      refetchPayrollCounts,
      loadAllocationPrograms,
      loadPurgePrograms,
    ]
  );

  const uploadFile = useCallback(
    async (files: FileList, selection: string): Promise<void> => {
      const formData = new FormData();
      formData.append("file", files[0]);
      if (selection) {
        formData.append("uploadType", selection);
      }
      if (activePeriod) {
        formData.append("activePeriod", activePeriod);
      }
      const uploadData = await payrollApi.uploadPayrolls(formData, company.id);
      if (uploadData.errors.length) {
        return Promise.reject(uploadData);
      }
      const { totalAmount, numberOfRowsImported } = uploadData;
      setPayrollCount(
        numberOfRowsImported ? numberOfRowsImported.toString() : "0"
      );
      setTotalPayrollAllocation(formatNumber(+totalAmount));
      setShowUploadedResultModal(true);
      loadAllocationPrograms();
      loadPurgePrograms();
      refetchPayrollCounts();
      return refetchPayrolls();
    },
    [
      refetchPayrollCounts,
      activePeriod,
      company.id,
      loadAllocationPrograms,
      loadPurgePrograms,
      refetchPayrolls,
    ]
  );

  const data = useMemo(() => {
    const withChildren: Payroll[] = [];
    payrolls?.allocated?.forEach((item, index) => {
      withChildren.push({ ...item, id: index });
      if (item.children && expandedRows[index]) {
        withChildren.push(...item.children);
      }
    });
    return [...withChildren, ...(payrolls?.unAllocated || [])];
  }, [expandedRows, payrolls]);

  const importActions = [
    {
      title: t("company.tabs.payrollRegister.getTemplate"),
      url: "templates?templateName=PAYROLL_REGISTER",
    },
  ];

  const uploadActions = useMemo(
    () => [
      {
        title: t("company.tabs.payrollRegister.upload"),
        action: uploadFile,
        ...(data.length && {
          confirmation: true,
          preselection: RELOAD_UPLOAD_TYPE.ALL,
        }),
      },
    ],
    [t, data, uploadFile]
  );

  const isAllCollapsed = useMemo(() => {
    const rows = Object.values(expandedRows);
    return !rows.length || rows.every((item) => !item);
  }, [expandedRows]);

  const isAllExpanded = useMemo(() => {
    const expandedValues = Object.values(expandedRows);
    return (
      payrolls?.allocated?.length === expandedValues?.length &&
      expandedValues.every((item) => item)
    );
  }, [expandedRows, payrolls]);

  const onExpandAll = useCallback(() => {
    const newRowsStatus = {} as Record<string, boolean>;
    payrolls.allocated?.forEach((_, index) => {
      newRowsStatus[index] = isAllCollapsed;
    });
    setExpandedRows(newRowsStatus);
  }, [isAllCollapsed, payrolls.allocated]);

  // const rowClickCallback = useCallback(
  //   (row: Payroll) => {
  //     const isParent = row?.children?.length;
  //     const isAllocated = isPayrollAllocated(row);
  //     if (isParent) {
  //       return false;
  //     }
  //     setPayrollToEdit({
  //       ...row,
  //       allocated: isAllocated,
  //     });
  //     setShowPayrollEditForm(true);
  //   },
  //   [setPayrollToEdit, setShowPayrollEditForm, isPayrollAllocated]
  // );

  const columns = useMemo(
    () =>
      getColumns({
        t,
        expandedRows,
        setExpandedRows,
        onExpandAll,
        isAllCollapsed,
      }),
    [expandedRows, isAllCollapsed, onExpandAll, t]
  );

  return !isLoading ? (
    <div className="payroll-register">
      <Modal
        onClose={() => {
          setPayrollCount("");
          setTotalPayrollAllocation("");
          setShowUploadedResultModal(false);
        }}
        show={showUploadedResultModal}
        title={t("general.uploadResults")}
        Footer={[
          <button
            onClick={() => {
              setPayrollCount("");
              setTotalPayrollAllocation("");
              setShowUploadedResultModal(false);
            }}
            className="btn btn-primary"
          >
            {t("general.ok")}
          </button>,
        ]}
      >
        <div>
          {t(
            +payrollCount === 1
              ? "company.tabs.payrollRegister.uploadModalInfo"
              : "company.tabs.payrollRegister.uploadModalInfo_plural",
            { payrollCount, totalPayrollAllocation }
          )}
        </div>
      </Modal>
      <Modal
        centredContent={false}
        show={showPayrollEditForm}
        title={t(
          `company.tabs.payrollRegister.form.${payrollToEdit ? "edit" : "add"}`
        )}
        {...(payrollToEdit?.allocated && {
          subtitle: t(`company.tabs.payrollRegister.form.payrollAllocated`),
        })}
      >
        <PayrollFormComponent
          key={payrollToEdit?.id}
          payroll={payrollToEdit}
          programs={shortPrograms}
          onSubmit={submitHandler}
          onClose={() => {
            setShowPayrollEditForm(false);
            setPayrollToEdit(undefined);
          }}
        />
      </Modal>
      {allPayrollsCount > 0 ? (
        <div>
          <ActionHeaderComponent
            allocationPrograms={allocationPrograms}
            allocationMade={hasAllocationBeenMade}
            purgePrograms={purgePrograms}
            runAllocation={runAllocation}
            runPurge={runPurge}
            importActions={importActions}
            uploadActions={uploadActions}
            allocationProgram={selectedAllocationProgram}
            setAllocationProgram={setSelectedAllocationProgram}
            purgeProgram={selectedPurgeProgram}
            setPurgeProgram={setSelectedPurgeProgram}
          />
          {!data.length ? (
            <div className="empty-table flex-fill justify-content-center align-items-center">
              {t("general.filteredDataMessage")}
            </div>
          ) : (
            <Table
              search
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns}
              data={data}
              // temporary
              // onRowClick={rowClickCallback}
              filterTrigger={() => {
                if (!isAllExpanded) {
                  onExpandAll();
                }
              }}
            />
          )}
        </div>
      ) : (
        <EmptyListComponent
          importActions={importActions}
          uploadActions={uploadActions}
          description={t("company.tabs.payrollRegister.emptyList")}
        />
      )}
    </div>
  ) : (
    <Spinner size="medium" />
  );
}
