import { useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "./useErrorHandler";

interface UseLoadDataInterface<T> {
  fetcher: () => Promise<T>;
  transformer?: (data: T) => T;
}

export function useLoadData<T>({
  fetcher,
  transformer,
}: UseLoadDataInterface<T>): [T, boolean, () => void] {
  const errorHandler = useErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<T>([] as unknown as T);

  const load = useCallback(() => {
    setIsLoading(true);
    fetcher()
      .then(
        (res: T) => {
          setLoadedData(transformer ? transformer(res) : res);
        },
        (err) => {
          errorHandler(undefined, err.message);
        }
      )
      .finally(() => setIsLoading(false));
  }, [errorHandler, fetcher, transformer]);

  useEffect(() => {
    load();
  }, [load]);
  return [loadedData, isLoading, load];
}
