import React, { Dispatch, SetStateAction } from "react";
import { TableProps } from "../table.component";

interface SearchRowProps<T> {
  columns: TableProps<T>["columns"];
  tableHeaderHeight: number;
  columnsPositions: Record<number, string | number>;
  setFilters: Dispatch<SetStateAction<Record<keyof T, string>>>;
  filterTrigger?: () => void;
}
export default function SearchRow<T>({
  columns,
  tableHeaderHeight,
  columnsPositions,
  setFilters,
  filterTrigger,
}: SearchRowProps<T>): JSX.Element {
  return (
    <tr className="search">
      {columns.map(({ freeze, property, header, renderHeader }, index) => (
        <td
          key={header}
          className={freeze ? "freeze" : ""}
          style={{
            top: tableHeaderHeight - 2,
            ...(freeze && { left: columnsPositions[index] }),
          }}
        >
          {header || renderHeader ? (
            <input
              onChange={({ target }) => {
                if (filterTrigger) {
                  filterTrigger();
                }
                setFilters((state) => ({
                  ...state,
                  [property]: target.value,
                }));
              }}
              type="text"
            />
          ) : (
            ""
          )}
        </td>
      ))}
    </tr>
  );
}
