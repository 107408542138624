import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FaLongArrowAltRight,
  FaLongArrowAltLeft,
  FaLongArrowAltUp,
  FaLongArrowAltDown,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  DOCUMENT_NAMES,
  DOCUMENT_NAMES_MANDATORY,
  TOAST_TYPES,
} from "../../../consts";
import companyApi from "../../../api/company-api";
import { CompanyContext } from "../../../context/company-context";
import { ToastContext } from "../../../context/toast.context";
import BackTo from "../../../components/shared/back-to/back-to.component";
import "./settings.component.scss";

const FILE_NAMES_EXAMPLES = {
  [DOCUMENT_NAMES.year]: "[Year]",
  [DOCUMENT_NAMES.vendorName]: "[VendorName]",
  [DOCUMENT_NAMES.accountingPeriod]: "[Acc Period]",
  [DOCUMENT_NAMES_MANDATORY.vendorId]: "[VendorId]",
  [DOCUMENT_NAMES_MANDATORY.invoiceNumber]: "[Invoice Number]",
};

export function SettingsComponent() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { addToast } = useContext(ToastContext);
  const [availableOptions, setAvailableOptions] = useState<string[]>(
    Object.values(DOCUMENT_NAMES)
  );
  const [chosenOptions, setChosenOptions] = useState<string[]>(
    Object.values(DOCUMENT_NAMES_MANDATORY)
  );
  const [selectedItem, setSelectedItem] = useState<string>("");

  const moveItem = useCallback(
    (side: "left" | "right"): void => {
      if (!selectedItem) {
        return;
      }
      const increaseHandler =
        side === "right" ? setAvailableOptions : setChosenOptions;
      const decreaseHandler =
        side === "right" ? setChosenOptions : setAvailableOptions;
      increaseHandler((state) => [...state, selectedItem]);
      decreaseHandler((state) => state.filter((item) => item !== selectedItem));
      setSelectedItem("");
    },
    [selectedItem]
  );

  useEffect(() => {
    companyApi.getFileNamePattern(company.id).then(({ fileNameParts }) => {
      setChosenOptions(fileNameParts);
      setAvailableOptions(
        Object.values(DOCUMENT_NAMES).filter(
          (item) => !fileNameParts.includes(item)
        )
      );
    });
  }, [company.id]);

  const getSideArrowDisabledClass = useCallback(
    (side: "left" | "right"): string => {
      if (!selectedItem) {
        return "disabled";
      }
      const neededList: string[] =
        side === "left" ? chosenOptions : availableOptions;
      return neededList.includes(selectedItem) ||
        Object.values(DOCUMENT_NAMES_MANDATORY).includes(selectedItem)
        ? "disabled"
        : "";
    },
    [availableOptions, chosenOptions, selectedItem]
  );

  const sortItem = useCallback(
    (direction: "up" | "down"): void => {
      const elementIndex = chosenOptions.indexOf(selectedItem);
      const newIndex = direction === "up" ? elementIndex - 1 : elementIndex + 1;
      const newArr = [...chosenOptions];
      newArr[elementIndex] = chosenOptions[newIndex];
      newArr[newIndex] = selectedItem;
      setChosenOptions(newArr);
    },
    [selectedItem, chosenOptions]
  );

  const filePathExample = useMemo(
    () => chosenOptions.map((item) => FILE_NAMES_EXAMPLES[item]).join("-"),
    [chosenOptions]
  );

  const savePattern = useCallback(() => {
    companyApi.setFileNamePattern(chosenOptions, company.id).then(
      () => {
        addToast({
          id: company.id,
          type: TOAST_TYPES.success,
          message: t("company.tabs.documents.fileNamePatternSaved"),
        });
      },
      () => {
        addToast({
          id: company.id,
          type: TOAST_TYPES.error,
          message: t("general.serverError"),
        });
      }
    );
  }, [addToast, chosenOptions, company.id, t]);

  const cancelCallback = useCallback(() => {
    const prevUrl = pathname.split("settings")[0];
    navigate(prevUrl);
  }, [pathname, navigate]);

  const isUpArrowDisabled = useMemo(
    () =>
      !selectedItem ||
      !chosenOptions.includes(selectedItem) ||
      chosenOptions[0] === selectedItem,
    [chosenOptions, selectedItem]
  );

  const isDownArrowDisabled = useMemo(
    () =>
      !selectedItem ||
      !chosenOptions.includes(selectedItem) ||
      chosenOptions[chosenOptions.length - 1] === selectedItem,
    [chosenOptions, selectedItem]
  );
  return (
    <div className="document-settings-page d-flex flex-column">
      <header>
        <BackTo
          link={pathname.split("settings")[0]}
          title={t("company.tabs.documents.settings.back")}
        />
      </header>
      <p className="mt-3 fw-normal">
        {t("company.tabs.documents.settings.title")}
      </p>
      <div className="content d-flex">
        <div className="d-flex flex-column">
          <p className="mt-1 fs-5">
            {t("company.tabs.documents.settings.availableTitle")}
          </p>
          <div className="d-flex">
            <div className="available options">
              {availableOptions.map((option) => (
                <div
                  key={option}
                  onClick={() => setSelectedItem(option)}
                  className={`option ${option === selectedItem && "active"}`}
                >
                  {t(`company.tabs.documents.types.${option}`).toUpperCase()}
                </div>
              ))}
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center controls options">
              <FaLongArrowAltRight
                className={`icon ${getSideArrowDisabledClass("left")}`}
                onClick={() => moveItem("left")}
              />
              <FaLongArrowAltLeft
                className={`icon ${getSideArrowDisabledClass("right")}`}
                onClick={() => moveItem("right")}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column">
          <p className="mt-1 fs-5">
            {t("company.tabs.documents.settings.selectedTitle")}
          </p>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <div className="selected options">
                {chosenOptions.map((option) => (
                  <div
                    onClick={() => setSelectedItem(option)}
                    key={option}
                    className={`option ${option === selectedItem && "active"}`}
                  >
                    {t(`company.tabs.documents.types.${option}`).toUpperCase()}
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end mt-3 gap-3">
                <button onClick={cancelCallback} className="btn btn-secondary">
                  {t("general.cancel")}
                </button>
                <button
                  disabled={!chosenOptions.length}
                  onClick={savePattern}
                  className="btn btn-primary"
                >
                  {t("general.save")}
                </button>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-start ms-2 gap-5 options controls">
              <FaLongArrowAltUp
                className={`icon ${isUpArrowDisabled && "disabled"}`}
                onClick={() => sortItem("up")}
              />
              <FaLongArrowAltDown
                className={`icon ${isDownArrowDisabled && "disabled"}`}
                onClick={() => sortItem("down")}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          {filePathExample}
        </div>
      </div>
    </div>
  );
}
