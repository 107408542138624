import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../../consts";
import { User } from "../../../interfaces/interfaces";
import permissionsApi from "../../../api/permissions-api";
import userApi from "../../../api/user-api";
import { ToastContext } from "../../../context/toast.context";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import BackTo from "../../../components/shared/back-to/back-to.component";
import PageLayout from "../../../components/shared/page-layout/page-layout.component";
import CreateEntityModal from "../../../components/shared/create-entity-modal/modal.component";
import ConfirmationModalComponent from "../../../components/shared/confirmation-modal/confirmation-modal.component";
import UserForm from "../../../components/forms/user-form/user-form.component";
import "./user.component.scss";

export default function UserComponent(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const [user, setUser] = useState<User | null>(null);
  const [showInviteConfirmation, setShowInviteConfirmation] =
    useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();

  const deleteUser = useCallback(() => {
    if (user) {
      userApi.deleteUser(user.id).then(() => navigate(`/${ROUTE_PATHS.users}`));
    }
  }, [user, navigate]);

  const loadUser = useCallback((userId: string) => {
    Promise.all([
      userApi.getUser(userId),
      permissionsApi.getCompaniesByUser(userId),
    ]).then(([newUser, companies = []]): void => {
      const updatedUser = { ...newUser, company: [companies[0]] };
      setUser(updatedUser);
    });
  }, []);

  const sendInvite = useCallback(
    (userId: string | undefined): void => {
      if (userId) {
        userApi.sendInvite(userId).then(
          () => {
            loadUser(userId);
            addToast({
              id: userId,
              type: "success",
              message: t("users.userForm.sendInviteSuccess"),
            });
          },
          (error) =>
            errorHandler(
              error,
              error.title || t("users.userForm.sendInviteError")
            )
        );
      }
    },
    [loadUser, addToast, t, errorHandler]
  );

  const onSendInvite = useCallback((): void => {
    if (user?.inviteSent) {
      setShowInviteConfirmation(true);
    } else {
      sendInvite(user?.id);
    }
  }, [user, sendInvite]);

  useEffect((): void => {
    if (id) {
      loadUser(id);
    }
  }, [id, loadUser]);
  return (
    <PageLayout
      title={() => (
        <BackTo
          link={`/${ROUTE_PATHS.users}`}
          title={t(`users.${id ? "editUser" : "createUser"}`, {
            name: user?.firstName,
          })}
        />
      )}
      actionNode={() => (
        <div className="user-id gap-3">
          <CreateEntityModal
            title={t("users.userForm.reSendInviteTitle")}
            bodyData={t("users.userForm.reSendInviteBody", { id: user?.id })}
            onCancel={() => setShowInviteConfirmation(false)}
            onSubmit={() => {
              sendInvite(user?.id);
              setShowInviteConfirmation(false);
            }}
            submitTitle={t("users.userForm.reSendInvite")}
            cancelTitle={t("users.userForm.cancel")}
            show={showInviteConfirmation}
          />
          {!!id && [
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="btn btn-secondary"
            >
              {t("users.userForm.delete")}
            </button>,
            <button onClick={onSendInvite} className="btn btn-primary">
              {t(
                `users.userForm.${
                  user?.inviteSent ? "reSendInvite" : "sendInvite"
                }`
              )}
            </button>,
            <div className="space" />,
          ]}
          <label htmlFor="id">{t("users.userForm.id")}</label>
          <div>
            <input
              type="text"
              readOnly
              className="form-control"
              id="id"
              value={user?.id}
            />
          </div>
        </div>
      )}
    >
      <UserForm
        showSendInviteButton={false}
        sendInvite={sendInvite}
        key={user?.id || 0}
        entity={user}
      />
      <ConfirmationModalComponent
        title={t("users.userForm.deleteConfirmation")}
        show={showDeleteConfirmation}
        onSubmit={deleteUser}
        onClose={() => setShowDeleteConfirmation(false)}
      />
    </PageLayout>
  );
}
