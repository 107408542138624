import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import storage from "../storage/storage";

const ROUTES_WITHOUT_AUTH = ["/login", "/update-password", "/forgot-password"];

const axiosClient = axios.create();
axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axiosClient.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
axiosClient.defaults.timeout = 70000;
axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = storage.get("token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error?.response?.data);
  }
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      storage.remove("token");
      document.cookie = "";
      if (
        !ROUTES_WITHOUT_AUTH.some((route) =>
          window.location.href.includes(route)
        )
      ) {
        window.location.href = "/login";
      }
    }
    if (error.response.status === 403) {
      storage.remove("token");
      /** @todo: Request refresh token */
    }
    return Promise.reject(error?.response?.data);
  }
);

export async function getRequest<T, TT>(
  url: string,
  config?: TT
): Promise<AxiosResponse<T>> {
  return axiosClient
    .get(`/${url}`, config)
    .then((response: AxiosResponse) => response);
}

export async function postRequest<TT, TTT>(
  url: string,
  payload?: TT,
  config?: TTT
): Promise<AxiosResponse> {
  return axiosClient
    .post(`/${url}`, payload, config)
    .then((response: AxiosResponse) => response);
}

export async function putRequest<T, TT>(
  url: string,
  payload: TT
): Promise<AxiosResponse<T>> {
  return axiosClient
    .put(`/${url}`, payload)
    .then((response: AxiosResponse) => response);
}

export async function patchRequest<T, TT>(
  url: string,
  payload: TT
): Promise<AxiosResponse<T>> {
  return axiosClient
    .patch(`/${url}`, payload)
    .then((response: AxiosResponse) => response);
}

export async function deleteRequest<T>(url: string): Promise<AxiosResponse<T>> {
  return axiosClient
    .delete(`/${url}`)
    .then((response: AxiosResponse) => response);
}

export const httpClient = {
  get: getRequest,
  post: postRequest,
  put: putRequest,
  patch: patchRequest,
  delete: deleteRequest,
};
