import React from "react";
import "./logo.component.scss";
import { colors } from "../../../consts/colors";

interface LogoProps {
  secondaryColor?: string;
  textColor?: string;
  width?: string;
  height?: string;
}

export default function Logo({
  secondaryColor = colors.grey,
  textColor = colors.white,
  width = "180",
  height = "68",
}: LogoProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 180 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_626_71441)">
        <path
          d="M174.868 10.3549L170.552 24.3532L150.921 24.3595L149.349 7.38287L164.051 6.55273L174.868 10.3549Z"
          fill={secondaryColor}
        />
        <path
          d="M179.29 28.5092L174.975 42.5074L155.343 42.5138L153.772 25.5372L168.473 24.707L179.29 28.5092Z"
          fill={secondaryColor}
        />
        <path
          d="M139.786 2.03906L131.77 26.563L139.907 49.9525L147.131 26.2715L139.786 2.03906Z"
          fill={secondaryColor}
        />
        <path
          d="M178.891 22.6281C178.567 21.6396 177.889 21.2403 176.464 21.2403H162.858C161.439 21.2403 160.919 20.9742 160.602 19.973L158.296 12.2989C157.979 11.304 158.22 11.0316 159.645 11.0316H173.225C174.645 11.0316 175.127 10.6323 174.791 9.63744L172.712 2.83158C172.395 1.83668 171.73 1.41211 170.291 1.41211H141.421C139.995 1.41211 139.519 1.81134 139.855 2.80623L147.143 26.2528H180L178.891 22.6281Z"
          fill="url(#paint0_linear_626_71441)"
        />
        <path
          d="M141.548 50.6136H170.419C171.838 50.6136 172.548 50.2207 172.839 49.2511L174.962 42.5657C175.254 41.5898 174.823 41.2032 173.397 41.2032H159.817C158.391 41.2032 158.043 40.9434 158.334 39.9675L160.787 31.983C161.078 31.0135 161.579 30.7536 163.004 30.7536H176.584C178.01 30.7536 178.714 30.3608 179.005 29.3912L180 26.2734H147.143L139.982 49.2511C139.691 50.208 140.122 50.6136 141.548 50.6136Z"
          fill="white"
        />
        <path
          d="M139.906 16.1902L134.476 24.6627L115.427 26.2723L103.716 14.3018L110.193 5.11328L139.906 16.1902Z"
          fill={secondaryColor}
        />
        <path
          d="M122.15 22.2051L99.1091 29.0299C99.1091 29.0299 106.751 44.5047 107.917 44.7328C109.083 44.961 125.889 43.9787 126.104 43.871C126.32 43.7633 131.358 31.6788 131.358 31.6788L122.15 22.2051Z"
          fill={secondaryColor}
        />
        <path
          d="M98.4441 6.36914L84.6803 26.5649L98.6532 45.7848L100.041 26.2734L98.4441 6.36914Z"
          fill={secondaryColor}
        />
        <path
          d="M114.324 51.2599C128.76 51.2599 137.72 44.5744 140.16 36.723L143.448 26.2734H121.073L120.281 28.8715C119.989 29.8411 120.338 30.1706 121.757 30.1706H125.75C127.169 30.1706 127.619 30.4304 127.321 31.438L125.794 36.0513C124.907 39.036 121.903 41.4377 117.632 41.4377C112.22 41.4377 111.853 37.6735 113.253 33.3898L115.427 26.248H99.9456L97.772 33.4151C94.4705 44.0548 99.0331 51.2599 114.324 51.2599Z"
          fill="white"
        />
        <path
          d="M115.427 26.2727H99.9459L97.6329 18.9916C94.2427 8.13013 98.8559 0.779297 114.115 0.779297C128.456 0.779297 137.048 7.53446 139.944 15.2148C140.192 15.8485 140.179 16.4061 139.038 16.4061H126.998C125.953 16.4061 125.313 16.0766 124.939 15.3479C124.241 13.9169 123.139 12.7216 121.77 11.9096C120.401 11.0977 118.823 10.7046 117.233 10.779C111.821 10.779 111.701 14.6191 113.095 19.017L115.427 26.2727ZM142.46 23.1043C142.137 22.1221 141.586 21.7925 140.16 21.7925H121.548C120.123 21.7925 119.723 22.1221 120.047 23.1043L121.073 26.2727H143.449L142.46 23.1043Z"
          fill="url(#paint1_linear_626_71441)"
        />
        <path
          d="M64.5728 7.30078L74.7118 8.14359L89.236 25.9947L85.9092 37.5342L63.8123 46.4376C63.8123 46.4376 55.0357 42.8002 55.3399 42.1982C55.6441 41.5962 58.5083 11.781 58.5083 11.781L64.5728 7.30078Z"
          fill={secondaryColor}
        />
        <path
          d="M44.5485 2.03906L36.5323 26.563L44.6689 49.9525L51.8867 26.2715L44.5485 2.03906Z"
          fill={secondaryColor}
        />
        <path
          d="M63.0463 12.2989C62.7295 11.304 62.9703 11.0316 64.3961 11.0316H67.4315C74.7443 11.0316 77.1903 14.8717 78.6161 19.3076L80.758 26.2782H96.2391L94.2049 19.7258C90.7767 8.78829 81.5945 1.41211 65.2706 1.41211H46.1711C44.7453 1.41211 44.27 1.81134 44.6059 2.80623L51.8934 26.2528H67.3681L63.0463 12.2989Z"
          fill="url(#paint2_linear_626_71441"
        />
        <path
          d="M46.2978 50.6136H65.3846C81.7211 50.6136 90.8336 43.4085 94.2365 32.6991L96.239 26.2734H80.7579L78.6224 33.0983C77.2156 37.4518 74.9216 41.2159 67.5138 41.2159H64.5671C63.1477 41.2159 62.7928 40.9561 63.0843 39.9802L67.368 26.2861H51.8933L44.7326 49.2511C44.4411 50.208 44.872 50.6136 46.2978 50.6136Z"
          fill="white"
        />
        <path
          d="M42.9768 10.6075L37.47 27.1849L23.4718 23.2497L17.344 8.04107L32.0393 7.21094L42.9768 10.6075Z"
          fill={secondaryColor}
        />
        <path
          d="M47.641 28.1036L42.1342 44.681L23.5797 42.5138L22.0082 25.5372L36.7098 24.707L47.641 28.1036Z"
          fill={secondaryColor}
        />
        <path
          d="M8.0162 2.03906L0 26.563L8.1366 49.9525L15.3607 26.2715L8.0162 2.03906Z"
          fill={secondaryColor}
        />
        <path
          d="M47.1027 22.6281C46.7859 21.6396 46.1078 21.2403 44.682 21.2403H31.102C29.6762 21.2403 29.1629 20.9742 28.8397 19.973L26.5141 12.2989C26.1909 11.304 26.438 11.0316 27.8639 11.0316H41.4439C42.8633 11.0316 43.3449 10.6323 43.0027 9.63744L40.9306 2.83158C40.6074 1.83668 39.923 1.41211 38.5035 1.41211H9.63889C8.21309 1.41211 7.73782 1.81134 8.07367 2.80623L15.3611 26.2528H48.2117L47.1027 22.6281Z"
          fill="url(#paint3_linear_626_71441)"
        />
        <path
          d="M9.76472 50.6136H38.6547C40.0805 50.6136 40.7902 50.2207 41.0817 49.2511L43.2046 42.5657C43.4961 41.5898 43.0652 41.2032 41.6394 41.2032H28.034C26.6082 41.2032 26.2597 40.9434 26.5512 39.9675L29.0036 31.983C29.2951 31.0135 29.7957 30.7536 31.2215 30.7536H44.8015C46.2273 30.7536 46.9307 30.3608 47.2222 29.3912L48.2108 26.2734H15.3602L8.1995 49.2511C7.908 50.208 8.33891 50.6136 9.76472 50.6136Z"
          fill="white"
        />
        <path
          d="M12.2113 63.9908H14.746V65.9553C14.4396 66.19 14.1001 66.378 13.7385 66.5129C13.3195 66.6716 12.8747 66.7511 12.4267 66.7474C11.9683 66.7557 11.5132 66.6695 11.0896 66.4939C10.7089 66.3314 10.3659 66.0919 10.0821 65.7905C9.80492 65.4793 9.58983 65.1179 9.44837 64.7259C9.29329 64.293 9.21605 63.836 9.22024 63.3761C9.22233 62.9431 9.30173 62.5139 9.45471 62.1088C9.59836 61.7178 9.81321 61.3568 10.0884 61.0442C10.3629 60.743 10.6946 60.4996 11.0643 60.3281C11.4512 60.1485 11.8734 60.0575 12.3 60.0619C12.5635 60.0608 12.8265 60.0842 13.0858 60.1316C13.3018 60.1779 13.5137 60.2414 13.7194 60.3217C13.9121 60.4011 14.0967 60.4987 14.2708 60.6132C14.4429 60.7273 14.606 60.8544 14.7587 60.9935L14.8094 61.0378L15.4938 60.2267L15.4494 60.1887C15.2501 60.0169 15.0383 59.8602 14.8157 59.7197C14.5913 59.5769 14.3536 59.4559 14.106 59.3585C13.8369 59.2387 13.5567 59.1453 13.2695 59.0797C12.9528 59.0326 12.6329 59.0093 12.3127 59.01C11.7388 59.0038 11.1702 59.1198 10.6446 59.3503C10.1191 59.5809 9.64866 59.9207 9.2646 60.3471C8.89417 60.749 8.60381 61.2178 8.40912 61.7285C8.20604 62.254 8.10288 62.8128 8.10494 63.3761C8.09957 63.9489 8.19834 64.518 8.39644 65.0554C8.58406 65.5663 8.87288 66.034 9.24559 66.4305C9.62691 66.8258 10.0838 67.1404 10.589 67.3557C11.1545 67.5838 11.76 67.6958 12.3697 67.6853C13.0225 67.6941 13.67 67.5668 14.2708 67.3114C14.7958 67.0914 15.2817 66.7874 15.7092 66.4115V63.0276H12.2113V63.9908Z"
          fill={textColor}
        />
        <path
          d="M23.4843 63.9071C23.7596 63.7869 24.0092 63.6147 24.2194 63.4001C24.4312 63.1927 24.5993 62.9449 24.7137 62.6714C24.8322 62.3687 24.8903 62.0458 24.8848 61.7208C24.8918 61.3937 24.8337 61.0684 24.7137 60.764C24.6034 60.486 24.4374 60.2337 24.2257 60.0225C23.9415 59.7386 23.5972 59.5221 23.2182 59.3888C22.773 59.2301 22.3029 59.1528 21.8304 59.1607H18.1993V67.5698H19.2576V64.357H21.5452L23.9216 67.5255H25.227L22.6922 64.1289C22.9645 64.0883 23.2306 64.0138 23.4843 63.9071ZM23.2309 62.9502C23.0399 63.0952 22.8256 63.2067 22.5972 63.2797C22.3253 63.3579 22.0436 63.3963 21.7607 63.3938H19.2576V60.1683H21.7544C22.2927 60.1355 22.827 60.2802 23.2752 60.5802C23.4509 60.7147 23.5912 60.8901 23.684 61.091C23.7767 61.292 23.8191 61.5125 23.8075 61.7335C23.812 61.9725 23.7599 62.2092 23.6554 62.4242C23.5565 62.63 23.4111 62.8101 23.2309 62.9502Z"
          fill={textColor}
        />
        <path
          d="M31.2475 59.1172H30.2906L26.4885 67.4819L26.4504 67.5706H27.5657L28.5163 65.4161H32.9521L33.9153 67.6023H35.0623L31.2602 59.1869L31.2475 59.1172ZM32.5149 64.4212H28.9662L30.7595 60.4226L32.5149 64.4212Z"
          fill={textColor}
        />
        <path
          d="M43.0975 65.6814L38.0026 59.1987L37.9836 59.1797H37.014V67.5698H38.0533V60.9097L43.2749 67.5444L43.2939 67.5698H44.1304V59.1797H43.0975V65.6814Z"
          fill={textColor}
        />
        <path
          d="M46.2285 60.1682H49.0041V67.5698H50.0624V60.1682H52.8379V59.1797H46.2285V60.1682Z"
          fill={textColor}
        />
        <path
          d="M65.2136 63.8698C65.043 63.7011 64.8487 63.558 64.6369 63.4453C64.4615 63.3552 64.2793 63.2789 64.092 63.2171C64.2075 63.1556 64.3196 63.0879 64.4278 63.0144C64.5922 62.9031 64.7377 62.7662 64.8587 62.6088C64.9953 62.4434 65.1005 62.2544 65.1692 62.0512C65.2537 61.8155 65.2945 61.5664 65.2896 61.3161C65.2928 61.0637 65.2456 60.8133 65.151 60.5794C65.0563 60.3455 64.916 60.1328 64.7383 59.9536C64.473 59.7057 64.1575 59.5177 63.8131 59.4023C63.4054 59.2625 62.9767 59.1939 62.5458 59.1995H58.9337V67.5706H62.6662C63.0935 67.577 63.5198 67.5258 63.9335 67.4185C64.2811 67.3248 64.6093 67.1703 64.9031 66.9623C65.1716 66.7731 65.3892 66.5206 65.5368 66.2272C65.6852 65.9237 65.7591 65.5891 65.7522 65.2513C65.759 64.9841 65.7093 64.7185 65.6065 64.4719C65.5163 64.2474 65.3828 64.0428 65.2136 63.8698ZM64.2314 61.4428C64.2438 61.6462 64.2043 61.8494 64.1168 62.0333C64.0292 62.2173 63.8964 62.3761 63.7308 62.4947C63.332 62.7474 62.8652 62.8713 62.3937 62.8496H60.0173V60.1437H62.4634C62.9288 60.1146 63.3912 60.2369 63.7815 60.4923C63.926 60.5983 64.0425 60.738 64.1209 60.8992C64.1993 61.0604 64.2372 61.2383 64.2314 61.4175V61.4428ZM64.6876 65.1689C64.6971 65.3706 64.655 65.5713 64.5651 65.752C64.4753 65.9328 64.3408 66.0876 64.1743 66.2018C63.7426 66.4778 63.2348 66.6109 62.7232 66.582H60.0173V63.8128H62.5521C63.11 63.7784 63.6661 63.9037 64.1553 64.174C64.324 64.278 64.4622 64.4249 64.5557 64.5997C64.6492 64.7744 64.6947 64.9708 64.6876 65.1689Z"
          fill={textColor}
        />
        <path
          d="M69.1044 59.1738H68.0461V67.5703H69.1044V59.1738Z"
          fill={textColor}
        />
        <path
          d="M72.9317 59.1797H71.8671V67.5698H77.583V66.5812H72.9317V59.1797Z"
          fill={textColor}
        />
        <path
          d="M80.7133 59.1797H79.655V67.5698H85.3646V66.5812H80.7133V59.1797Z"
          fill={textColor}
        />
        <path
          d="M88.5772 59.1738H87.5189V67.5703H88.5772V59.1738Z"
          fill={textColor}
        />
        <path
          d="M97.43 65.6814L92.3351 59.1987L92.3161 59.1797H91.3466V67.5698H92.3795V60.9097L97.6074 67.5444L97.6264 67.5698H98.4629V59.1797H97.43V65.6814Z"
          fill={textColor}
        />
        <path
          d="M104.895 63.9909H107.43V65.9553C107.122 66.1913 106.78 66.3794 106.416 66.513C105.997 66.6722 105.552 66.7517 105.104 66.7474C104.648 66.7553 104.195 66.669 103.773 66.494C103.392 66.3329 103.049 66.0932 102.766 65.7906C102.484 65.4825 102.269 65.1202 102.132 64.726C101.977 64.293 101.9 63.836 101.904 63.3762C101.903 62.9428 101.983 62.5131 102.138 62.1088C102.282 61.7178 102.497 61.3569 102.772 61.0442C103.045 60.7416 103.377 60.498 103.748 60.3281C104.135 60.1479 104.557 60.0569 104.984 60.062C105.247 60.0609 105.51 60.0842 105.77 60.1317C105.985 60.1788 106.197 60.2423 106.403 60.3218C106.595 60.4025 106.78 60.5 106.955 60.6133C107.127 60.7273 107.29 60.8545 107.442 60.9935L107.493 61.0379L108.178 60.2267L108.127 60.1887C107.929 60.0153 107.717 59.8585 107.493 59.7198C107.269 59.5769 107.031 59.456 106.783 59.3586C106.516 59.2391 106.238 59.1457 105.953 59.0798C105.634 59.0324 105.312 59.0091 104.99 59.01C104.399 59.0031 103.813 59.1242 103.273 59.3649C102.768 59.5977 102.318 59.9321 101.948 60.3471C101.576 60.7487 101.283 61.2175 101.087 61.7286C100.883 62.2541 100.78 62.8128 100.782 63.3762C100.78 63.9487 100.879 64.5172 101.074 65.0555C101.264 65.5667 101.555 66.0344 101.929 66.4306C102.314 66.8371 102.778 67.1606 103.292 67.3811C103.857 67.6097 104.463 67.7217 105.072 67.7106C105.725 67.7195 106.373 67.5922 106.974 67.3368C107.498 67.1152 107.983 66.8114 108.412 66.4369V63.0277H104.876L104.895 63.9909Z"
          fill={textColor}
        />
        <path
          d="M117.936 62.8804C117.56 62.8068 117.19 62.7072 116.827 62.5826C116.588 62.5059 116.361 62.3926 116.156 62.2467C116.019 62.1396 115.909 62.0032 115.833 61.8475C115.772 61.6877 115.742 61.5179 115.744 61.3469C115.742 61.1719 115.781 60.9988 115.859 60.8423C115.938 60.6858 116.052 60.5502 116.194 60.447C116.558 60.1749 117.008 60.0422 117.461 60.0732C117.87 60.072 118.275 60.1428 118.659 60.2823C119.058 60.4312 119.432 60.6407 119.768 60.9033L119.818 60.9477L120.452 60.1239L120.401 60.0858C120 59.7631 119.551 59.5044 119.071 59.3191C118.554 59.138 118.009 59.05 117.461 59.0593C117.085 59.0553 116.71 59.1131 116.352 59.2303C116.033 59.3415 115.735 59.5065 115.471 59.7183C115.223 59.9147 115.023 60.1664 114.888 60.4534C114.748 60.7414 114.676 61.0581 114.679 61.3786C114.672 61.6893 114.731 61.998 114.85 62.2847C114.968 62.5516 115.149 62.7863 115.376 62.9691C115.642 63.1826 115.942 63.3498 116.263 63.4634C116.675 63.6183 117.099 63.7391 117.531 63.8246C117.891 63.9 118.247 63.9973 118.595 64.1161C118.823 64.1936 119.037 64.3069 119.229 64.452C119.369 64.5508 119.479 64.6869 119.546 64.8449C119.606 65.0006 119.636 65.166 119.635 65.3328C119.637 65.5186 119.596 65.7024 119.513 65.8689C119.43 66.0353 119.309 66.1796 119.159 66.2897C118.78 66.5623 118.32 66.6986 117.854 66.6762C117.341 66.683 116.833 66.586 116.359 66.3911C115.892 66.1713 115.464 65.8782 115.091 65.5229L115.04 65.4786L114.407 66.258L114.451 66.296C114.913 66.7282 115.45 67.0719 116.035 67.3099C116.622 67.5337 117.245 67.6455 117.873 67.6394C118.264 67.6428 118.653 67.585 119.026 67.4683C119.358 67.37 119.666 67.2062 119.933 66.9867C120.191 66.775 120.398 66.5078 120.538 66.205C120.679 65.9022 120.749 65.5715 120.744 65.2378C120.755 64.9473 120.7 64.658 120.583 64.3922C120.465 64.1263 120.288 63.891 120.066 63.7042C119.429 63.2683 118.7 62.9862 117.936 62.8804Z"
          fill={textColor}
        />
        <path
          d="M126.06 63.2607L123.329 59.205L123.31 59.1797H122.024L125.515 64.2556V67.5698H126.574V64.2429L130.002 59.2747L130.072 59.1797H128.83L126.06 63.2607Z"
          fill={textColor}
        />
        <path
          d="M134.9 62.8811C134.522 62.8064 134.149 62.707 133.784 62.5832C133.546 62.5047 133.322 62.3892 133.119 62.241C132.979 62.1375 132.868 62.0001 132.796 61.8418C132.736 61.6819 132.705 61.5121 132.707 61.3412C132.705 61.1662 132.745 60.9932 132.823 60.8366C132.901 60.6801 133.016 60.5445 133.157 60.4413C133.521 60.1692 133.971 60.0365 134.424 60.0675C134.835 60.0657 135.243 60.1365 135.628 60.2766C136.044 60.4375 136.432 60.6643 136.775 60.9483L136.826 60.9926L137.46 60.1689L137.409 60.1308C137.006 59.8066 136.555 59.5479 136.072 59.3641C135.555 59.1825 135.01 59.0945 134.462 59.1042C134.086 59.1013 133.712 59.1591 133.354 59.2753C133.026 59.3676 132.718 59.5155 132.441 59.7126C132.196 59.9125 131.997 60.1633 131.858 60.4477C131.719 60.7361 131.65 61.0529 131.655 61.3729C131.638 61.6928 131.69 62.0127 131.807 62.3107C131.921 62.5802 132.102 62.8159 132.333 62.9951C132.598 63.21 132.898 63.3772 133.22 63.4894C133.632 63.6438 134.056 63.7646 134.488 63.8506C134.848 63.9251 135.204 64.0225 135.552 64.1421C135.78 64.2189 135.995 64.3324 136.186 64.478C136.325 64.5769 136.432 64.7131 136.497 64.8708C136.562 65.0252 136.594 65.1913 136.592 65.3588C136.595 65.5454 136.553 65.7299 136.469 65.8965C136.385 66.0632 136.262 66.207 136.11 66.3157C135.733 66.5874 135.275 66.7236 134.811 66.7022C134.296 66.7098 133.785 66.6128 133.309 66.4171C132.843 66.1965 132.416 65.9035 132.042 65.5489L131.997 65.5045L131.332 66.284L131.376 66.322C131.84 66.752 132.376 67.0954 132.961 67.3359C133.548 67.5586 134.171 67.6703 134.798 67.6654C135.189 67.6697 135.579 67.6119 135.952 67.4943C136.285 67.3961 136.595 67.2324 136.864 67.0127C137.122 66.801 137.329 66.5338 137.47 66.231C137.61 65.9281 137.68 65.5975 137.675 65.2637C137.687 64.9733 137.632 64.684 137.514 64.4182C137.397 64.1523 137.22 63.917 136.997 63.7302C136.372 63.291 135.654 63.0005 134.9 62.8811Z"
          fill={textColor}
        />
        <path
          d="M139.298 60.1682H142.08V67.5698H143.138V60.1682H145.907V59.1797H139.298V60.1682Z"
          fill={textColor}
        />
        <path
          d="M149.076 63.8246H153.588V62.8614H149.076V60.1429H154.12V59.1797H148.018V67.5698H154.177V66.6066H149.076V63.8246Z"
          fill={textColor}
        />
        <path
          d="M160.425 63.6409L157.447 59.1797H156.401V67.5698H157.44V61.0301L160.336 65.2758L160.355 65.3012H160.469L163.384 61.0174V67.5698H164.443V59.1797H163.403L160.425 63.6409Z"
          fill={textColor}
        />
        <path
          d="M172.231 63.7301C171.616 63.2941 170.91 63.0038 170.165 62.881C169.789 62.8073 169.419 62.7078 169.056 62.5831C168.816 62.5045 168.59 62.389 168.385 62.2409C168.248 62.1355 168.14 61.9985 168.068 61.8417C168.003 61.683 167.97 61.5127 167.973 61.3411C167.971 61.1661 168.01 60.9931 168.089 60.8365C168.167 60.68 168.281 60.5444 168.423 60.4413C168.787 60.1691 169.237 60.0365 169.69 60.0674C170.099 60.0657 170.504 60.1365 170.888 60.2765C171.304 60.436 171.692 60.6629 172.035 60.9482L172.086 60.9926L172.719 60.1688L172.669 60.1308C172.267 59.808 171.818 59.5493 171.338 59.364C170.821 59.1829 170.276 59.0949 169.728 59.1042C169.352 59.1003 168.977 59.1581 168.619 59.2753C168.3 59.3864 168.002 59.5514 167.738 59.7632C167.492 59.9615 167.292 60.2127 167.155 60.4983C167.015 60.7863 166.943 61.103 166.946 61.4235C166.939 61.7342 166.998 62.0429 167.117 62.3297C167.235 62.5966 167.416 62.8313 167.643 63.0141C167.909 63.2276 168.209 63.3947 168.531 63.5083C168.943 63.6624 169.366 63.7832 169.798 63.8695C170.158 63.9449 170.514 64.0423 170.863 64.161C171.091 64.2379 171.305 64.3513 171.496 64.4969C171.633 64.5978 171.74 64.7334 171.807 64.8898C171.866 65.0455 171.897 65.211 171.895 65.3777C171.9 65.5638 171.859 65.7482 171.776 65.9149C171.693 66.0816 171.571 66.2256 171.42 66.3346C171.041 66.6073 170.581 66.7435 170.115 66.7212C169.602 66.7279 169.093 66.6309 168.619 66.436C168.154 66.2147 167.726 65.9217 167.352 65.5678L167.301 65.5235L166.667 66.3029L166.712 66.3409C167.175 66.7709 167.712 67.1143 168.296 67.3548C168.883 67.5775 169.506 67.6892 170.134 67.6844C170.525 67.6877 170.914 67.63 171.287 67.5133C171.618 67.4149 171.926 67.2511 172.193 67.0317C172.451 66.82 172.658 66.5527 172.799 66.2499C172.939 65.9471 173.01 65.6164 173.004 65.2827C173.008 64.9811 172.94 64.6831 172.805 64.4131C172.671 64.1431 172.474 63.909 172.231 63.7301Z"
          fill={textColor}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_626_71441"
          x1="159.88"
          y1="26.2719"
          x2="159.88"
          y2="1.43746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E0E0E0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_626_71441"
          x1="120.015"
          y1="26.2727"
          x2="120.015"
          y2="0.779297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E0E0E0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_626_71441"
          x1="70.3718"
          y1="26.2719"
          x2="70.3718"
          y2="1.43746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E0E0E0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_626_71441"
          x1="28.092"
          y1="26.2719"
          x2="28.092"
          y2="1.43746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E0E0E0" />
        </linearGradient>
        <clipPath id="clip0_626_71441">
          <rect
            width="180"
            height="66.9306"
            fill="white"
            transform="translate(0 0.779297)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
