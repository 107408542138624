import { MCOAInterface, UploadResponse } from "../interfaces/interfaces";
import { httpClient } from "../core/http-client/http-client";
import { AxiosResponse } from "axios";
import { FieldValues } from "react-hook-form";

async function getMCOAs(
  companyId: string | undefined
): Promise<Array<MCOAInterface>> {
  const { data } = await httpClient.get(
    `company/${companyId}/masterchart-accounts/count`
  );
  return httpClient
    .get(`company/${companyId}/masterchart-accounts?size=${data}`)
    .then((response: AxiosResponse): Array<MCOAInterface> => response.data);
}

async function createMCOA({
  companyId,
  data,
}: {
  companyId: string | undefined;
  data: FieldValues;
}): Promise<MCOAInterface> {
  return httpClient
    .post(`company/${companyId}/masterchart-accounts`, {
      ...data,
      company: { id: companyId },
    })
    .then((response: AxiosResponse): MCOAInterface => response.data);
}

async function updateMCOA({
  companyId,
  data,
}: {
  companyId: string | undefined;
  data: FieldValues;
}): Promise<MCOAInterface> {
  return httpClient
    .put(`company/${companyId}/masterchart-accounts/${data.id}`, data)
    .then((response: AxiosResponse): MCOAInterface => response.data);
}

async function uploadMCOA(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/masterchart-accounts/file-upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

const mcoaApi = {
  uploadMCOA,
  getMCOAs,
  createMCOA,
  updateMCOA,
};

export default mcoaApi;
