import { createContext, useCallback, useEffect, useState } from "react";
import { Company } from "../interfaces/interfaces";
import companyApi from "../api/company-api";

interface CompanyProviderProps {
  children: React.ReactNode;
}

const CompanyContext = createContext({} as any);

const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const [company, setCompany] = useState<Company | null>(null);
  const [chosenMonth, setChosenMonth] = useState<string>("");
  const [months, setMonths] = useState<string[]>([]);
  const updateCompany = useCallback(() => {
    if (!company?.id) {
      return;
    }
    companyApi.getCompany(company.id).then((data) => setCompany(data));
  }, [company]);

  useEffect(() => {
    if (company?.companyFiscalPeriodStartDate) {
      const fiscalStartDate = new Date(company.companyFiscalPeriodStartDate);
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      const fiscalStartMonth = fiscalStartDate.getMonth();
      const fiscalYear = fiscalStartDate.getFullYear();
      const monthAmount =
        fiscalYear === currentYear
          ? currentMonth - fiscalStartMonth
          : 12 - fiscalStartMonth + currentMonth;

      if (monthAmount < 0) {
        setMonths([]);
        return;
      }
      const monthList = Array.from(Array(monthAmount).keys()).map(
        (item) => `${item + 1}/${fiscalYear}`
      );
      setMonths(monthList);
      setChosenMonth(monthList.at(-1) || "");
    }
  }, [company]);

  return (
    <CompanyContext.Provider
      value={{
        company,
        setCompany,
        updateCompany,
        months,
        chosenMonth,
        setChosenMonth,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export { CompanyProvider, CompanyContext };
