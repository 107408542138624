const prefix = "egb_";
const storageType = sessionStorage;

const set = (key: string, item: unknown | string | number | boolean): void => {
  if (typeof item === "undefined") return;
  if (
    typeof item === "string" ||
    typeof item === "number" ||
    typeof item === "boolean"
  ) {
    storageType.setItem(`${prefix}${key}`, item.toString());
  } else {
    storageType.setItem(`${prefix}${key}`, JSON.stringify(item));
  }
};

const get = (key: string): string | null => {
  let parsed: string | null;

  try {
    const value = storageType.getItem(`${prefix}${key}`);
    parsed = value ? JSON.parse(value) : value;
  } catch {
    parsed = storageType.getItem(`${prefix}${key}`);
  }

  return parsed;
};

const remove = (key: string): void => {
  storageType.removeItem(`${prefix}${key}`);
};

const clear = (): void => {
  storageType.clear();
};

export const storage = {
  set,
  get,
  remove,
  clear,
};

export default storage;
