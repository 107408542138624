import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import {
  AllocateRequest,
  GlTransaction,
  UploadResponse,
} from "../interfaces/interfaces";

async function getAccounts(
  companyId: string | undefined,
  activePeriod: string | undefined
): Promise<Array<GlTransaction>> {
  const { data } = await httpClient.get(
    `company/${companyId}/grant-transanctions/count`
  );
  if (!activePeriod) {
    return [];
  }
  return httpClient
    .get(
      `company/${companyId}/grant-transanctions?status.notEquals=ALLOCATED_OFFSET&size=${data}${
        activePeriod ? `&activePeriod=${activePeriod}` : ""
      }`
    )
    .then((response: AxiosResponse): Array<GlTransaction> => response.data);
}

async function getAccountsCount(
  companyId: string | undefined
): Promise<number> {
  return httpClient
    .get(`company/${companyId}/grant-transanctions/count`)
    .then((response: AxiosResponse): number => response.data);
}

async function getNotAllocatedAccounts(
  companyId: string | undefined,
  activePeriod: string | undefined
): Promise<Array<GlTransaction>> {
  return httpClient
    .get(
      `company/${companyId}/grant-transanctions/notallocated${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`
    )
    .then((response: AxiosResponse): Array<GlTransaction> => response.data);
}

async function allocateGLTransactions(
  companyId: string | undefined,
  data: AllocateRequest
): Promise<{ errors?: Record<string, string>[]; status: string }> {
  return httpClient
    .post(`company/${companyId}/grant-transanctions/allocate`, data)
    .then(
      (
        response: AxiosResponse
      ): { errors?: Record<string, string>[]; status: string } => response.data
    );
}

async function uploadAccounts(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/grant-transanctions/file-upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

async function addGlTransaction(
  data: Record<string, string>,
  companyId: string | undefined
) {
  return httpClient
    .post(`company/${companyId}/grant-transanctions/`, data)
    .then((response: AxiosResponse): UploadResponse => response.data);
}

async function purgeGlTransactions(
  companyId: string,
  programId: string,
  activePeriod: string | undefined
) {
  return httpClient
    .post(
      `company/${companyId}/grant-transanctions/purge?programId=${programId}${
        activePeriod ? `&activePeriod=${activePeriod}` : ""
      }`
    )
    .then(
      (response: AxiosResponse): { errors?: Record<string, string>[] } =>
        response.data
    );
}

const glAccountsApi = {
  getAccounts,
  uploadAccounts,
  getNotAllocatedAccounts,
  allocateGLTransactions,
  addGlTransaction,
  purgeGlTransactions,
  getAccountsCount,
};

export default glAccountsApi;
