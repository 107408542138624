import React from "react";
import "./confirmation-modal.component.scss";
import Modal from "../modal/modal.component";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  title: string;
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitTitle?: string;
}

export default function ConfirmationModalComponent({
  title,
  show,
  onClose,
  onSubmit,
  submitTitle,
}: ConfirmationModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("general.confirmationTitle")}
      show={show}
      Footer={
        <div className="gap-3 confirmation-footer footer">
          <button onClick={onClose} className="btn btn-secondary">
            {t("general.cancel")}
          </button>
          <button onClick={onSubmit} className="btn btn-primary">
            {submitTitle || t("general.delete")}
          </button>
        </div>
      }
    >
      <div>{title}</div>
    </Modal>
  );
}
