import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { SortDirection, TableProps } from "../table.component";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

interface TableHeaderProps<T> {
  columns: TableProps<T>["columns"];
  columnsPositions: Record<number, string | number>;
  sortValue: string | number | symbol;
  sortDirection: SortDirection;
  setTableHeaderHeight: Dispatch<SetStateAction<number>>;
  setColumnsPositions: Dispatch<
    SetStateAction<Record<number, string | number>>
  >;
  setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  setSortType: Dispatch<SetStateAction<"string" | "number">>;
  setSortValue: Dispatch<SetStateAction<string | number | symbol>>;
  expandableRows?: boolean;
  className?: string;
  freezeColumnsNumber?: number;
}
export default function TableHeader<T>({
  columns,
  columnsPositions,
  sortValue,
  sortDirection,
  setTableHeaderHeight,
  setColumnsPositions,
  setSortDirection,
  setSortType,
  setSortValue,
  expandableRows,
  className,
  freezeColumnsNumber,
}: TableHeaderProps<T>): JSX.Element {
  const Icon = useMemo(
    () => (!(sortDirection === "up") ? FaArrowUp : FaArrowDown),
    [sortDirection]
  );

  const headerRef = useRef<HTMLTableRowElement>(null);

  const onHeaderClick = useCallback(
    (
      sort: boolean | undefined,
      property: keyof T,
      type?: "number" | "string"
    ) => {
      if (!sort) {
        return;
      }
      setSortDirection((state) => (state === "up" ? "down" : "up"));
      if (type) {
        setSortType(type);
      } else {
        setSortType("string");
      }
      setSortValue(property);
    },
    [setSortDirection, setSortType, setSortValue]
  );

  useEffect(() => {
    if (headerRef?.current) {
      setTableHeaderHeight(headerRef.current?.clientHeight);
      const list = headerRef.current.children || [];
      for (let i = 0; i < list.length; i++) {
        setColumnsPositions((state) => {
          const currentColumnWidth = list[i].clientWidth;
          if (i === 0) {
            state[0] = 0;
          }
          return {
            ...state,
            [i + 1]: +state[i] + currentColumnWidth,
          };
        });
      }
    }
  }, [setColumnsPositions, setTableHeaderHeight]);

  return (
    <thead>
      <tr className={`${className ? className : ""}`} ref={headerRef}>
        {columns.map(
          (
            {
              freeze,
              header,
              sort,
              property,
              renderHeader,
              sortType: type,
              textAlign,
            },
            index
          ) => (
            <th
              className={`${freeze ? "freeze" : ""} ${
                freezeColumnsNumber && freezeColumnsNumber - 1 === index
                  ? "show-shadow"
                  : ""
              }`}
              style={{
                ...(freeze && { left: columnsPositions[index] }),
                ...(textAlign && { textAlign }),
              }}
              key={header}
            >
              <div
                key={header}
                onClick={() => onHeaderClick(sort, property, type)}
                className={`header-column gap-3 ${sort && "sort"}`}
              >
                {renderHeader ? (
                  renderHeader(property)
                ) : (
                  <span>{header.toUpperCase()}</span>
                )}
                {sort && sortValue === property && <Icon />}
              </div>
            </th>
          )
        )}
        {expandableRows && <th key="expandable" />}
      </tr>
    </thead>
  );
}
