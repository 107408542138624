import React, { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useLoadData } from "../../../hooks/useLoadData";
import { CompanyContext } from "../../../context/company-context";
import dashboardApi from "../../../api/dashboard-api";
import Spinner from "../../../components/shared/spinner/spinner.component";
import Table from "../../../components/shared/table/table.component";
import { useTranslation } from "react-i18next";
import { getStaticColumns } from "./helpers";
import { DetailsByProgram } from "../../../interfaces/interfaces";
import { formatNumber } from "../../../utils";
import { SORT_TYPES } from "../../../consts";
import BackTo from "../../../components/shared/back-to/back-to.component";

export default function ProgramDashboard(): JSX.Element {
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();
  const { programId } = useParams();
  const [programDashboard, isLoading] = useLoadData({
    fetcher: useCallback(
      () =>
        dashboardApi.getProgramDetails({
          companyId: company?.id,
          programCode: programId,
        }),
      [company?.id, programId]
    ),
  });

  const rowsToDisplay = useMemo(
    () => [
      ...(programDashboard?.prDashboardDetailsByProgram || []),
      ...(programDashboard?.glDashboardDetailsByProgram || []),
    ],
    [programDashboard]
  );

  const uniqueGrants = useMemo<string[]>(
    () => programDashboard?.programGrantCodes || [],
    [programDashboard]
  );

  const columns = useMemo<
    {
      header: string;
      render?: (row: DetailsByProgram) => string | JSX.Element;
      property: keyof DetailsByProgram;
      sum?: (row: DetailsByProgram, acc: number) => number;
      format?: (value: string | JSX.Element) => string | JSX.Element;
    }[]
  >(
    () => [
      ...getStaticColumns({ t }),
      ...uniqueGrants.map((grantCode) => ({
        header: `${t(
          "company.tabs.dashboard.programDetails.mtdGrant"
        )} ${grantCode}, $`,
        property: grantCode,
        sum: (row: DetailsByProgram, acc: number) =>
          (+row.grantCodeAndAmountSpent[grantCode] || 0) + acc,
        format: (value: string | JSX.Element) => formatNumber(+value),
        sort: true,
        sortType: SORT_TYPES.number,
      })),
    ],
    [t, uniqueGrants]
  );

  const rowsWithGrants = useMemo<DetailsByProgram[]>(
    () =>
      rowsToDisplay.map((item) => {
        return {
          ...item,
          ...uniqueGrants.reduce(
            (acc, grant) => ({
              ...acc,
              [grant]: item.grantCodeAndAmountSpent[grant],
            }),
            {}
          ),
        };
      }),
    [rowsToDisplay, uniqueGrants]
  );
  console.log("yyy", rowsWithGrants);
  console.log(columns);
  return (
    <div className="program-dashboard">
      <div className="header flex-fill justify-content-between">
        <BackTo
          link="/dashboard"
          title={`${t("company.tabs.dashboard.programDetails.program")} ${
            programDashboard.programName
          } / ${programId}`}
        />
        {/*todo add export*/}
        <button disabled className="btn btn-primary">
          Export
        </button>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={columns}
          data={rowsWithGrants}
          isFooter
          filter={(row: DetailsByProgram) => !!row?.jobFunctionAcronym}
          filteredTotalTitle={t(
            "company.tabs.grants.finData.list.separatorTitle"
          )}
          totalTitle={t("company.tabs.grants.finData.list.totalTitle")}
          restTotalTitle={t(
            "company.tabs.grants.finData.list.totalSecondTitle"
          )}
          filteredHeader={t(
            "company.tabs.dashboard.programDetails.employeeCompensation"
          )}
          restHeader={t(
            "company.tabs.dashboard.programDetails.notPersonalExpenses"
          )}
        />
      )}
    </div>
  );
}
