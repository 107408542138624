import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS, TYPES_TO_UI } from "../../../consts";
import { Company, User } from "../../../interfaces/interfaces";
import companyApi from "../../../api/company-api";
import permissionsApi from "../../../api/permissions-api";
import { useLoadData } from "../../../hooks/useLoadData";
import PageLayout from "../../../components/shared/page-layout/page-layout.component";
import Table from "../../../components/shared/table/table.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import "./companies-list.component.scss";

export default function CompaniesList(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companiesList, setCompaniesList] = useState<Array<Company>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [companies, areCompaniesLoading] = useLoadData<Company[]>({
    fetcher: companyApi.getCompanies,
  });

  const [companyUsers, areCompanyUsersLoading] = useLoadData<
    Record<string, Array<User>>
  >({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fetcher: permissionsApi.getCompaniesUsers,
  });

  useEffect(() => {
    setIsLoading(true);
    if (!(areCompaniesLoading || areCompanyUsersLoading)) {
      const updatedCompanies = companies.map((company: Company) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isInviteSend = companyUsers[+company.id]?.some(
          (item: User) => item.inviteSent
        );
        return {
          ...company,
          invite: isInviteSend
            ? t("companies.inviteSent")
            : t("companies.inviteNotSent"),
        };
      });
      setCompaniesList(updatedCompanies);
      setIsLoading(false);
    }
  }, [t, companies, companyUsers, areCompaniesLoading, areCompanyUsersLoading]);

  const COLUMNS: {
    header: string;
    property: keyof Company;
    sort?: boolean;
    sortType?: "string" | "number";
    render?: (row: Company) => JSX.Element | string;
  }[] = [
    {
      header: t("companies.list.id"),
      property: "id",
      sort: true,
      sortType: "number",
    },
    {
      header: t("companies.list.name"),
      property: "name",
      sort: true,
    },
    {
      header: t("companies.list.invite"),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      property: "invite",
    },
    {
      header: t("companies.list.type"),
      property: "companyType",
      render: ({ companyType }: Company): string =>
        TYPES_TO_UI[companyType as keyof typeof TYPES_TO_UI],
    },
    {
      header: t("companies.list.status"),
      property: "license",
    },
    {
      header: "",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      property: "",
      render: (data: { [key: string]: string } | Company): JSX.Element => (
        <Link
          className="text-decoration-none"
          to={`/companies/company/${data.id}`}
        >
          {t("companies.list.details")}
        </Link>
      ),
    },
  ];

  return (
    <PageLayout
      smallHeader
      title={t("companies.pageTitle")}
      actionTitle={t("companies.createCompany")}
      action={() => navigate(`/${ROUTE_PATHS.companies}/company`)}
    >
      {!isLoading ? (
        <Table search columns={COLUMNS} data={companiesList} />
      ) : (
        <Spinner size="medium" />
      )}
    </PageLayout>
  );
}
