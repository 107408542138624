import { useCallback, useContext } from "react";
import { ToastContext } from "../context/toast.context";
import { TOAST_TYPES } from "../consts";
import { useTranslation } from "react-i18next";
import { CustomError } from "../interfaces/interfaces";

export function useErrorHandler(): (
  err?: CustomError,
  customMessage?: string,
  callback?: () => void
) => void {
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  return useCallback(
    (err, customMessage, callback?: () => void) => {
      const message = customMessage || err?.title || err?.detail;
      addToast({
        id: Math.random().toString(),
        type: TOAST_TYPES.error,
        message: message || t("general.serverError"),
      });
      if (callback) {
        callback();
      }
    },
    [addToast, t]
  );
}
