import "./forgot-password.component.scss";
import React, { useMemo, useState } from "react";
import LoginWrapper from "../wrapper/wrapper.component";
import { useTranslation } from "react-i18next";
import { FieldValues, useForm } from "react-hook-form";
import authApi from "../../../api/auth-api";
import useCount, { UseCountInterface } from "../../../hooks/useCount";

const INIT_COUNT = 60;

export default function ForgotPassword(): JSX.Element {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string | null>(null);
  const [key, setKey] = useState<string | null>(null);
  const [mfaKey, setMfaKey] = useState<string | null>(null);
  const [subtitle, setSubtitle] = useState<string>(
    t("login.updatePassword.subtitle")
  );
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm();

  const isFormHigh = useMemo<boolean>(
    () => !!subtitle && subtitle !== t("login.updatePassword.subtitle"),
    [t, subtitle]
  );

  const { count, resentMfaCode, disabled }: UseCountInterface = useCount(
    mfaKey,
    INIT_COUNT
  );
  const submit = async (data: FieldValues): Promise<void> => {
    if (!email) {
      authApi.resetPassword(data.email).then(
        ({ resetKey, mfaKey: newMfaKey }) => {
          setEmail(data.email);
          setKey(resetKey);
          setMfaKey(newMfaKey);
          setSubtitle("");
        },
        (err) =>
          setError("email", {
            message: err.detail || err.title || t("general.serverError"),
          })
      );
    } else {
      authApi.verifyMfa(data, key).then(
        (res: string) => setSubtitle(res),
        (err) =>
          setError("email", {
            message: err.detail || err.title || t("general.serverError"),
          })
      );
    }
  };
  return (
    <LoginWrapper
      title={t("login.updatePassword.forgotPassword")}
      subtitle={subtitle}
    >
      {!isFormHigh && (
        <form onClick={() => clearErrors()} onSubmit={handleSubmit(submit)}>
          <div className="mb-4">
            <label htmlFor="email" className="form-label">
              {t("login.updatePassword.email")}
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              {...register("email")}
            />
            {errors.email && (
              <div className="error">{errors.email.message}</div>
            )}
          </div>
          {email && (
            <div className="mb-4">
              <label htmlFor="mfaCode" className="form-label">
                {t("login.updatePassword.verificationCode")}
              </label>
              <input
                type="text"
                className="form-control"
                id="mfaCode"
                {...register("mfaCode")}
              />
            </div>
          )}
          <div className="d-grid gap-2 mb-4">
            <button type="submit" className="btn btn-primary">
              {t("login.loginForm.nextBtn")}
            </button>
            {/*todo move to separate component*/}
            {key && (
              <div className="count gap-3">
                <span>{`${count} ${t("login.loginForm.seconds")}`} </span>
                <a href={disabled ? undefined : "#"} onClick={resentMfaCode}>
                  {t("login.loginForm.sendAgain")}
                </a>
              </div>
            )}
          </div>
        </form>
      )}
    </LoginWrapper>
  );
}
