export const formatNumber = (value: number) => {
  if (!value) {
    return "0.00";
  }
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatToNumber = (value: string) => {
  if (!value) {
    return;
  }
  return value.replace(/\,/g, "");
};
