import { TFunction } from "i18next";
import {
  DetailsByGrant,
  DetailsByProgram,
} from "../../../interfaces/interfaces";
import { SortTypes } from "../../../interfaces/types";
import { SORT_TYPES } from "../../../consts";
import { formatNumber } from "../../../utils";

export const getStaticColumns = ({
  t,
  showBalance,
}: {
  t: TFunction;
  showBalance: boolean;
}): {
  header: string;
  render?: (row: DetailsByGrant) => JSX.Element | string;
  property: keyof DetailsByProgram;
  sum?: (row: DetailsByGrant, acc: number) => number;
  format?: (value: string | JSX.Element) => string | JSX.Element;
  freeze?: boolean;
  sort?: boolean;
  sortType?: SortTypes;
  textAlign?: CanvasTextAlign;
}[] => [
  {
    header: t("company.tabs.dashboard.grantDetails.expenseCategory"),
    render: (row: DetailsByGrant) =>
      row?.jobFunctionAcronym || row.accountName || "",
    property: "accountName",
    freeze: !showBalance,
    sort: true,
    sortType: SORT_TYPES.string,
    textAlign: "left",
  },
  ...(showBalance
    ? [
        {
          header: t("company.tabs.dashboard.grantDetails.originalBudget"),
          property: "originalBudget",
          sum: (row: DetailsByGrant, acc: number) => +row.originalBudget + acc,
          format: (value: string | JSX.Element) => formatNumber(+value),
          sort: true,
          sortType: SORT_TYPES.number,
        },
      ]
    : []),
  {
    header: t("company.tabs.dashboard.grantDetails.modifiedBudget"),
    property: "modifiedBudget",
    sum: (row: DetailsByGrant, acc: number) => +row.modifiedBudget + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: !showBalance,
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.grantDetails.totalSpenting"),
    property: "totalSpent",
    sum: (row: DetailsByGrant, acc: number) => +row.totalSpent + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: !showBalance,
    sort: true,
    sortType: SORT_TYPES.number,
  },
  {
    header: t("company.tabs.dashboard.grantDetails.remainingBalance"),
    property: "remainingBalance",
    sum: (row: DetailsByGrant, acc: number) => +row.remainingBalance + acc,
    format: (value: string | JSX.Element) => formatNumber(+value),
    freeze: !showBalance,
    sort: true,
    sortType: SORT_TYPES.number,
  },
];
// todo think about data format validation
export const getFormattedDateMonthFirst = (date: string): string => {
  const [day, month, year] = date?.split("/") || [];
  return `${month}/${day}/${year}`;
};

export const getMonthFromPeriod = ({
  start,
  end,
}: {
  start: string;
  end: string;
}): string[] => {
  const startDate = new Date(getFormattedDateMonthFirst(start));
  const endDate = new Date(getFormattedDateMonthFirst(end));
  const startMonth = startDate.getMonth() + 1;
  const startYear = startDate.getFullYear();
  const endMonth = endDate.getMonth() + 1;
  const endYear = endDate.getFullYear();
  if (!startMonth || !endMonth) {
    return [];
  }
  const prefixMonth = Array.from(Array(13 - startMonth).keys()).map(
    (item) => `${item + startMonth}/${startYear}`
  );
  const suffixMonth = Array.from(Array(endMonth).keys()).map(
    (item) => `${item + 1}/${endYear}`
  );
  if (endYear === startYear) {
    return Array.from(Array(endMonth - startMonth + 1).keys()).map(
      (item) => `${item + startMonth}/${startYear}`
    );
  }
  if (endYear - startYear === 1) {
    return [...prefixMonth, ...suffixMonth];
  }
  const betweenMonth: string[] = [];
  const years = Array.from(Array(endYear - startYear).keys())
    .map((item) => item + startYear)
    .filter((_, index) => index > 0);
  years.forEach((year) => {
    Array.from(Array(12).keys()).forEach((month) => {
      betweenMonth.push(`${month + 1}/${year}`);
    });
  });
  return [...prefixMonth, ...betweenMonth, ...suffixMonth];
};

export const getMonthFromDate = (date: string): string => {
  const dateObject = new Date(getFormattedDateMonthFirst(date));
  return `${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
};

export const sortMonthAndYearValue = (a: string, b: string) => {
  const [monthOne, yearOne] = a.split("/") || [];
  const [monthTwo, yearTwo] = b.split("/") || [];
  if (+yearOne < +yearTwo) {
    return -1;
  }
  if (+yearOne > +yearTwo) {
    return 1;
  }
  if (+monthOne < +monthTwo) {
    return -1;
  }
  if (+monthOne > +monthTwo) {
    return 1;
  }
  return 0;
};
