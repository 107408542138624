import { useCallback } from "react";
import { AxiosResponse } from "axios";
import { useErrorHandler } from "../../../hooks/useErrorHandler";

export function DownloadButtonComponent({
  label,
  downloadHandler,
  fileName,
  primary = true,
  disabled = false,
}: {
  label: string;
  downloadHandler: () => Promise<AxiosResponse>;
  fileName?: string;
  primary?: boolean;
  disabled?: boolean;
}): JSX.Element {
  const errorHandler = useErrorHandler();
  const download = useCallback(() => {
    downloadHandler().then(
      ({ data: blob, headers }) => {
        const content = headers?.["content-disposition"];
        const name = content?.split("filename=")?.[1]?.replaceAll('"', "");
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name || fileName || "file");

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      },
      (err) => errorHandler(err)
    );
  }, [downloadHandler, errorHandler, fileName]);
  return (
    <button
      onClick={download}
      className={`btn btn-${primary ? "primary" : "secondary"}`}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
