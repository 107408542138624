import { USER_ROLE_LABELS, USER_ROLES } from "../consts";

const getRole = (roles: Array<string>): string =>
  Object.entries(USER_ROLE_LABELS).find(([key]) => roles?.includes(key))?.[1] ||
  USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN];

const utils = {
  getRole,
};

export default utils;
