import React, { useContext, useState } from "react";
import "./login.component.scss";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/user.context";
import authApi from "../../api/auth-api";
import { LoginResponse, User } from "../../interfaces/interfaces";
import { getUser } from "../../api/user-api";
import LoginWrapper from "./wrapper/wrapper.component";
import useCount, { UseCountInterface } from "../../hooks/useCount";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../consts";

const INIT_COUNT = 60;

export default function Login(): JSX.Element {
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [key, setKey] = useState<string | null>(null);

  const { count, resentMfaCode, disabled }: UseCountInterface = useCount(
    key,
    INIT_COUNT
  );

  const onSubmit = async (data: FieldValues): Promise<void> => {
    try {
      const { email, password, confirmationCode }: FieldValues = data;
      if (key) {
        const { userId }: LoginResponse = await authApi.mfaLogin(
          confirmationCode,
          key
        );
        if (userId) {
          getUser(userId).then((res: User) => setUser(res));
        }
      } else {
        const { mfaKey, userId }: LoginResponse = await authApi.login(
          email,
          password
        );
        if (mfaKey) {
          setKey(mfaKey);
        }
        if (userId) {
          getUser(userId).then((res: User) => setUser(res));
        }
      }
    } catch (err: any) {
      setError("email", { message: err.detail || t("general.serverError") });
      setError("confirmationCode", {
        message: err.detail || err.title || t("general.serverError"),
      });
    }
  };

  return (
    <LoginWrapper
      title={t(`login.pageTitle.${key ? "welcome" : "login"}`)}
      subtitle={key ? t("login.pageSubtitle") : ""}
    >
      <form onClick={() => clearErrors()} onSubmit={handleSubmit(onSubmit)}>
        {key ? (
          <div className="mb-4">
            <label className="form-check-label" htmlFor="confirmationCode">
              {t("login.loginForm.confirmationCodePlaceholder")}
            </label>
            <input
              id="confirmationCode"
              type="text"
              className="form-control"
              {...register("confirmationCode")}
            />
            {errors?.email && (
              <div className="error">{errors?.confirmationCode?.message}</div>
            )}
          </div>
        ) : (
          <>
            <div className="mb-4">
              <label className="form-check-label" htmlFor="login">
                {t("login.loginForm.login")}
              </label>
              <input
                id="login"
                type="text"
                className="form-control"
                {...register("email")}
              />
              {errors?.email && (
                <div className="error">{errors?.email?.message}</div>
              )}
            </div>
            <div className="mb-4">
              <label className="form-check-label" htmlFor="password">
                {t("login.loginForm.password")}
              </label>
              <input
                id="password"
                type="password"
                className="form-control"
                {...register("password")}
              />
            </div>
          </>
        )}
        <div className="d-grid gap-2 mb-4">
          <button type="submit" className="btn btn-primary">
            {t(`login.loginForm.${key ? "submitBtn" : "nextBtn"}`)}
          </button>
        </div>
        {key ? (
          <div className="count gap-3">
            <span>{`${count} ${t("login.loginForm.seconds")}`} </span>
            <a href={disabled ? undefined : "#"} onClick={resentMfaCode}>
              {t("login.loginForm.sendAgain")}
            </a>
          </div>
        ) : (
          <div className="forgot-password">
            <Link to={`/${ROUTE_PATHS.forgotPassword}`}>
              {t("login.loginForm.forgotPassword")}
            </Link>
          </div>
        )}
      </form>
    </LoginWrapper>
  );
}
