import React from "react";
import "./history-modal.component.scss";
import Modal from "../../../../components/shared/modal/modal.component";
import { useTranslation } from "react-i18next";
import { ModificationHistory } from "../budget.component";

interface Column {
  property: keyof ModificationHistory;
  header: string;
}

interface HistoryModalProps {
  show: boolean;
  onClose: () => void;
  data: Array<ModificationHistory>;
}

export default function HistoryModalComponent({
  show,
  onClose,
  data,
}: HistoryModalProps): JSX.Element {
  const { t } = useTranslation();
  const COLUMNS: Array<Column> = [
    {
      property: "date",
      header: t("company.tabs.grants.finData.historyModal.modificationDate"),
    },
    {
      property: "account",
      header: t("company.tabs.grants.finData.historyModal.accountDescription"),
    },
    {
      property: "description",
      header: t(
        "company.tabs.grants.finData.list.account.accountGrouping.name"
      ),
    },
    {
      property: "amount",
      header: t("company.tabs.grants.finData.historyModal.amount"),
    },
  ];

  return (
    <Modal
      size="large"
      onClose={onClose}
      show={show}
      title={t("company.tabs.grants.finData.historyModal.title")}
      centredContent={true}
    >
      <div className="modal-body">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              {COLUMNS.map(({ header }) => (
                <th key={header}>{header.toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row: ModificationHistory) => (
              <tr key={row.id}>
                {COLUMNS.map(({ property }: Column) => (
                  <td key={property}>{row[property]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
