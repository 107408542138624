import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../../consts";
import { Program } from "../../../interfaces/interfaces";
import fileApi from "../../../api/file-api";
import { CompanyContext } from "../../../context/company-context";
import { Action } from "../../../components/shared/empty-list/empty-list.component";
import ButtonsComponent from "../../../components/shared/download-upload-buttons/buttons.component";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import WarningButtonComponent from "../../../components/shared/warning-button/warning-button.component";
import "./action-header.component.scss";
import MonthSwitcher from "../../../components/shared/month-switcher/month-switcher-component";

interface ActionHeaderProps {
  purgePrograms: Program[];
  allocationPrograms: Program[];
  runAllocation: () => Promise<void>;
  runPurge: () => Promise<void>;
  loadGlAccounts: () => void;
  importActions: Action[];
  uploadActions: Action[];
  uploadWarning?: string;
  allocationProgram: string;
  setAllocationProgram: (code: string) => void;
  purgeProgram: string;
  setPurgeProgram: (code: string) => void;
}

export default function ActionHeaderComponent({
  purgePrograms,
  allocationPrograms,
  runAllocation,
  runPurge,
  importActions,
  uploadActions,
  uploadWarning,
  allocationProgram,
  setAllocationProgram,
  purgeProgram,
  setPurgeProgram,
}: ActionHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { company, chosenMonth } = useContext(CompanyContext);
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const allocationWarningMessage = useMemo(
    () =>
      allocationProgram === "all"
        ? t("shared.allocation.warningAll")
        : t("shared.allocation.warningCode", {
            code: allocationPrograms.find(
              (program) => program.id.toString() === allocationProgram
            )?.code,
          }),
    [t, allocationProgram, allocationPrograms]
  );
  const purgeWarningMessage = useMemo(
    () =>
      purgeProgram === "all"
        ? t("shared.purge.warningAll")
        : t("shared.purge.warningCode", {
            code: purgePrograms.find(
              (program) => program.id.toString() === purgeProgram
            )?.code,
          }),
    [t, purgePrograms, purgeProgram]
  );

  return (
    <div className="header-actions">
      <div className="actions gap-5">
        <MonthSwitcher />
        <div className="d-flex flex-column gap-1">
          {ButtonsComponent({ importActions, uploadActions, uploadWarning })}
        </div>
        <div>
          <label htmlFor="allocate">Allocate Direct Expenses</label>
          <div className="select-wrapper gap-3">
            <select
              onChange={(e) => setAllocationProgram(e.target.value)}
              className="form-select"
              id="allocate"
              defaultValue={allocationProgram}
            >
              <option value="all">All</option>
              {allocationPrograms.map(({ id, code, name }) => (
                <option key={id} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              title={t("shared.allocation.label")}
              warning={allocationWarningMessage}
              callback={runAllocation}
            />
          </div>
        </div>
        <div>
          <label htmlFor="purge">{t("company.tabs.gl.purgeLabel")}</label>
          <div className="select-wrapper gap-3">
            <select
              onChange={(e) => setPurgeProgram(e.target.value)}
              className="form-select"
              id="purge"
              defaultValue={purgeProgram}
            >
              <option value="all">All</option>
              {purgePrograms.map(({ id, code, name }) => (
                <option key={id} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              title={t("company.tabs.gl.purge")}
              warning={purgeWarningMessage}
              callback={runPurge}
            />
          </div>
        </div>
        <DownloadButtonComponent
          fileName="gl-transactions-export.xlsx"
          label={t("company.tabs.gl.downloadTitle")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${
                company.id
              }/grant-transanctions/journal-entries${
                activePeriod ? `?activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
        <DownloadButtonComponent
          fileName="gl-transactions-export.xlsx"
          label={t("general.export")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${
                company.id
              }/grant-transanctions-export?status.notEquals=BUDGED_MODIFIED${
                activePeriod ? `&activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
      </div>
    </div>
  );
}
