import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import { Company, User } from "../interfaces/interfaces";
import { FieldValues } from "react-hook-form";
import { buildFromDto as buildUserModel } from "./user-api";
const baseUrl = "companies";
const buildFromDto = (company: Company): Company => ({
  ...company,
  license: company.licenseActive ? "Active" : "Inactive",
});

const buildToDto = (
  company: FieldValues
): Record<string, string | boolean> => ({
  ...company,
  licenseActive: company.license === "Active",
});

async function getCompanies(): Promise<Array<Company>> {
  const { data } = await httpClient.get(`${baseUrl}/count`);
  return httpClient
    .get(`${baseUrl}?size=${data}`)
    .then(
      (response: AxiosResponse): Array<Company> =>
        response.data.map(buildFromDto)
    );
}

async function getCompanyUsers(companyId: string): Promise<Array<User>> {
  return httpClient
    .get(`company/${companyId}/users`)
    .then(
      (response: AxiosResponse): Array<User> =>
        response.data.map(buildUserModel)
    );
}

async function getCompany(id: string): Promise<Company> {
  return httpClient
    .get(`${baseUrl}/${id}`)
    .then((response: AxiosResponse): Company => buildFromDto(response.data));
}

async function createCompany(company: FieldValues): Promise<Company> {
  return httpClient
    .post(baseUrl, buildToDto(company))
    .then((response: AxiosResponse) => buildFromDto(response.data));
}

export function editCompany(company: FieldValues): Promise<Company> {
  return httpClient
    .put(`${baseUrl}/${company.id}`, buildToDto(company))
    .then((response: AxiosResponse) => buildFromDto(response.data));
}

export function setFileNamePattern(
  pattern: string[],
  companyId: string | undefined
): Promise<Record<string, never>> {
  return httpClient
    .post(`company-settings/file-name-pattern/${companyId}`, {
      fileNameParts: pattern,
    })
    .then((response: AxiosResponse) => response.data);
}
export function getFileNamePattern(
  companyId: string | undefined
): Promise<{ fileNameParts: Array<string> }> {
  return httpClient
    .get(`company-settings/file-name-pattern/${companyId}`)
    .then((response: AxiosResponse) => response.data);
}

export function uploadFiles(
  files: FormData,
  companyId: string | undefined
): Promise<AxiosResponse> {
  return httpClient
    .post(`document-management/upload-files/${companyId}`, files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse) => response.data);
}

export const companyApi = {
  getCompanies,
  createCompany,
  getCompany,
  getCompanyUsers,
  editCompany,
  setFileNamePattern,
  getFileNamePattern,
  uploadFiles,
};

export default companyApi;
