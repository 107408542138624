import "./spinner.component.scss";

import classNames from "classnames";

import { SpinnerSize, SpinnerVariant } from "../../../interfaces/types";

interface SpinnerProps {
  variant?: SpinnerVariant;
  size?: SpinnerSize;
}

export default function Spinner({
  variant = "dark",
  size = "tiny",
}: SpinnerProps): JSX.Element {
  const className = classNames(
    "spinner",
    { light: variant === "light" },
    { dark: variant === "dark" },
    size
  );

  return (
    <div className="spinner-wrapper">
      <div className={className}></div>
    </div>
  );
}
