import React from "react";
import UploadButtonComponent from "../upload-button/upload-button.component";
import { Action } from "../empty-list/empty-list.component";
import { DownloadButtonComponent } from "../download-button/download-button.component";
import fileApi from "../../../api/file-api";

export default function ButtonsComponent({
  importActions,
  uploadActions,
  uploadWarning,
}: {
  importActions: Action[];
  uploadActions: Action[];
  uploadWarning?: string;
}): JSX.Element[] {
  return [
    ...importActions.map(
      ({ url, title }: Action): JSX.Element => (
        <DownloadButtonComponent
          key={title}
          label={title}
          downloadHandler={() => fileApi.downloadFile(url || "")}
          primary={false}
        />
      )
    ),
    ...uploadActions.map(
      ({ title, action, confirmation, preselection }: Action): JSX.Element => (
        <UploadButtonComponent
          uploadWarning={uploadWarning}
          key={title}
          upload={action}
          title={title}
          confirmation={confirmation}
          preselection={preselection}
        />
      )
    ),
  ];
}
