import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import { CompanyContext } from "../../../context/company-context";

export default function MonthSwitcher() {
  const { t } = useTranslation();
  const { months, chosenMonth, setChosenMonth } = useContext(CompanyContext);
  return (
    <div>
      <label htmlFor="month-switcher" className="form-label">
        {t("general.accountingPeriod")}
      </label>
      <select
        className="form-select"
        id="month-switcher"
        onChange={({ target }) => setChosenMonth(target.value)}
        value={chosenMonth}
      >
        {months.map((month: string) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
    </div>
  );
}
