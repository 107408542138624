import React from "react";
import "./form.input.component.scss";
import { UseFormRegisterReturn } from "react-hook-form";

interface FormSelectComponentProps {
  label: string;
  error?: string | undefined;
  inputProps: UseFormRegisterReturn;
  property: string;
  readonly?: boolean;
  options?: Array<{ value: string; label: string }>;
}

export default function FormSelectComponent({
  label,
  error,
  inputProps,
  property,
  readonly = false,
  options = [],
}: FormSelectComponentProps): JSX.Element {
  return (
    <div className="mb-3 form-field">
      <label htmlFor={property} className="form-label">
        {label}
      </label>
      <select
        disabled={readonly}
        className="form-select"
        id={property}
        {...inputProps}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <div className="error">{error}</div>}
    </div>
  );
}
