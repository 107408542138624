import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { USER_ROLE_LABELS, USER_ROLES } from "../../consts";
import { User } from "../../interfaces/interfaces";
import companyApi from "../../api/company-api";
import userApi from "../../api/user-api";
import { CompanyContext } from "../../context/company-context";
import { ToastContext } from "../../context/toast.context";
import { UserContext } from "../../context/user.context";
import { useLoadData } from "../../hooks/useLoadData";
import Modal from "../../components/shared/modal/modal.component";
import ConfirmationModalComponent from "../../components/shared/confirmation-modal/confirmation-modal.component";
import Table from "../../components/shared/table/table.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import UserForm from "../../components/forms/user-form/user-form.component";
import "./user.details.component.scss";

export default function UserDetailsComponent(): JSX.Element | null {
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();
  const isUserAdmin = user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN];
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [showInviteConfirmation, setShowInviteConfirmation] =
    useState<boolean>(false);
  const [companyUsers, isLoading, loadCompanyUsers] = useLoadData<User[]>({
    fetcher: useCallback(
      () => companyApi.getCompanyUsers(company.id),
      [company]
    ),
  });

  const sendInvite = useCallback(
    (userId: string | undefined): void => {
      if (!showInviteConfirmation && currentUser && currentUser.inviteSent) {
        setShowInviteConfirmation(true);
        setOpenForm(false);
        return;
      }
      userApi
        .sendInvite(userId)
        .then(
          () => {
            addToast({
              id: currentUser?.id,
              type: "success",
              message: t("users.userForm.sendInviteSuccess"),
            });
            loadCompanyUsers();
          },
          () =>
            addToast({
              id: currentUser?.id,
              type: "error",
              message: t("users.userForm.sendInviteError"),
            })
        )
        .finally(() => {
          setOpenForm(false);
          setShowInviteConfirmation(false);
          setCurrentUser(null);
        });
    },
    [showInviteConfirmation, currentUser, addToast, t, loadCompanyUsers]
  );

  const COLUMNS: {
    header: string;
    property: keyof User;
    disabledClick?: boolean;
    sort?: boolean;
    render?: (row: User) => JSX.Element | string;
  }[] = [
    {
      header: t("company.tabs.user-details.list.id"),
      property: "id",
      sort: true,
    },
    {
      header: t("company.tabs.user-details.list.name"),
      property: "firstName",
      sort: true,
    },
    {
      header: t("company.tabs.user-details.list.role"),
      property: "role",
      sort: true,
    },
    {
      header: t("company.tabs.user-details.list.invite"),
      property: "inviteSent",
      render: (row: User) =>
        row.inviteSent ? "Invite sent" : "Invite not sent",
    },
    {
      header: "",
      disabledClick: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      property: "",
      render: (): JSX.Element => (
        <a className="text-decoration-none">{t("general.disable")}</a>
      ),
    },
  ];

  return (
    <>
      <ConfirmationModalComponent
        title={t("users.userForm.reSendInviteBody", { id: currentUser?.id })}
        show={showInviteConfirmation}
        onSubmit={() => sendInvite(currentUser?.id)}
        onClose={() => {
          setShowInviteConfirmation(false);
          setCurrentUser(null);
        }}
        submitTitle={t("users.userForm.reSendInvite")}
      />
      <Modal
        centredContent={false}
        className="user-form-modal"
        show={openForm}
        title={t("users.userForm.formTitle")}
        onClose={() => {
          setOpenForm(false);
          setCurrentUser(null);
        }}
      >
        <UserForm
          sendInvite={isUserAdmin ? sendInvite : undefined}
          readonly={!isUserAdmin && currentUser?.id !== user.id}
          entity={currentUser}
          getUsers={loadCompanyUsers}
          key={currentUser?.id}
          onClose={() => {
            setOpenForm(false);
            setCurrentUser(null);
          }}
        />
      </Modal>
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        <>
          {isUserAdmin && (
            <div className="header">
              <button
                onClick={() => setOpenForm(true)}
                className="btn btn-primary"
              >
                {t("general.add")}
              </button>
            </div>
          )}
          <Table
            columns={COLUMNS}
            data={companyUsers}
            onRowClick={(row) => {
              setCurrentUser(row);
              setOpenForm(true);
            }}
          />
        </>
      )}
    </>
  );
}
