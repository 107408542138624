import React from "react";
import "./program-form.component.scss";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormSelectComponent from "../form-fields/form-select.component";
import { PROGRAM_TYPES } from "../../../consts/program";
import FormInputComponent from "../form-fields/form-input.component";

interface ProgramFormComponentInterface {
  onSubmit: (data: FieldValues) => void;
  onClose: () => void;
  entity?: FieldValues;
}

export default function ProgramFormComponent({
  onSubmit,
  onClose,
  entity,
}: ProgramFormComponentInterface): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    ...(entity && { defaultValues: entity }),
  });
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <FormInputComponent
          label={t("company.tabs.programs.list.header.id")}
          inputProps={register("code", {
            required: {
              value: true,
              message: t("general.form.validation.required"),
            },
          })}
          property={"code"}
          error={errors?.code?.message}
        />
      </div>
      <div className="mb-4">
        <FormInputComponent
          label={t("company.tabs.programs.list.header.name")}
          inputProps={register("name", {
            required: {
              value: true,
              message: t("general.form.validation.required"),
            },
          })}
          property={"name"}
          error={errors?.name?.message}
        />
      </div>
      <div className="mb-4">
        <FormSelectComponent
          label={t("company.tabs.programs.form.departmentType")}
          inputProps={register("category")}
          property="category"
          options={PROGRAM_TYPES.map((item) => ({ value: item, label: item }))}
        />
      </div>
      <div className="gap-2 modal-buttons">
        <button onClick={onClose} className="btn w-50 btn-secondary">
          {t("general.cancel")}
        </button>
        <button type="submit" className="btn w-50 btn-primary">
          {t("general.save")}
        </button>
      </div>
    </form>
  );
}
