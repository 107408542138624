import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./header.component.scss";
import UserMenu from "./userMenu/user-menu-component";
import { ROUTES, colors } from "../../../consts";
import { useContext } from "react";
import { UserContext } from "../../../context/user.context";
import Logo from "../../shared/logo/logo.component";

interface Route {
  key: string;
  permissionsMatcher: (arg: [string]) => boolean;
}

export default function Header(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <Logo
            secondaryColor={colors.darkBlue}
            textColor={colors.darkBlue}
            width="120"
            height="45"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        />
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav me-auto gap-3 mb-2 mb-lg-0">
            {ROUTES.map(
              ({ key, permissionsMatcher }: Route): JSX.Element | null =>
                permissionsMatcher(user?.authorities) ? (
                  <li className="nav-item" key={key}>
                    <NavLink
                      className={(navData) =>
                        navData.isActive ? "nav-link active" : "nav-link"
                      }
                      to={`/${key}`}
                      aria-current="page"
                    >
                      {t(`shared.navbar.${key}`)}
                    </NavLink>
                  </li>
                ) : null
            )}
          </ul>
          <UserMenu />
        </div>
      </div>
    </nav>
  );
}
