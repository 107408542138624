import React from "react";
import "./wrapper.component.scss";
import Logo from "../../shared/logo/logo.component";
import { useTranslation } from "react-i18next";

interface LoginWrapperProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
}

export default function LoginWrapper({
  children,
  title,
  subtitle,
}: LoginWrapperProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="page-container">
      <Logo />
      <div className="page-wrapper">
        <div className="page">
          <div className="mb-4">
            <h4 className="page-header">{title}</h4>
            {subtitle && <span>{subtitle}</span>}
          </div>
          {children}
        </div>
        <div className="page-footer">
          <span>
            {t("login.footer.prefix")}
            <a href="#">{t("login.footer.terms")}</a>
            <a href="#">{t("login.footer.dataPolicy")}</a>
            <span>{t("login.footer.and")}</span>
            <a href="#">{t("login.footer.cookiesPolicy")}</a>
          </span>
        </div>
      </div>
    </div>
  );
}
