import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Company,
  CustomError,
  GrantFinData,
} from "../../../interfaces/interfaces";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CompanyContext } from "../../../context/company-context";
import { ToastContext } from "../../../context/toast.context";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import grantsApi from "../../../api/grants-api";
import { TOAST_TYPES } from "../../../consts";
import { ModificationHistory } from "./budget.component";
import { TFunction } from "i18next";

interface BudgetData {
  t: TFunction;
  renderAllModificationHeader: () => string | JSX.Element;
  isEditing: boolean;
  budgetModifications: Record<string, number | string>;
  setBudgetModifications: Dispatch<
    SetStateAction<Record<string, string | number>>
  >;
  isModificationsBalance: boolean;
  showModificationHistoryModal: boolean;
  setShowModificationHistoryModal: Dispatch<SetStateAction<boolean>>;
  budgetModificationsHistory: ModificationHistory[];
  pathname: string;
  modifyBudget: () => void;
  company: Company;
  currentBudget: GrantFinData[];
  grantId: string | undefined;
}

export const useBudgetData = (): BudgetData => {
  const [currentBudget, setCurrentBudget] = useState<Array<GrantFinData>>([]);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const { company } = useContext(CompanyContext);
  const { id: grantId } = useParams();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();

  const [budgetModifications, setBudgetModifications] = useState<
    Record<string, string | number>
  >({});

  const [showModificationHistoryModal, setShowModificationHistoryModal] =
    useState<boolean>(false);

  const [budgetModificationsHistory, setBudgetModificationsHistory] = useState<
    Array<ModificationHistory>
  >([]);

  const loadFinData = useCallback(async () => {
    try {
      const budgets = await grantsApi.getFinData(company.id, grantId);
      setCurrentBudget(
        budgets.map((budget) => ({
          ...budget,
          descriptionOrAcronym:
            budget?.jobFunction?.acronym || budget?.account?.name,
        }))
      );
      const modifiedTransactions = await grantsApi.getTransactions(
        company.id,
        grantId
      );
      setBudgetModificationsHistory(
        modifiedTransactions.map(
          ({
            id: transactionId,
            postingDate: date,
            amount,
            account,
            grantBudgetId,
          }) => ({
            id: transactionId,
            date,
            amount,
            description:
              budgets.find(({ id: budgetId }) => grantBudgetId === budgetId)
                ?.jobFunction?.acronym || account?.name,
            account: account?.code,
          })
        )
      );
    } catch (err) {
      errorHandler(err as CustomError);
    }
  }, [company.id, errorHandler, grantId]);

  useEffect((): void => {
    loadFinData();
  }, [company, id, loadFinData]);

  const isModificationsBalance = useMemo(
    () =>
      !Object.values(budgetModifications).reduce(
        (a, b) => (+a || 0) + (+b || 0),
        0
      ),
    [budgetModifications]
  );

  const modifyBudget = useCallback(() => {
    if (!isEditing) {
      return setIsEditing(true);
    }
    grantsApi
      .modifyGrantBudget(company.id, {
        modifications: Object.entries(budgetModifications)
          .filter(([, value]) => value)
          .map(([grantBudgetId, value]) => ({ grantBudgetId, value })),
      })
      .then(
        (data) => {
          if (data.status === "ERROR") {
            return errorHandler(undefined, data?.errors?.[0]?.message);
          }
          addToast({
            id: company.id,
            type: TOAST_TYPES.success,
            message: t("company.tabs.grants.finData.budgetModifiedSuccess"),
          });
          setIsEditing(false);
          setBudgetModifications({});
          loadFinData();
        },
        (err) => errorHandler(err)
      );
  }, [
    addToast,
    budgetModifications,
    company.id,
    errorHandler,
    isEditing,
    loadFinData,
    t,
  ]);

  const renderAllModificationHeader = useCallback(() => {
    if (!budgetModificationsHistory.length) {
      return t(
        "company.tabs.grants.finData.list.totalBudgetModification"
      ).toUpperCase();
    }
    const lastModifiedDate =
      budgetModificationsHistory[budgetModificationsHistory.length - 1].date;
    return (
      <a href="#" onClick={() => setShowModificationHistoryModal(true)}>
        <p>
          {t(
            "company.tabs.grants.finData.list.totalBudgetModification"
          ).toUpperCase()}
        </p>
        <p>{lastModifiedDate}</p>
      </a>
    );
  }, [budgetModificationsHistory, t]);

  return {
    t,
    renderAllModificationHeader,
    isEditing,
    budgetModifications,
    setBudgetModifications,
    isModificationsBalance,
    showModificationHistoryModal,
    setShowModificationHistoryModal,
    budgetModificationsHistory,
    pathname,
    modifyBudget,
    company,
    currentBudget,
    grantId,
  };
};
