import "./app.scss";

import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/layout/layout.component";
import { UserContext } from "./context/user.context";
import { PERMISSIONS, ROUTE_PATHS } from "./consts";
import Companies from "./pages/companies/companies.component";
import Users from "./pages/users/users.component";
import UpdatePassword from "./components/login/update-password/update-password.component";
import ForgotPassword from "./components/login/forgot-password/forgot-password.component";
import { ToastProvider } from "./context/toast.context";
import CompanyUserComponent from "./pages/company-user/company-user.component";
import { CompanyProvider } from "./context/company-context";
import Login from "./components/login/login.component";

function App(): JSX.Element {
  const { user, loaded } = useContext(UserContext);

  return (
    loaded && (
      <BrowserRouter>
        {/* Auth */}
        {user && (
          <Layout>
            <ToastProvider>
              {user.authorities.includes(PERMISSIONS.admin) ? (
                <Routes>
                  <Route path="/companies/*" element={<Companies />} />
                  <Route path="/users/*" element={<Users />} />
                  {/* Default for auth users */}
                  <Route
                    path="*"
                    element={<Navigate to="companies" replace />}
                  />
                </Routes>
              ) : (
                <CompanyProvider>
                  <CompanyUserComponent />
                </CompanyProvider>
              )}
            </ToastProvider>
          </Layout>
        )}
        {/* Non-auth */}
        {!user && (
          <Routes>
            <Route path={`/${ROUTE_PATHS.login}`} element={<Login />} />
            <Route
              path={`/${ROUTE_PATHS.updatePassword}`}
              element={<UpdatePassword />}
            />
            <Route
              path={`/${ROUTE_PATHS.forgotPassword}`}
              element={<ForgotPassword />}
            />
            {/* Default for non-auth users */}
            <Route
              path="*"
              element={<Navigate to={`/${ROUTE_PATHS.login}`} replace />}
            />
          </Routes>
        )}
      </BrowserRouter>
    )
  );
}

export default App;
