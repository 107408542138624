import React from "react";
import "./error-modal.component.scss";
import { useTranslation } from "react-i18next";
import Modal from "../../modal/modal.component";
import { UploadingError } from "../../../../interfaces/interfaces";

const ERROR_TYPES = {
  notFound: "NOT_FOUND",
  systemError: "SYSTEM_ERROR",
};

interface ErrorModalProps {
  show: boolean;
  errors: Array<UploadingError>;
  onClose: () => void;
}

export default function ErrorModalComponent({
  show,
  errors,
  onClose,
}: ErrorModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="error-modal">
      <Modal
        Footer={
          <div className="footer">
            <div className="title">{t("errorModal.footerTitle")}</div>
            <button onClick={onClose} className="btn btn-secondary">
              {t("general.cancel")}
            </button>
          </div>
        }
        show={show}
        onClose={onClose}
        title={t("errorModal.title")}
      >
        <div className="content">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>{t("errorModal.message")}</th>
                <th>{t("errorModal.column")}</th>
                <th>{t("errorModal.row")}</th>
              </tr>
            </thead>
            <tbody>
              {errors
                .filter(
                  ({ errorCode }) => errorCode !== ERROR_TYPES.systemError
                )
                .map(
                  (error: UploadingError): JSX.Element => (
                    <tr>
                      <td>{error.message}</td>
                      <td>{error.column}</td>
                      <td>{error.row}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
}
