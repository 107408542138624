export const PHONE_NUMBER_VALIDATOR =
  /^[(][0-9]{3}[)][-\s\.][0-9]{3}[-\s\.][0-9]{4}$/;

export const EMAIL_VALIDATOR = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const INPUT_TYPES: {
  text: "text";
  select: "select";
  date: "date";
  radio: "radio";
  phone: "phone";
} = {
  text: "text",
  select: "select",
  date: "date",
  radio: "radio",
  phone: "phone",
};
