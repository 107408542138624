import { Program } from "../../interfaces/interfaces";
import { TFunction } from "i18next";

export const getColumns = (
  t: TFunction
): {
  header: string;
  property: keyof Program;
  sort?: boolean;
}[] => [
  {
    header: t("company.tabs.programs.list.header.id"),
    property: "code",
    sort: true,
  },
  {
    header: t("company.tabs.programs.list.header.name"),
    property: "name",
    sort: true,
  },
  {
    header: t("company.tabs.programs.list.header.category"),
    property: "category",
    sort: true,
  },
];
