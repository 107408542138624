import React from "react";
import "./back-to.component.scss";
import { useNavigate } from "react-router";

interface BackToProps {
  link: string;
  title: string;
}

export default function BackTo({ link, title }: BackToProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(link)} className="back-to gap-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        className="bi bi-arrow-left-short"
        viewBox="0 0 16 16"
      >
        <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
      </svg>
      <a>{title}</a>
    </div>
  );
}
