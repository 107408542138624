import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BILLING_ALLOCATION_METHODS, TOAST_TYPES } from "../../consts";
import { GlTransaction, Program } from "../../interfaces/interfaces";
import { formatNumber } from "../../utils";
import glAccountsApi from "../../api/gl-account-api";
import programsApi from "../../api/programs-api";
import { CompanyContext } from "../../context/company-context";
import { ToastContext } from "../../context/toast.context";
import { useLoadData } from "../../hooks/useLoadData";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ActionHeaderComponent from "./action-header/action-header.component";
import Table from "../../components/shared/table/table.component";
import EmptyListComponent from "../../components/shared/empty-list/empty-list.component";
import Modal from "../../components/shared/modal/modal.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import "./gl-account.component.scss";

export default function GlAccountListComponent(): JSX.Element {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { addToast } = useContext(ToastContext);
  const { company, chosenMonth } = useContext(CompanyContext);
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const [glAccounts, areGLsLoading, refetchAccounts] = useLoadData<
    GlTransaction[]
  >({
    fetcher: useCallback(
      () => glAccountsApi.getAccounts(company.id, activePeriod),
      [activePeriod, company.id]
    ),
    transformer: useCallback(
      (data: GlTransaction[]) =>
        data.filter(({ status }) => status !== "BUDGED_MODIFIED"),
      []
    ),
  });
  const [
    allocationPrograms,
    areAllocationProgramsLoading,
    loadAllocationPrograms,
  ] = useLoadData<Program[]>({
    fetcher: useCallback(
      () => programsApi.getGLAllocationPrograms(company.id, activePeriod),
      [activePeriod, company.id]
    ),
  });
  const [purgePrograms, arePurgeProgramsLoading, loadPurgePrograms] =
    useLoadData<Program[]>({
      fetcher: useCallback(
        () => programsApi.getGLPurgePrograms(company.id, activePeriod),
        [activePeriod, company.id]
      ),
    });
  const [allGlCount, , refetchAllGlCounts] = useLoadData<number>({
    fetcher: useCallback(
      () => glAccountsApi.getAccountsCount(company.id),
      [company]
    ),
  });
  const [showUploadedResultModal, setShowUploadedResultModal] =
    useState<boolean>(false);
  const [glCount, setGlCount] = useState<string>("");
  const [glAmount, setGlAmount] = useState<string>("");
  const [selectedAllocationProgram, setSelectedAllocationProgram] =
    useState<string>("all");
  const [selectedPurgeProgram, setSelectedPurgeProgram] =
    useState<string>("all");

  const runAllocation = useCallback(() => {
    const allocationProgramId =
      selectedAllocationProgram === "all"
        ? selectedAllocationProgram
        : +selectedAllocationProgram;
    const data = {
      programId: allocationProgramId,
      companyId: company.id,
      type: BILLING_ALLOCATION_METHODS.SAM,
      activePeriod,
    };
    return glAccountsApi.allocateGLTransactions(company.id, data).then(
      (res) => {
        if (res.status == "ERROR") {
          const messages = res.errors?.map(({ message }) => message).join(";");
          errorHandler(undefined, messages);
        } else {
          addToast({
            type: TOAST_TYPES.success,
            message: t("company.tabs.gl.allocateSuccess"),
          });
        }
        loadAllocationPrograms();
        loadPurgePrograms();
        refetchAllGlCounts();
        refetchAccounts();
      },
      (err) => errorHandler(err)
    );
  }, [
    selectedAllocationProgram,
    company.id,
    activePeriod,
    refetchAllGlCounts,
    refetchAccounts,
    loadAllocationPrograms,
    loadPurgePrograms,
    errorHandler,
    addToast,
    t,
  ]);

  const purgeProgramCode = useMemo(
    () =>
      purgePrograms.find(
        (program) => program.id.toString() === selectedPurgeProgram
      )?.code,
    [purgePrograms, selectedPurgeProgram]
  );

  const runPurge = useCallback(() => {
    const programId =
      selectedPurgeProgram === "all" ? "" : selectedPurgeProgram;
    return glAccountsApi
      .purgeGlTransactions(company.id, programId, activePeriod)
      .then(
        (res) => {
          if (res.errors?.length) {
            errorHandler(undefined, res.errors?.[0]?.message);
          } else {
            const toastMessage =
              selectedPurgeProgram === "all"
                ? t("company.tabs.gl.purgeAllSuccess")
                : t("company.tabs.gl.purgeProgramSuccess", {
                    purgeProgram: purgeProgramCode,
                  });
            addToast({
              type: TOAST_TYPES.success,
              message: toastMessage,
            });
            loadAllocationPrograms();
            loadPurgePrograms();
            refetchAccounts();
            refetchAllGlCounts();
          }
        },
        (err) => errorHandler(err)
      );
  }, [
    selectedPurgeProgram,
    purgeProgramCode,
    company.id,
    activePeriod,
    errorHandler,
    t,
    addToast,
    refetchAllGlCounts,
    refetchAccounts,
    loadAllocationPrograms,
    loadPurgePrograms,
  ]);

  const uploadGlAccounts = useCallback(
    async (files: FileList, selection: string) => {
      const formData = new FormData();
      formData.append("file", files[0]);
      if (selection) {
        formData.append("uploadType", selection);
      }
      if (activePeriod) {
        formData.append("activePeriod", activePeriod);
      }
      const uploadData = await glAccountsApi.uploadAccounts(
        formData,
        company?.id
      );
      if (uploadData?.errors?.length) {
        return Promise.reject(uploadData);
      }
      refetchAllGlCounts();
      loadAllocationPrograms();
      loadPurgePrograms();
      refetchAccounts();
      const { totalAmount, numberOfRowsImported } = uploadData;
      setGlAmount(formatNumber(+totalAmount));
      setGlCount(numberOfRowsImported);
      setShowUploadedResultModal(true);
    },
    [
      activePeriod,
      company?.id,
      loadAllocationPrograms,
      loadPurgePrograms,
      refetchAccounts,
      refetchAllGlCounts,
    ]
  );

  const isLoading = useMemo(
    () =>
      areGLsLoading || arePurgeProgramsLoading || areAllocationProgramsLoading,
    [areGLsLoading, arePurgeProgramsLoading, areAllocationProgramsLoading]
  );

  const COLUMNS = useMemo(
    () => [
      {
        header: t("company.tabs.gl.list.fiscalYear"),
        property: "fiscalYear",
        sort: true,
        sortType: "number",
      },
      {
        header: t("company.tabs.gl.list.accountingPeriod"),
        property: "accountingPeriod",
      },
      {
        header: t("company.tabs.gl.list.id"),
        property: "edgeId",
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.gl.list.transanctionId"),
        property: "transanctionId",
      },
      {
        header: t("company.tabs.gl.list.program.code"),
        property: "program.code",
        render: ({ program }: GlTransaction) => program?.code,
        sort: true,
      },
      {
        header: t("company.tabs.gl.list.grant.code"),
        property: "grant.code",
        render: ({ grant }: GlTransaction) => grant?.code,
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.gl.list.tranType"),
        property: "transanctionType",
      },
      {
        header: t("company.tabs.gl.list.transactionDate"),
        property: "transactionDate",
      },
      {
        header: t("company.tabs.gl.list.account.code"),
        property: "account.code",
        render: ({ account }: GlTransaction) => account?.code,
      },
      {
        header: t("company.tabs.gl.list.account.name"),
        property: "account.name",
        render: ({ account }: GlTransaction) => account?.name,
      },
      {
        header: t("company.tabs.gl.list.description"),
        property: "description",
      },
      {
        header: t("company.tabs.gl.list.entryType"),
        property: "entryType",
      },
      {
        header: t("company.tabs.gl.list.amount"),
        property: "amount",
        format: (value: string) => formatNumber(+value),
      },
      {
        header: t("company.tabs.gl.list.allocationPercentage"),
        property: "allocationPercentage",
        format: (value: string): string => (value ? `${value}%` : ""),
      },
      {
        header: t("company.tabs.gl.list.vendor.name"),
        property: "vendor.name",
        render: ({ vendor }: GlTransaction) => vendor?.name,
      },
      {
        header: t("company.tabs.gl.list.fileName"),
        property: "fileName",
      },
    ],
    [t]
  );

  const importActions = useMemo(
    () => [
      {
        title: t("company.tabs.gl.getTemplate"),
        url: "templates?templateName=GL_TRANSACTION",
      },
    ],
    [t]
  );

  const uploadActions = useMemo(
    () => [
      {
        title: t("company.tabs.gl.uploadGl"),
        action: uploadGlAccounts,
        ...(glAccounts.length && { confirmation: true }),
      },
    ],
    [t, glAccounts, uploadGlAccounts]
  );

  const render = useCallback(
    () =>
      allGlCount > 0 ? (
        <>
          <Modal
            className="upload-gl-modal"
            onClose={() => {
              setShowUploadedResultModal(false);
              setGlCount("");
              setGlAmount("");
            }}
            show={showUploadedResultModal}
            title={t("general.uploadResults")}
            Footer={[
              <button
                onClick={() => {
                  setShowUploadedResultModal(false);
                  setGlCount("");
                  setGlAmount("");
                }}
                className="btn btn-primary"
              >
                {t("general.ok")}
              </button>,
            ]}
          >
            <div>
              {t(
                +glCount === 1
                  ? "company.tabs.gl.uploadModalInfo"
                  : "company.tabs.gl.uploadModalInfo_plural",
                { glCount, glAmount }
              )}
            </div>
          </Modal>
          <ActionHeaderComponent
            key={glAccounts.length}
            purgePrograms={purgePrograms}
            allocationPrograms={allocationPrograms}
            runAllocation={runAllocation}
            runPurge={runPurge}
            loadGlAccounts={refetchAccounts}
            importActions={importActions}
            uploadActions={uploadActions}
            allocationProgram={selectedAllocationProgram}
            setAllocationProgram={setSelectedAllocationProgram}
            purgeProgram={selectedPurgeProgram}
            setPurgeProgram={setSelectedPurgeProgram}
          />
          {!glAccounts.length ? (
            <div className="empty-table flex-fill justify-content-center align-items-center">
              {t("general.filteredDataMessage")}
            </div>
          ) : (
            <Table
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={COLUMNS}
              data={glAccounts}
              search={true}
            />
          )}
        </>
      ) : (
        <EmptyListComponent
          {...{
            importActions,
            uploadActions,
            description: t("company.tabs.gl.addList"),
          }}
        />
      ),
    [
      allGlCount,
      showUploadedResultModal,
      t,
      glCount,
      glAmount,
      glAccounts,
      purgePrograms,
      allocationPrograms,
      refetchAccounts,
      runAllocation,
      runPurge,
      selectedAllocationProgram,
      setSelectedAllocationProgram,
      selectedPurgeProgram,
      setSelectedPurgeProgram,
      COLUMNS,
      importActions,
      uploadActions,
    ]
  );

  return (
    <div className="gl-accounts">
      {isLoading ? <Spinner size="medium" /> : render()}
    </div>
  );
}
