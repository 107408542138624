import React, { useContext, useCallback, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { USER_ROLE_LABELS, USER_ROLES } from "../../../consts";
import { JobFunctionByProgram, Program } from "../../../interfaces/interfaces";
import jobFunctionsApi from "../../../api/job-functions-api";
import { CompanyContext } from "../../../context/company-context";
import { UserContext } from "../../../context/user.context";
import { useLoadData } from "../../../hooks/useLoadData";
import FormSelectComponent from "../form-fields/form-select.component";
import FormInputComponent from "../form-fields/form-input.component";

interface PayrollFormComponentProps {
  onClose: () => void;
  onSubmit: (data: FieldValues) => void;
  payroll?: FieldValues;
  programs: Program[];
}

export default function PayrollFormComponent({
  onClose,
  onSubmit,
  payroll,
  programs,
}: PayrollFormComponentProps): JSX.Element {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const { user } = useContext(UserContext);
  const isUserAdmin = [
    USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    USER_ROLE_LABELS[USER_ROLES.ROLE_COMPANY_ADMIN],
  ].includes(user.role);
  const isEditable = isUserAdmin && payroll?.allocated === false;
  const getProgramIdByCode = (programCode: string, allPrograms: Program[]) => {
    const matchedProgram: Program | undefined = allPrograms.find(
      (p) => p.code === programCode
    );
    return matchedProgram?.id;
  };
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    resetField,
  } = useForm({
    ...(payroll && { defaultValues: payroll }),
  });
  const programAllocationId = watch("programAllocationId");
  const [jobFunctions, areJobFunctionsLoading] = useLoadData<
    JobFunctionByProgram[]
  >({
    fetcher: useCallback(() => {
      const programId = getProgramIdByCode(programAllocationId, programs);
      if (programId) {
        return jobFunctionsApi.getJobFunctionsByProgram(company.id, programId);
      }
      return new Promise(() => []);
    }, [company, programs, programAllocationId]),
  });

  const getJFIDByAcronym = (jfAcronym: string, jfs: JobFunctionByProgram[]) => {
    const matchedJF: JobFunctionByProgram | undefined = jfs.find(
      (jf) => jf.acronym === jfAcronym
    );
    return matchedJF?.id;
  };

  useEffect(() => {
    if (payroll?.jFACR && !areJobFunctionsLoading) {
      resetField("jFACR", {
        defaultValue: payroll.jFACR,
      });
    }
  }, [resetField, areJobFunctionsLoading, payroll]);

  const showWarning = () =>
    dirtyFields?.payrollProgramAllocation ||
    dirtyFields?.prBenefitProgramAllocation;

  const submitModifiedPayload = (values: FieldValues) => {
    const jfID = getJFIDByAcronym(values.jFACR, jobFunctions);
    const programId = getProgramIdByCode(values.programAllocationId, programs);
    values.jFACR = jfID;
    values.programAllocationId = programId;
    onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit(submitModifiedPayload)}>
      <div className="job-function-form gap-4 row">
        <div className="col">
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.edgeid")}
              inputProps={register("edgeId")}
              property={"edgeId"}
              error={errors?.edgeId?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.id")}
              inputProps={register("id")}
              property={"id"}
              error={errors?.id?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.employee")}
              inputProps={register("employeeName")}
              property={"employeeName"}
              error={errors?.employeeName?.message}
            />
          </div>
          <div className="mb-3">
            <FormSelectComponent
              readonly={!isEditable}
              label={t("company.tabs.payrollRegister.form.programAllocationId")}
              inputProps={register("programAllocationId")}
              property={"programAllocationId"}
              options={programs.map((program) => ({
                value: program.code,
                label: program.code,
              }))}
              error={errors?.programAllocationId?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t(
                "company.tabs.payrollRegister.form.programAllocationName"
              )}
              inputProps={register("programAllocationName")}
              property={"programAllocationName"}
              error={errors?.programAllocationName?.message}
            />
          </div>
          <div className="mb-3">
            <FormSelectComponent
              readonly={!isEditable}
              label={t("company.tabs.payrollRegister.form.jFACR")}
              inputProps={register("jFACR")}
              property={"jFACR"}
              options={jobFunctions.map((item) => ({
                value: item.acronym,
                label: item.acronym,
              }))}
              error={errors?.jFACR?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.grantBudgetId")}
              inputProps={register("grantCode")}
              property={"grantCode"}
              error={errors?.grantCode?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t(
                "company.tabs.payrollRegister.form.programAllocationPercentage"
              )}
              inputProps={register("allocationPercentage")}
              property={"allocationPercentage"}
              error={errors?.allocationPercentage?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.payDate")}
              inputProps={register("payDate")}
              property={"payDate"}
              error={errors?.payDate?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={true}
              label={t("company.tabs.payrollRegister.form.checkId")}
              inputProps={register("checkId")}
              property={"checkId"}
              error={errors?.checkId?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={!isEditable}
              label={t(
                "company.tabs.payrollRegister.form.payrollProgramAllocation"
              )}
              prefix="$"
              inputProps={register("payrollProgramAllocation")}
              property={"payrollProgramAllocation"}
              error={errors?.payrollProgramAllocation?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              readonly={!isEditable}
              label={t(
                "company.tabs.payrollRegister.form.prBenefitProgramAllocation"
              )}
              prefix="$"
              inputProps={register("prBenefitProgramAllocation")}
              property={"prBenefitProgramAllocation"}
              error={errors?.prBenefitProgramAllocation?.message}
            />
          </div>
          {showWarning() && (
            <div className="text-center fw-bold text-danger">
              {t(`company.tabs.payrollRegister.form.mismatchWarning`)}
            </div>
          )}
          {isEditable ? (
            <div className="gap-2 modal-buttons">
              <button onClick={onClose} className="btn w-50 btn-secondary">
                {t("general.cancel")}
              </button>
              <button type="submit" className="btn w-50 btn-primary">
                {t("general.save")}
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-center pt-2">
              <button onClick={onClose} className="btn w-75 btn-secondary">
                {t("general.cancel")}
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
