import React from "react";
import "./users.component.scss";
import { Route, Routes } from "react-router-dom";
import UserComponent from "./user/user.component";
import UserList from "./list/user-list.component";

export default function Users(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<UserList />} />
      <Route path="user/:id" element={<UserComponent />} />
      <Route path="user/*" element={<UserComponent />} />
    </Routes>
  );
}
