import React from "react";
import "./mcoa-form.component.scss";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormSelectComponent from "../form-fields/form-select.component";
import { ACCOUNT_CLASSES, ACCOUNT_GROUPING_TYPE } from "../../../consts/mcoa";
import FormInputComponent from "../form-fields/form-input.component";
interface McoaFormComponentProps {
  onClose: () => void;
  onSubmit: (data: FieldValues) => void;
  entity?: FieldValues;
}

export default function McoaFormComponent({
  onClose,
  onSubmit,
  entity,
}: McoaFormComponentProps): JSX.Element {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    ...(entity && { defaultValues: entity }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mcoa-form gap-4 row">
        <div className="col">
          <div className="mb-3">
            <FormInputComponent
              label={t(`company.tabs.mcoa.list.account`)}
              inputProps={register("code", {
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property="code"
              error={errors?.code?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              label={t(`company.tabs.mcoa.list.description`)}
              inputProps={register("name", {
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property={"name"}
              error={errors?.name?.message}
            />
          </div>
          <div className="mb-3">
            <FormSelectComponent
              label={t(`company.tabs.mcoa.list.class`)}
              inputProps={register("accountClass")}
              property="accountClass"
              options={Object.values(ACCOUNT_CLASSES).map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </div>
          <div className="mb-3">
            <FormSelectComponent
              label={t(`company.tabs.mcoa.list.type`)}
              inputProps={register("accountGrouping.type")}
              property="accountGrouping.type"
              options={Object.values(ACCOUNT_GROUPING_TYPE).map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </div>
          <div className="gap-2 modal-buttons">
            <button onClick={onClose} className="btn w-50 btn-secondary">
              {t("general.cancel")}
            </button>
            <button type="submit" className="btn w-50 btn-primary">
              {t("general.save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
