import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../../consts";
import { Program } from "../../../interfaces/interfaces";
import fileApi from "../../../api/file-api";
import { CompanyContext } from "../../../context/company-context";
import { Action } from "../../../components/shared/empty-list/empty-list.component";
import ButtonsComponent from "../../../components/shared/download-upload-buttons/buttons.component";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import WarningButtonComponent from "../../../components/shared/warning-button/warning-button.component";
import "./action-header.component.scss";
import MonthSwitcher from "../../../components/shared/month-switcher/month-switcher-component";

interface ActionHeaderProps {
  allocationPrograms: Program[];
  allocationMade: boolean;
  purgePrograms: Program[];
  runAllocation: () => Promise<void>;
  runPurge: () => Promise<void>;
  importActions: Action[];
  uploadActions: Action[];
  allocationProgram: string;
  setAllocationProgram: (code: string) => void;
  purgeProgram: string;
  setPurgeProgram: (code: string) => void;
}

export default function ActionHeaderComponent({
  allocationPrograms,
  purgePrograms,
  runAllocation,
  runPurge,
  importActions,
  uploadActions,
  allocationProgram,
  setAllocationProgram,
  purgeProgram,
  setPurgeProgram,
}: ActionHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { company, chosenMonth } = useContext(CompanyContext);
  const activePeriod = useMemo(
    () => chosenMonth.split("/")?.[0],
    [chosenMonth]
  );
  const programCode = useMemo(
    () =>
      allocationPrograms.find(
        (program) => program.id.toString() === allocationProgram
      )?.code,
    [allocationProgram, allocationPrograms]
  );
  const allocationWarningMessage = useMemo(
    () =>
      allocationProgram === "all"
        ? t("shared.allocation.warningAll")
        : t("shared.allocation.warningCode", { code: programCode }),
    [t, allocationProgram, programCode]
  );

  const purgeWarningMessage = useMemo(
    () =>
      purgeProgram === "all"
        ? t("shared.purge.warningAll")
        : t("shared.purge.warningCode", {
            code: purgePrograms.find(
              (program) => program.id.toString() === purgeProgram
            )?.code,
          }),
    [purgeProgram, t, purgePrograms]
  );

  return (
    <div className="payroll-register__header">
      <div className="d-flex justify-content-end align-items-end gap-5">
        <MonthSwitcher />
        <div className="d-flex flex-column gap-1">
          {ButtonsComponent({ importActions, uploadActions })}
        </div>
        <div>
          <label htmlFor="allocate">
            {t("company.tabs.payrollRegister.allocateLabel")}
          </label>
          <div className="d-flex select-wrapper gap-2">
            <select
              onChange={(e) => setAllocationProgram(e.target.value)}
              className="form-control"
              id="allocate"
              defaultValue={allocationProgram}
            >
              <option value="all">All</option>
              {allocationPrograms.map(({ id, code, name }) => (
                <option key={code} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              title={t("shared.allocation.label")}
              warning={allocationWarningMessage}
              callback={runAllocation}
            />
          </div>
        </div>
        <div>
          <label htmlFor="purge">
            {t("company.tabs.payrollRegister.purgeLabel")}
          </label>
          <div className="d-flex select-wrapper gap-2">
            <select
              onChange={(e) => setPurgeProgram(e.target.value)}
              className="form-control"
              id="purge"
              defaultValue={purgeProgram}
            >
              <option value="all">All</option>
              {purgePrograms.map(({ id, code, name }) => (
                <option key={code} value={id}>
                  {code} - {name}
                </option>
              ))}
            </select>
            <WarningButtonComponent
              warning={purgeWarningMessage}
              title={t("company.tabs.payrollRegister.purge")}
              callback={runPurge}
            />
          </div>
        </div>
        <DownloadButtonComponent
          fileName="payroll-register.xlsx"
          label={t("company.tabs.payrollRegister.export")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${
                company.id
              }/payroll-register/journal-entries${
                activePeriod ? `?activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
        <DownloadButtonComponent
          fileName="payroll-register.xlsx"
          label={t("general.export")}
          downloadHandler={() =>
            fileApi.downloadFile(
              `${ROUTE_PATHS.company}/${company.id}/payroll-register-export${
                activePeriod ? `?activePeriod=${activePeriod}` : ""
              }`
            )
          }
        />
      </div>
    </div>
  );
}
