import React, { PropsWithChildren, useMemo, useState } from "react";
import "./table.component.scss";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { formatNumber } from "../../../utils";
import TableHeader from "./header/header.component";
import SearchRow from "./search-row/search-row.component";
import Row from "./row/row.component";
import TotalRow from "./total-row/total-row.component";
import { compare } from "./helpers";
import ExpandableRow from "./expandable-row/expandable-row.component";

export type SortDirection = "down" | "up";

export interface ClassNames<T> {
  row?: (data: T) => string;
  header?: string;
}

export interface TableProps<T> {
  columns: Array<{
    header: string;
    renderHeader?: (property?: keyof T) => JSX.Element | string;
    property: keyof T;
    render?: (data: T) => JSX.Element | string;
    editable?: boolean;
    onChange?: (value: string, row: T) => void;
    sum?: (row: T, acc: number) => number;
    format?: (value: string | JSX.Element) => string | JSX.Element;
    sort?: boolean;
    sortType?: "string" | "number";
    footerClassName?: string;
    disabledClick?: boolean;
    freeze?: boolean;
    customComponent?: (row: T) => JSX.Element;
    textAlign?: CanvasTextAlign;
  }>;
  data: Array<T>;
  isFooter?: boolean;
  onRowClick?: (row: T) => void;
  filter?: (item: T) => boolean;
  filteredTotalTitle?: string;
  restTotalTitle?: string;
  totalTitle?: string;
  search?: boolean;
  isRowActive?: (row: T) => boolean;
  expandableRows?: boolean;
  expandedContent?: ({ data }: { data: T }) => JSX.Element;
  classNames?: ClassNames<T>;
  filteredHeader?: string;
  restHeader?: string;
  presortBy?: {
    property: keyof T;
    sortDirection: "up" | "down";
    sortType: "number" | "string";
  };
  filterTrigger?: () => void;
}

export default function Table<T>({
  columns,
  data,
  isFooter,
  onRowClick,
  filter,
  filteredTotalTitle,
  restTotalTitle,
  totalTitle,
  search = false,
  isRowActive,
  expandableRows,
  expandedContent,
  classNames,
  filteredHeader,
  restHeader,
  presortBy,
  filterTrigger,
}: PropsWithChildren<TableProps<T>>): JSX.Element {
  const { t } = useTranslation();

  const [sortValue, setSortValue] = useState<string | number | symbol>(
    presortBy?.property || columns[0].property
  );
  const [filters, setFilters] = useState({} as Record<keyof T, string>);
  const [sortType, setSortType] = useState<"string" | "number">(
    presortBy?.sortType || "number"
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    presortBy?.sortDirection || "up"
  );

  const filteredByPropertyData = useMemo(() => {
    if (!filters) {
      return data;
    }
    let res = data;
    columns.forEach(({ property }) => {
      if (filters[property]) {
        res = res.filter((row) =>
          String(get(row, property))
            ?.toLowerCase()
            ?.includes(String(filters[property])?.toLowerCase())
        );
      }
    });
    return res;
  }, [filters, data, columns]);

  const { filteredData, restData } = useMemo(() => {
    if (!filter) {
      return {
        filteredData: [],
        restData: filteredByPropertyData,
      };
    }
    const filtered: T[] = [];
    const rest: T[] = [];
    filteredByPropertyData.forEach((item) => {
      if (filter(item)) {
        filtered.push(item);
      } else rest.push(item);
    });
    return {
      filteredData: filtered,
      restData: rest,
    };
  }, [filter, filteredByPropertyData]);

  const sortedFilteredData = useMemo(
    () =>
      [...filteredData].sort((a, b) =>
        compare(a, b, sortValue, sortDirection, sortType)
      ),
    [filteredData, sortDirection, sortType, sortValue]
  );

  const sortedRestData = useMemo(
    () =>
      [...restData].sort((a, b) =>
        compare(a, b, sortValue, sortDirection, sortType)
      ),
    [restData, sortDirection, sortType, sortValue]
  );

  const totalCounts = useMemo(() => {
    const filtered = {} as Record<number, number>;
    const rest = {} as Record<number, number>;
    const counts = { filtered, rest };
    if (isFooter) {
      sortedFilteredData.forEach((row) => {
        columns.map(({ sum }, index) => {
          if (sum) {
            counts.filtered[index] = sum(row, counts.filtered[index] || 0);
          }
        });
      });
      sortedRestData.forEach((row) => {
        columns.map(({ sum }, index) => {
          if (sum) {
            counts.rest[index] = sum(row, counts.rest[index] || 0);
          }
        });
      });
    }
    return counts;
  }, [isFooter, sortedFilteredData, sortedRestData, columns]);
  const [tableHeaderHeight, setTableHeaderHeight] = useState<number>(20);
  const [columnsPositions, setColumnsPositions] = useState(
    {} as Record<number, string | number>
  );

  const freezeColumnsNumber = useMemo(
    () => columns.filter(({ freeze }) => freeze).length,
    [columns]
  );
  console.log(totalCounts);
  return (
    <div className="table-wrapper table-responsive">
      <table className="table table-hover">
        <TableHeader
          freezeColumnsNumber={freezeColumnsNumber}
          columns={columns}
          columnsPositions={columnsPositions}
          sortDirection={sortDirection}
          sortValue={sortValue}
          setColumnsPositions={setColumnsPositions}
          setTableHeaderHeight={setTableHeaderHeight}
          setSortType={setSortType}
          setSortDirection={setSortDirection}
          setSortValue={setSortValue}
          expandableRows={expandableRows}
          className={classNames?.header}
        />
        <tbody>
          {search && (
            <SearchRow
              columns={columns}
              tableHeaderHeight={tableHeaderHeight}
              columnsPositions={columnsPositions}
              filterTrigger={filterTrigger}
              setFilters={setFilters}
            />
          )}
          {filteredHeader && !!restData.length && !!filteredData.length && (
            <tr className="header-row">
              <td
                className={freezeColumnsNumber ? "show-shadow" : ""}
                colSpan={freezeColumnsNumber}
              >
                {filteredHeader}
              </td>
            </tr>
          )}
          {sortedFilteredData.map(
            (row: T, rowIndex: number): JSX.Element =>
              expandableRows ? (
                <ExpandableRow
                  key={rowIndex}
                  columns={columns}
                  columnsPositions={columnsPositions}
                  row={row}
                  rowIndex={rowIndex}
                  isRowActive={isRowActive}
                  expandedContent={expandedContent}
                  className={classNames?.row}
                  freezeColumnsNumber={freezeColumnsNumber}
                />
              ) : (
                <Row
                  key={rowIndex}
                  columns={columns}
                  columnsPositions={columnsPositions}
                  row={row}
                  isRowActive={isRowActive}
                  onRowClick={onRowClick}
                  rowIndex={rowIndex}
                  className={classNames?.row}
                  freezeColumnsNumber={freezeColumnsNumber}
                />
              )
          )}
          {!!restData.length && !!filteredData.length && (
            <TotalRow
              columns={columns}
              columnsPositions={columnsPositions}
              totalCounts={totalCounts.filtered}
              title={filteredTotalTitle}
              freezeColumnsNumber={freezeColumnsNumber}
            />
          )}
          {restHeader && !!restData.length && !!filteredData.length && (
            <tr className="header-row">
              <td
                className={freezeColumnsNumber ? "show-shadow" : ""}
                colSpan={freezeColumnsNumber}
              >
                {restHeader}
              </td>
            </tr>
          )}
          {!!restData.length &&
            sortedRestData.map(
              (row: T, rowIndex: number): JSX.Element =>
                expandableRows ? (
                  <ExpandableRow
                    key={rowIndex}
                    columns={columns}
                    columnsPositions={columnsPositions}
                    row={row}
                    rowIndex={rowIndex}
                    isRowActive={isRowActive}
                    expandedContent={expandedContent}
                    className={classNames?.row}
                    freezeColumnsNumber={freezeColumnsNumber}
                  />
                ) : (
                  <Row
                    key={rowIndex}
                    columns={columns}
                    columnsPositions={columnsPositions}
                    row={row}
                    isRowActive={isRowActive}
                    onRowClick={onRowClick}
                    rowIndex={rowIndex}
                    className={classNames?.row}
                    freezeColumnsNumber={freezeColumnsNumber}
                  />
                )
            )}
          {!!restData.length && !!filteredData.length && (
            <TotalRow
              columns={columns}
              columnsPositions={columnsPositions}
              totalCounts={totalCounts.rest}
              title={restTotalTitle}
              freezeColumnsNumber={freezeColumnsNumber}
            />
          )}
          {isFooter && (
            <tr className="total-data">
              {columns.map(
                ({ freeze, footerClassName, sum, textAlign }, index) => {
                  const getValue = () => {
                    if (index === 0) {
                      return totalTitle || t("general.total");
                    }
                    return sum
                      ? formatNumber(
                          (+totalCounts.rest[index] || 0) +
                            (+totalCounts.filtered[index] || 0)
                        )
                      : "-";
                  };
                  return (
                    <td
                      style={{
                        ...(freeze && { left: columnsPositions[index] }),
                        ...(textAlign && { textAlign }),
                      }}
                      key={index}
                      className={`${footerClassName} ${
                        freeze ? "freeze" : ""
                      } ${
                        freezeColumnsNumber && freezeColumnsNumber - 1 === index
                          ? "show-shadow"
                          : ""
                      }`}
                    >
                      {getValue()}
                    </td>
                  );
                }
              )}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
