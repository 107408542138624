import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import "./comnany-form.component.scss";
import { useForm, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Company } from "../../../interfaces/interfaces";
import { MCOAInterface } from "../../../interfaces/interfaces";
import companyApi from "../../../api/company-api";
import mcoaApi from "../../../api/mcoa-api";
import { useNavigate } from "react-router";
import {
  CURRENCIES,
  LANGUAGES,
  ROUTE_PATHS,
  TYPES_TO_UI,
  USER_ROLE_LABELS,
  USER_ROLES,
} from "../../../consts";
import { ACCOUNT_GROUPING_TYPE, PHONE_NUMBER_VALIDATOR } from "../../../consts";
import CreateEntityModal from "../../shared/create-entity-modal/modal.component";
import { UserContext } from "../../../context/user.context";
import { CompanyContext } from "../../../context/company-context";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { ToastContext } from "../../../context/toast.context";
import FormPhoneComponent from "../form-fields/form-phone.component";
import FormInputComponent from "../form-fields/form-input.component";
import FormAutocompleteComponent from "../form-fields/form-autocomplete.component";
import { useLoadData } from "../../../hooks/useLoadData";

interface CompanyFormProps {
  entity?: Company | null;
}

export default function CompanyForm({ entity }: CompanyFormProps): JSX.Element {
  const { user } = useContext(UserContext);
  const { setCompany, company } = useContext(CompanyContext);
  const editable = useMemo(
    () => user.role === USER_ROLE_LABELS[USER_ROLES.ROLE_ADMIN],
    [user.role]
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mcoaList, mcoaListLoading] = useLoadData<MCOAInterface[]>({
    fetcher: useCallback(
      () =>
        company?.id ? mcoaApi.getMCOAs(company.id) : new Promise(() => []),
      [company]
    ),
    transformer: useCallback(
      (data: MCOAInterface[]) =>
        data.filter(
          (mcoa) =>
            mcoa.accountGrouping.type ===
            ACCOUNT_GROUPING_TYPE.SYSTEM_ALLOCATION
        ),
      []
    ),
  });
  const { addToast } = useContext(ToastContext);
  const errorHandler = useErrorHandler();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    ...(entity && { defaultValues: entity }),
  });
  const phoneNumber = watch("phoneNumber");
  const companyFiscalPeriodStartDate = watch("companyFiscalPeriodStartDate");
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!mcoaListLoading) {
      reset({ ...entity });
    }
  }, [reset, entity, mcoaListLoading]);

  const submit = (values: FieldValues): void => {
    const apiHandler = entity
      ? companyApi.editCompany
      : companyApi.createCompany;

    apiHandler(values).then(
      (data: Company) => {
        if (!entity) {
          setShowModal(true);
        }
        setCompany(data);
        addToast({
          id: user.id,
          type: "success",
          message: t(
            `company.tabs.companyForm.${entity ? "editSuccess" : "addSuccess"}`
          ),
        });
      },
      (err) => errorHandler(err)
    );
  };

  const endPeriodError = errors?.companyFiscalPeriodEndDate?.message;
  const startPeriodError = errors?.companyFiscalPeriodStartDate?.message;
  return (
    <>
      <CreateEntityModal
        show={showModal}
        submitTitle={t("company.tabs.companyForm.createModal.createUser")}
        cancelTitle={t("company.tabs.companyForm.createModal.cancel")}
        onCancel={() => {
          navigate(`/${ROUTE_PATHS.companies}`);
        }}
        onSubmit={() =>
          navigate(`/${ROUTE_PATHS.companies}/company/${company?.id}/users`)
        }
        bodyData={t("company.tabs.companyForm.createModal.body", {
          id: company?.id8Digits,
        })}
        title={t("company.tabs.companyForm.createModal.title")}
      />
      <form onSubmit={handleSubmit(submit)}>
        <div className="company-form row gap-4">
          <div className="col">
            <FormInputComponent
              readonly={!editable}
              label={t("company.tabs.companyForm.name")}
              error={errors?.name?.message}
              inputProps={register("name", {
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property="name"
            />
            <div className="mb-3">
              <label htmlFor="type" className="form-label">
                {t("company.tabs.companyForm.type")}
              </label>
              <select
                className="form-select"
                disabled={!editable}
                id="type"
                {...register("companyType")}
              >
                {Object.entries(TYPES_TO_UI).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <FormInputComponent
              readonly={!editable}
              label={t("company.tabs.companyForm.identifier")}
              inputProps={register("uid", {
                pattern: {
                  value: /^[0-9-]+$/,
                  message: t("general.form.validation.numbersWithHyphens"),
                },
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property="uid"
              error={errors?.uid?.message}
            />
            <div className="mb-3">
              <label htmlFor="fiscalPeriod" className="form-label">
                {t("company.tabs.companyForm.fiscalPeriod")}
              </label>
              <div className="fiscal-period gap-4">
                <div>
                  <input
                    readOnly={!editable}
                    type="date"
                    className="form-control"
                    id="fiscalPeriod"
                    {...register("companyFiscalPeriodStartDate", {
                      required: {
                        value: true,
                        message: t("general.form.validation.required"),
                      },
                    })}
                  />
                  {startPeriodError && (
                    <div className="error">{startPeriodError}</div>
                  )}
                </div>
                <div>
                  <input
                    readOnly={!editable}
                    type="date"
                    className="form-control"
                    id="fiscalPeriod"
                    {...register("companyFiscalPeriodEndDate", {
                      required: {
                        value: true,
                        message: t("general.form.validation.required"),
                      },
                      validate: (value) =>
                        (!value && !companyFiscalPeriodStartDate) ||
                        companyFiscalPeriodStartDate <= value ||
                        t("general.form.validation.dateBigger"),
                    })}
                  />
                  {endPeriodError && (
                    <div className="error">{endPeriodError}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t("company.tabs.companyForm.description")}
              </label>
              <textarea
                readOnly={!editable}
                className="form-control"
                id="description"
                {...register("description")}
              />
            </div>
          </div>
          <div className="col">
            <FormPhoneComponent
              disabled={!editable}
              error={errors?.phoneNumber?.message}
              value={phoneNumber}
              formProps={register("phoneNumber", {
                pattern: {
                  value: PHONE_NUMBER_VALIDATOR,
                  message: t("general.form.validation.phone"),
                },
              })}
            />

            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                {t("company.tabs.companyForm.address")}
              </label>
              <textarea
                className="form-control"
                id="address"
                {...register("address")}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="country" className="form-label">
                {t("company.tabs.companyForm.country")}
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                {...register("country")}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="state" className="form-label">
                {t("company.tabs.companyForm.state")}
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                {...register("state")}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                {t("company.tabs.companyForm.city")}
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                {...register("city")}
              />
            </div>
            <FormInputComponent
              label={t("company.tabs.companyForm.postalCode")}
              inputProps={register("postalCode", {
                pattern: {
                  value: /^(?=.*?[1-9])[0-9()-]+$/,
                  message: t("general.form.validation.numbers"),
                },
              })}
              error={errors?.postalCode?.message}
              property="postalCode"
            />
          </div>
          <div className="col justify-content-between">
            <div>
              <div className="mb-3">
                <label htmlFor="language" className="form-label">
                  {t("company.tabs.companyForm.language")}
                </label>
                <select
                  className="form-select"
                  disabled={!editable}
                  id="currency"
                  {...register("language")}
                >
                  <option value={LANGUAGES.english}>{LANGUAGES.english}</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="currency" className="form-label">
                  {t("company.tabs.companyForm.currency")}
                </label>
                <select
                  className="form-select"
                  disabled={!editable}
                  id="currency"
                  {...register("currencyName")}
                >
                  <option value={CURRENCIES.usd}>{CURRENCIES.usd}</option>
                </select>
              </div>
              <div className="period-controls">
                <div>{t("company.tabs.companyForm.controlsTitle")}</div>
                <div className="control gap-3">
                  <button disabled className="btn btn-primary">
                    {t("company.tabs.companyForm.closePeriod")}
                  </button>
                  <input disabled type="date" />
                </div>
                <div className="control gap-3">
                  <button disabled className="btn btn-primary">
                    {t("company.tabs.companyForm.unlockPeriod")}
                  </button>
                  <input disabled type="date" />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="auditTimestamp" className="form-label">
                {t("company.tabs.companyForm.auditTimestamp")}
              </label>
              <input
                readOnly={true}
                type="text"
                className="form-control"
                id="auditTimestamp"
                {...register("auditTrailDateStamp")}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="effectiveDate" className="form-label">
                {t("company.tabs.companyForm.effectiveDate")}
              </label>
              <input
                readOnly={!editable}
                type="date"
                className="form-control"
                id="effectiveDate"
                {...register("effectiveDate")}
              />
            </div>
            <div className="mb-3 btn-group" role="group">
              <div className="form-check form-check-inline">
                <input
                  disabled={!editable}
                  className="form-check-input"
                  type="radio"
                  id="type1"
                  {...register("license")}
                  value="Active"
                />
                <label className="form-check-label" htmlFor="typeParent1">
                  {t("company.tabs.companyForm.active")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="type2"
                  disabled={!editable}
                  {...register("license")}
                  value="Inactive"
                />
                <label className="form-check-label" htmlFor="typeSubsidiary">
                  {t("company.tabs.companyForm.inactive")}
                </label>
              </div>
            </div>
            <FormInputComponent
              label={t("company.tabs.companyForm.offsetGrantCode")}
              type="text"
              inputProps={register("offsetGrantCode", {
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property={"offsetGrantCode"}
              error={errors?.offsetGrantCode?.message}
            />
            <FormInputComponent
              label={t("company.tabs.companyForm.fringeBenefitsRatio")}
              prefix="%"
              readonly={!editable}
              inputProps={register("fringeBenefitsRatio", {
                pattern: {
                  value: /^(100|[0-9]{1,2})(\.[0-9]{1,10})?$/,
                  message: t("general.form.validation.percentage"),
                },
              })}
              property="fringeBenefitsRatio"
              error={errors?.fringeBenefitsRatio?.message}
            />
            <FormAutocompleteComponent
              label={t(`company.tabs.companyForm.payrollAllocationAccount`)}
              property="payrollAllocationAccount"
              inputProps={register("payrollAllocationAccount")}
              options={mcoaList.map((item) => ({
                code: item.code,
                name: item.name,
              }))}
            />
            <FormAutocompleteComponent
              label={t(
                `company.tabs.companyForm.fringeBenefitsAllocationAccount`
              )}
              property="fringeBenefitsAllocationAccount"
              inputProps={register("fringeBenefitsAllocationAccount")}
              options={mcoaList.map((item) => ({
                code: item.code,
                name: item.name,
              }))}
            />
          </div>
          <div className="mb-3 right">
            <button
              className="btn me-3 border btn-secondary"
              onClick={() => navigate(`/${ROUTE_PATHS.companies}`)}
            >
              {t("company.tabs.companyForm.cancel")}
            </button>
            <button className="btn btn-primary" type="submit">
              {t(`company.tabs.companyForm.${entity ? "edit" : "submit"}`)}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
