import React from "react";
import "./modal.component.scss";

interface ModalProps {
  show: boolean;
  onClose?: () => void;
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  centredContent?: boolean;
  size?: "small" | "medium" | "large";
  Footer?: React.ReactNode;
  className?: string;
}

export default function Modal({
  show,
  onClose,
  title,
  subtitle,
  children,
  size = "medium",
  Footer,
  centredContent = true,
  className,
}: ModalProps): JSX.Element | null {
  return show ? (
    <div className={`modal ${className}`} aria-hidden="false" id="modal">
      <div className={`modal-dialog ${size} modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalLabel">
              {title}
            </h5>
            {onClose && (
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 m-0 mt-3 me-2"
                onClick={onClose}
                aria-label="Close"
              />
            )}
            {subtitle && (
              <div className="modal-subtitle text-center mt-2 mb-0 py-1 w-100">
                <span>{subtitle}</span>
              </div>
            )}
          </div>
          <div className={`modal-body ${centredContent ? "center" : ""}`}>
            {children}
          </div>
          {Footer && <div className="modal-footer">{Footer}</div>}
        </div>
      </div>
    </div>
  ) : null;
}
