import { TFunction } from "i18next";
import { Grant } from "../../../interfaces/interfaces";
import { Link } from "react-router-dom";
import { SortTypes } from "../../../interfaces/types";

export const getColumns = ({
  t,
  pathname,
}: {
  t: TFunction;
  pathname: string;
}): {
  header: string;
  property: keyof Grant;
  sort?: boolean;
  sortType?: SortTypes;
  render?: (data: Grant) => JSX.Element | string;
}[] => [
  {
    header: t("company.tabs.grants.list.id"),
    property: "code",
    sort: true,
    sortType: "number",
  },
  {
    header: t("company.tabs.grants.list.name"),
    property: "name",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.classification"),
    property: "grantClassification",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.startDate"),
    property: "grantBudgetStart",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.endDate"),
    property: "grantBudgetEnd",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.currentStatus"),
    property: "isActive",
    render: (data: Grant) => t(`general.${data.isActive}`),
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.programName"),
    render: (data: Grant) => data.program?.name,
    property: "program.name",
    sort: true,
    sortType: "string",
  },
  {
    header: t("company.tabs.grants.list.programId"),
    render: (data: Grant) => data.program?.code,
    property: "program.code",
    sort: true,
    sortType: "string",
  },
  {
    header: "",
    property: "details",
    render: ({ id: grantId }: Grant): JSX.Element => (
      <Link
        className="text-decoration-none"
        to={`${pathname}/grant/${grantId}`}
      >
        {t("companies.list.details").toString()}
      </Link>
    ),
  },
];
