import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./user-menu.component.scss";
import authApi from "../../../../api/auth-api";
import { UserContext } from "../../../../context/user.context";

export default function UserMenu(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  return (
    <div className="gap-4 user-menu">
      <span className="user-role">{user.firstName}</span>
      <div className="dropdown">
        <button
          className="btn btn-secondary"
          type="button"
          id="userMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <ul
          className="dropdown-menu dropdown-menu-end"
          aria-labelledby="userMenu"
        >
          <li>
            <a onClick={authApi.logout} className="dropdown-item" href="#">
              {t("shared.navbar.userMenu.logout")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
