import React, { useCallback, useContext, useMemo, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TOAST_TYPES } from "../../consts";
import { JobFunction } from "../../interfaces/interfaces";
import jobFunctionsApi from "../../api/job-functions-api";
import { CompanyContext } from "../../context/company-context";
import { ToastContext } from "../../context/toast.context";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useLoadData } from "../../hooks/useLoadData";
import ButtonsComponent from "../../components/shared/download-upload-buttons/buttons.component";
import EmptyListComponent from "../../components/shared/empty-list/empty-list.component";
import JobFunctionFormComponent from "../../components/forms/jobFunction/job-function-form.component";
import Modal from "../../components/shared/modal/modal.component";
import Table from "../../components/shared/table/table.component";
import Spinner from "../../components/shared/spinner/spinner.component";
import "./job-functions.scss";
import { DownloadButtonComponent } from "../../components/shared/download-button/download-button.component";
import fileApi from "../../api/file-api";
import { getColumns } from "./helpers";

export default function JobFunctions(): JSX.Element {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [jobFunctionToEdit, setJobFunctionToEdit] = useState<
    JobFunction | undefined
  >(undefined);
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const { company } = useContext(CompanyContext);
  const errorHandler = useErrorHandler();
  const [jobFunctions, areJobFunctionsLoading, loadJobFunctions] = useLoadData<
    JobFunction[]
  >({
    fetcher: useCallback(
      () => jobFunctionsApi.getJobFunctions(company.id),
      [company]
    ),
  });

  const submitHandler = useCallback(
    (values: FieldValues) => {
      const fetcher = values.id
        ? jobFunctionsApi.updateJobFunction
        : jobFunctionsApi.createJobFunction;
      fetcher(company.id, values)
        .then(
          (data: JobFunction) => {
            addToast({
              id: data.id,
              type: TOAST_TYPES.success,
              message: t(
                `company.tabs.jobFunctions.${values.id ? "edit" : "add"}Success`
              ),
            });
            loadJobFunctions();
            setShowForm(false);
          },
          (err) => errorHandler(err)
        )
        .finally(() => {
          setJobFunctionToEdit(undefined);
          setShowForm(false);
        });
    },
    [addToast, company.id, errorHandler, loadJobFunctions, t]
  );

  const columns = useMemo<
    {
      header: string;
      property: keyof JobFunction;
      sort?: boolean;
    }[]
  >(() => getColumns(t), [t]);

  const uploadFile = useCallback(
    async (files: FileList): Promise<void> => {
      const formData = new FormData();
      formData.append("file", files[0]);

      const uploadData = await jobFunctionsApi.uploadJobFunctions(
        formData,
        company.id
      );
      if (uploadData?.errors?.length) {
        return Promise.reject(uploadData);
      }
      loadJobFunctions();
    },
    [company, loadJobFunctions]
  );

  const importActions = [
    {
      title: t("company.tabs.jobFunctions.list.import"),
      url: "templates?templateName=JOB_FUNCTION",
    },
  ];

  const uploadActions = [
    {
      title: t("company.tabs.jobFunctions.list.upload"),
      action: uploadFile,
    },
  ];

  return (
    <div className="job-functions">
      <Modal
        centredContent={false}
        show={showForm}
        title={t(
          `company.tabs.jobFunctions.list.${jobFunctionToEdit ? "edit" : "add"}`
        )}
      >
        <JobFunctionFormComponent
          key={jobFunctionToEdit?.id}
          entity={jobFunctionToEdit}
          onSubmit={submitHandler}
          onClose={() => {
            setShowForm(false);
            setJobFunctionToEdit(undefined);
          }}
        />
      </Modal>
      {areJobFunctionsLoading ? (
        <Spinner size="medium" />
      ) : jobFunctions.length ? (
        <div>
          <div className="list-header">
            <div className="actions gap-2">
              <button
                onClick={() => setShowForm(true)}
                className="btn btn-primary"
              >
                {t("company.tabs.jobFunctions.list.add")}
              </button>
              {ButtonsComponent({ importActions, uploadActions })}
              <DownloadButtonComponent
                label={t("general.export")}
                downloadHandler={() =>
                  fileApi.downloadFile(
                    `company/${company.id}/job-functions-export`
                  )
                }
              />
            </div>
          </div>
          <Table
            columns={columns}
            search
            data={jobFunctions}
            onRowClick={(row) => {
              setJobFunctionToEdit(row);
              setShowForm(true);
            }}
          />
        </div>
      ) : (
        <EmptyListComponent
          {...{
            importActions,
            uploadActions,
            description: t("company.tabs.jobFunctions.list.emptyTitle"),
          }}
        />
      )}
    </div>
  );
}
