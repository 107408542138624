import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import { Payroll, UploadResponse } from "../interfaces/interfaces";

async function getPayrolls(
  companyId: string | undefined,
  activePeriod: string | undefined
): Promise<{ allocated: Payroll[]; unAllocated: Payroll[] }> {
  if (!activePeriod) {
    return { allocated: [], unAllocated: [] };
  }
  return httpClient
    .get(
      `company/${companyId}/payroll-registers/grouped${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`
    )
    .then(
      (
        response: AxiosResponse
      ): { allocated: Payroll[]; unAllocated: Payroll[] } => response.data
    );
}

async function getPayrollsCount(
  companyId: string | undefined
): Promise<number> {
  return httpClient
    .get(`company/${companyId}/payroll-registers/count`)
    .then((response: AxiosResponse): number => response.data);
}

async function uploadPayrolls(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/payroll-register/file-upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

async function addPayroll(
  data: Record<string, string>,
  companyId: string | undefined
) {
  return httpClient
    .post(`company/${companyId}/payroll-registers/`, data)
    .then((response: AxiosResponse): Payroll => response.data);
}

async function allocatePayrolls(
  data: Record<string, string | number>,
  companyId: string | undefined,
  activePeriod: string | undefined
): Promise<{ errors?: Record<string, string>[]; status: string }> {
  return httpClient
    .post(
      `company/${companyId}/payroll-register/allocate${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`,
      data
    )
    .then(
      (
        response: AxiosResponse
      ): { errors?: Record<string, string>[]; status: string } => response.data
    );
}

async function editPayroll(
  companyId: string,
  payrollId: string,
  data: Record<string, number>
): Promise<Payroll | { errors?: Record<string, string>[] }> {
  return httpClient
    .patch(`company/${companyId}/payroll-registers/${payrollId}`, data)
    .then(
      (response: AxiosResponse): { errors?: Record<string, string>[] } =>
        response.data
    );
}

async function purgePayrolls(
  companyId: string,
  programId: string,
  activePeriod: string | undefined
): Promise<{ errors?: Record<string, string>[] }> {
  return httpClient
    .post(
      `company/${companyId}/payroll-register/purge?programId=${programId}${
        activePeriod ? `&activePeriod=${activePeriod}` : ""
      }`
    )
    .then(
      (response: AxiosResponse): { errors?: Record<string, string>[] } =>
        response.data
    );
}

const payrollApi = {
  getPayrolls,
  uploadPayrolls,
  addPayroll,
  allocatePayrolls,
  editPayroll,
  purgePayrolls,
  getPayrollsCount,
};

export default payrollApi;
