import React, { useState } from "react";
import "./grants.component.scss";
import { Route, Routes } from "react-router-dom";
import GrantList from "./list/list.component";
import GrantComponent from "./grant/grant.component";
import BudgetDataComponent from "./budget/budget.component";
import { Grant } from "../../interfaces/interfaces";

export default function Grants(): JSX.Element {
  const [grant, setGrant] = useState<Grant>();
  return (
    <Routes>
      <Route path="/" element={<GrantList />} />
      <Route path="/grant" element={<GrantComponent setGrant={setGrant} />} />
      <Route
        path="grant/:grantId"
        element={<GrantComponent setGrant={setGrant} />}
      />
      <Route
        path="grant/:id/budget/:id"
        element={<BudgetDataComponent grant={grant} />}
      />
    </Routes>
  );
}
