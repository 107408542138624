import { httpClient } from "../core/http-client/http-client";
import { AxiosResponse } from "axios";
import {
  JobFunction,
  JobFunctionByProgram,
  UploadResponse,
} from "../interfaces/interfaces";
import { FieldValues } from "react-hook-form";

async function getJobFunctions(
  companyId: string | undefined
): Promise<Array<JobFunction>> {
  const { data } = await httpClient.get(
    `company/${companyId}/job-functions/count`
  );
  return httpClient
    .get(`company/${companyId}/job-functions?size=${data}`)
    .then((response: AxiosResponse): Array<JobFunction> => response.data);
}
async function getJobFunctionsByProgram(
  companyId: string,
  programId: string
): Promise<Array<JobFunctionByProgram>> {
  return httpClient
    .get(`company/${companyId}/job-functions/by-program/${programId}`)
    .then(
      (response: AxiosResponse): Array<JobFunctionByProgram> => response.data
    );
}
async function uploadJobFunctions(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/job-functions/import`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

async function createJobFunction(
  companyId: string | undefined,
  data: FieldValues
): Promise<JobFunction> {
  return httpClient
    .post(`company/${companyId}/job-functions`, {
      ...data,
      company: { id: companyId },
    })
    .then((response: AxiosResponse): JobFunction => response.data);
}

async function updateJobFunction(
  companyId: string | undefined,
  data: FieldValues
): Promise<JobFunction> {
  return httpClient
    .put(`company/${companyId}/job-functions/${data.id}`, data)
    .then((response: AxiosResponse): JobFunction => response.data);
}

const jobFunctionsApi = {
  uploadJobFunctions,
  getJobFunctions,
  getJobFunctionsByProgram,
  createJobFunction,
  updateJobFunction,
};

export default jobFunctionsApi;
