import React from "react";
import Spinner from "./spinner.component";

interface SpinnerButtonProps {
  target: HTMLButtonElement | null;
}

export function SpinnerButton({
  target,
}: SpinnerButtonProps): JSX.Element | null {
  const height = target?.clientHeight;
  return height ? (
    <div style={{ top: -((height + 10) / 2) }} className="button-spinner">
      <Spinner size="tiny" variant="light" />
    </div>
  ) : null;
}
