import React, { useContext, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import documentsApi from "../../../api/documents-api";
import { CompanyContext } from "../../../context/company-context";
import { useLoadData } from "../../../hooks/useLoadData";
import ActionHeaderComponent from "./action-header/action-header.component";
import Table from "../../../components/shared/table/table.component";
import Spinner from "../../../components/shared/spinner/spinner.component";
import "./list.component.scss";
import { GlTransaction } from "../../../interfaces/interfaces";

export function DocumentList() {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedGrant, setSelectedGrant] = useState("");
  const [periodsToGrants, areP2GLoading] = useLoadData({
    fetcher: useCallback(
      () => documentsApi.getPeriodsToGrants(company.id),
      [company.id]
    ),
  });
  const [glTransactions, areGLsLoading] = useLoadData({
    fetcher: useCallback(
      () =>
        selectedPeriod
          ? documentsApi.getTransactions(
              company.id,
              selectedPeriod,
              selectedGrant
            )
          : Promise.resolve([]),
      [company.id, selectedPeriod, selectedGrant]
    ),
  });
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const [foundInvoices, setFoundInvoices] = useState<{ [key: number]: number }>(
    {}
  );
  const data = useMemo(() => {
    const withStatus: GlTransaction[] = [];
    if (!showUploadStatus) return glTransactions;
    glTransactions.forEach((gl) => {
      withStatus.push({
        ...gl,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        uploadStatus: foundInvoices[+gl.id]
          ? `Success: page ${foundInvoices[+gl.id]}`
          : "Missing invoice file",
      });
    });
    return withStatus;
  }, [showUploadStatus, foundInvoices, glTransactions]);

  const isLoading = useMemo(() => areP2GLoading, [areP2GLoading]);
  const COLUMNS = useMemo(
    () => [
      {
        header: t("company.tabs.documents.list.accountingPeriod"),
        property: "accountingPeriod",
      },
      {
        header: t("company.tabs.documents.list.grantCode"),
        property: "grantCode",
        sort: true,
        sortType: "number",
      },
      {
        header: t("company.tabs.documents.list.transactionId"),
        property: "transanctionId",
      },
      {
        header: t("company.tabs.documents.list.transactionDate"),
        property: "transanctionDate",
      },
      {
        header: t("company.tabs.documents.list.glAccount"),
        property: "accountCode",
      },
      {
        header: t("company.tabs.documents.list.jeDesc"),
        property: "description",
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.documents.list.amount"),
        property: "amount",
        sort: true,
        sortType: "number",
      },
      {
        header: t("company.tabs.documents.list.vendorName"),
        property: "vendorName",
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.documents.list.vendorId"),
        property: "vendorCode",
        sort: true,
        sortType: "string",
      },
      {
        header: t("company.tabs.documents.list.invoiceId"),
        property: "invoiceNumber",
      },
      {
        header: t("company.tabs.documents.list.filename"),
        property: "fileName",
      },
      {
        header: t("company.tabs.documents.list.uploadStatus"),
        property: "uploadStatus",
      },
    ],
    [t]
  );

  return isLoading ? (
    <Spinner size="medium" />
  ) : (
    <div className="documents-page">
      {Object.keys(periodsToGrants).length ? (
        <div>
          <ActionHeaderComponent
            periodsToGrants={periodsToGrants}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            selectedGrant={selectedGrant}
            setSelectedGrant={setSelectedGrant}
            setShowUploadStatus={setShowUploadStatus}
            setFoundInvoices={setFoundInvoices}
          />
          {selectedPeriod ? (
            areGLsLoading ? (
              <Spinner size="medium" />
            ) : (
              <Table
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={COLUMNS}
                data={data}
                search={true}
              />
            )
          ) : (
            <div className="empty-table flex-fill justify-content-center align-items-center">
              {t("general.filteredDataMessage")}
            </div>
          )}
        </div>
      ) : (
        <div className="empty-table flex-fill justify-content-center align-items-center">
          <p>{t("company.tabs.documents.emptyList")}</p>
        </div>
      )}
    </div>
  );
}
