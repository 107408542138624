import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";

const downloadFile = async (url: string): Promise<AxiosResponse> => {
  return httpClient
    .get(url, {
      responseType: "blob",
      headers: {
        "Access-Control-Expose-Headers":
          "Content-Disposition,X-Suggested-Filename",
      },
    })
    .then((response: AxiosResponse) => response);
};
const fileApi = {
  downloadFile,
};
export default fileApi;
