import { useTranslation } from "react-i18next";
import "./dashboard.component.scss";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  GrantDashboard,
  ProgramDashboard,
} from "../../../interfaces/interfaces";
import Table from "../../../components/shared/table/table.component";
import { CompanyContext } from "../../../context/company-context";
import dashboardApi from "../../../api/dashboard-api";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import Spinner from "../../../components/shared/spinner/spinner.component";
import fileApi from "../../../api/file-api";
import { ROUTE_PATHS } from "../../../consts";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import { getGrantColumns, getProgramColumns } from "./helpers";

export default function Dashboard(): JSX.Element {
  const { t } = useTranslation();
  const { company } = useContext(CompanyContext);
  const month = new Date().getMonth();
  const [currentProgram, setCurrentProgram] = useState<string | null>(null);
  const [isFilterApply, setIsFilterAlly] = useState<boolean>(false);
  const [grantDashboards, setGrantDashboards] = useState<GrantDashboard[]>([]);
  const errorHandler = useErrorHandler();
  const [programDashboards, setProgramDashboards] = useState<
    ProgramDashboard[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    dashboardApi
      .getDashboards(company?.id)
      .then(
        ({ grantDashboard, programDashboard }) => {
          setProgramDashboards(programDashboard);
          setGrantDashboards(grantDashboard);
        },
        (err) => errorHandler(err)
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [company, errorHandler, t]);

  const filteredProgramDashboards = useMemo(
    () =>
      isFilterApply
        ? programDashboards.filter(
            ({ programCode }) => programCode === currentProgram
          )
        : programDashboards,
    [currentProgram, isFilterApply, programDashboards]
  );

  const filteredGrantDashboards = useMemo(
    () =>
      isFilterApply
        ? grantDashboards.filter(
            ({ programCode }) => programCode === currentProgram
          )
        : grantDashboards,
    [currentProgram, isFilterApply, grantDashboards]
  );

  const programColumns = useMemo(() => getProgramColumns({ t }), [t]);
  const grantsColumns = useMemo(() => getGrantColumns({ t }), [t]);
  return (
    <div className="dashboard">
      <div className="header">
        <div className="current-month">{`${t("general.monthTitle")} - ${t(
          `general.month.${month}`
        )}`}</div>
        <div className="actions gap-2">
          <button
            onClick={() => {
              setIsFilterAlly((state) => !state);
              if (isFilterApply) {
                setCurrentProgram(null);
              }
            }}
            disabled={!currentProgram}
            className="btn btn-primary"
          >
            {t(isFilterApply ? "general.clearFilter" : "general.filter")}
          </button>
          <DownloadButtonComponent
            fileName="dashboard.xlsx"
            label={t("general.export")}
            downloadHandler={() =>
              fileApi.downloadFile(
                `${ROUTE_PATHS.company}/${company.id}/dashboard-export${
                  isFilterApply ? `?currentProgram=${currentProgram}` : ""
                }`
              )
            }
          />
        </div>
      </div>
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        <div className="container-fluid">
          <div className="half-container">
            <Table
              columns={programColumns}
              data={filteredProgramDashboards}
              presortBy={{
                property: "programCode",
                sortType: "number",
                sortDirection: "up",
              }}
              onRowClick={({ programCode }) => setCurrentProgram(programCode)}
              isRowActive={(row) => row?.programCode === currentProgram}
              classNames={{
                row: ({ currentMonthGrantFunding, yearGrantFunding }) =>
                  +currentMonthGrantFunding === 0 || +yearGrantFunding === 0
                    ? "red-text-programs"
                    : "",
              }}
            />
          </div>
          <div className="half-container">
            <Table
              columns={grantsColumns}
              data={filteredGrantDashboards}
              presortBy={{
                property: "grantCode",
                sortType: "number",
                sortDirection: "up",
              }}
              onRowClick={({ programCode }) => setCurrentProgram(programCode)}
              isRowActive={(row) => row?.programCode === currentProgram}
              classNames={{
                row: ({ remainingBillingMonths }) =>
                  +remainingBillingMonths <= 3 ? "red-text-grants" : "",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
