import React from "react";
import "./companies.component.scss";
import { Route, Routes } from "react-router-dom";
import CompaniesList from "./list/companies-list.component";
import { CompanyProvider } from "../../context/company-context";
import CompanyRoutesComponent from "./comany-routes/company-routes.component";
import CompanyComponent from "../company/company.component";

export default function Companies(): JSX.Element {
  return (
    <CompanyProvider>
      <Routes>
        <Route path="/" element={<CompaniesList />} />
        <Route path="/company/:id/*" element={<CompanyRoutesComponent />} />
        <Route path="/company/" element={<CompanyComponent />} />
      </Routes>
    </CompanyProvider>
  );
}
