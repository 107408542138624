import React from "react";
import "./form.input.component.scss";
import { UseFormRegisterReturn } from "react-hook-form";

interface FormInputComponentProps {
  label?: string;
  error?: string | undefined;
  inputProps: UseFormRegisterReturn;
  property: string;
  readonly?: boolean;
  type?: string;
  prefix?: string;
}

export default function FormInputComponent({
  label,
  error,
  inputProps,
  property,
  readonly = false,
  type = "text",
  prefix,
}: FormInputComponentProps): JSX.Element {
  return (
    <div className="mb-3 form-field">
      <label htmlFor={property} className="form-label">
        {label}
      </label>
      {prefix ? (
        <div className="input-group">
          <span className="input-group-text">{prefix}</span>
          <input
            readOnly={readonly}
            type="text"
            className="form-control"
            id={property}
            {...inputProps}
          />
        </div>
      ) : (
        <input
          readOnly={readonly}
          type={type}
          className="form-control"
          id={property}
          {...inputProps}
        />
      )}
      {error && <div className="error">{error}</div>}
    </div>
  );
}
