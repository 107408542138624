import React from "react";
import "./job-function-form.component.scss";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormSelectComponent from "../form-fields/form-select.component";
import { FUNCTION_TYPES } from "../../../consts/jobFunction";
import FormInputComponent from "../form-fields/form-input.component";

interface JobFunctionFormComponentProps {
  onClose: () => void;
  onSubmit: (data: FieldValues) => void;
  entity?: FieldValues;
}

export default function JobFunctionFormComponent({
  onClose,
  onSubmit,
  entity,
}: JobFunctionFormComponentProps): JSX.Element {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    ...(entity && { defaultValues: entity }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="job-function-form gap-4 row">
        <div className="col">
          <div className="mb-3">
            <FormInputComponent
              label={t("company.tabs.jobFunctions.form.description")}
              inputProps={register("description", {
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property={"description"}
              error={errors?.description?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              label={t("company.tabs.jobFunctions.form.acronym")}
              inputProps={register("acronym", {
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property={"code"}
              error={errors?.acronym?.message}
            />
          </div>
          <div className="mb-3">
            <FormInputComponent
              label={t("company.tabs.jobFunctions.form.region")}
              inputProps={register("region", {
                required: {
                  value: true,
                  message: t("general.form.validation.required"),
                },
              })}
              property={"code"}
              error={errors?.region?.message}
            />
          </div>
          <div className="mb-3">
            <FormSelectComponent
              label={t(`company.tabs.jobFunctions.form.type`)}
              inputProps={register("type")}
              property="type"
              options={FUNCTION_TYPES.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </div>
          <div className="gap-2 modal-buttons">
            <button onClick={onClose} className="btn w-50 btn-secondary">
              {t("general.cancel")}
            </button>
            <button type="submit" className="btn w-50 btn-primary">
              {t("general.save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
