import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Dashboard from "./main-dashboard/dashboard.component";
import ProgramDashboard from "./program-dashboard/parogram-dashboard.component";
import GrantDashboard from "./grant-dashboard/grant-dashboard.component";

export const LinkComponent = ({
  label,
  path,
}: {
  label: string;
  path: string;
}): JSX.Element => <Link to={path}>{label}</Link>;
export default function DashboardRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="program/:programId" element={<ProgramDashboard />} />
      <Route path="grant/:grantId" element={<GrantDashboard />} />
    </Routes>
  );
}
