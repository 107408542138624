import React, { useMemo } from "react";
import "./budget.component.scss";
import Table from "../../../components/shared/table/table.component";
import { Grant, GrantFinData } from "../../../interfaces/interfaces";
import BackTo from "../../../components/shared/back-to/back-to.component";
import { ROUTE_PATHS } from "../../../consts";
import HistoryModalComponent from "./history-modal/history-modal.componentn";
import fileApi from "../../../api/file-api";
import { DownloadButtonComponent } from "../../../components/shared/download-button/download-button.component";
import { getColumns } from "./helpers";
import { useBudgetData } from "./useBudgetData";

export interface ModificationHistory {
  id: string;
  date: string;
  amount: string;
  account: string;
  description?: string;
}

export default function BudgetDataComponent({
  grant,
}: {
  grant: Grant | undefined;
}): JSX.Element {
  const {
    t,
    renderAllModificationHeader,
    isEditing,
    budgetModifications,
    setBudgetModifications,
    isModificationsBalance,
    showModificationHistoryModal,
    setShowModificationHistoryModal,
    budgetModificationsHistory,
    pathname,
    modifyBudget,
    company,
    currentBudget,
    grantId,
  } = useBudgetData();

  const columns = useMemo(
    () =>
      getColumns({
        t,
        renderAllModificationHeader,
        isEditing,
        budgetModifications,
        setBudgetModifications,
        isModificationsBalance,
      }),
    [
      budgetModifications,
      isEditing,
      isModificationsBalance,
      renderAllModificationHeader,
      setBudgetModifications,
      t,
    ]
  );

  return (
    <div className="grant-budget">
      <HistoryModalComponent
        show={showModificationHistoryModal}
        onClose={() => setShowModificationHistoryModal(false)}
        data={budgetModificationsHistory}
      />
      <div className="header">
        <BackTo
          link={pathname.split("/budget")[0]}
          title={t("company.tabs.grants.finData.backToGrant", {
            id: grant?.code,
          })}
        />
        <div className="actions gap-2">
          <button className="btn btn-primary">
            {t("company.tabs.grants.finData.grantRenewal").toString()}
          </button>
          <button
            disabled={isEditing && !isModificationsBalance}
            className="btn btn-primary"
            onClick={modifyBudget}
          >
            {isEditing
              ? t("general.save").toString()
              : t("company.tabs.grants.finData.modifyBudget").toString()}
          </button>
          <DownloadButtonComponent
            fileName="grant-budget.xlsx"
            label={t("general.export")}
            downloadHandler={() =>
              fileApi.downloadFile(
                `${ROUTE_PATHS.company}/${company.id}/grant-budgets-export?grantId.equals=${grantId}`
              )
            }
          />
        </div>
      </div>
      <Table
        columns={columns}
        isFooter={true}
        data={currentBudget}
        filter={(row: GrantFinData) =>
          row?.account?.accountGrouping?.type !== "Program Expense"
        }
        filteredTotalTitle={t(
          "company.tabs.grants.finData.list.separatorTitle"
        )}
        totalTitle={t("company.tabs.grants.finData.list.totalTitle")}
        restTotalTitle={t("company.tabs.grants.finData.list.totalSecondTitle")}
      />
    </div>
  );
}
