import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import { Program, UploadResponse } from "../interfaces/interfaces";
import { FieldValues } from "react-hook-form";

async function getPrograms(
  companyId: string | undefined
): Promise<Array<Program>> {
  const { data } = await httpClient.get(`company/${companyId}/programs/count`);
  return httpClient
    .get(`company/${companyId}/programs?size=${data}`)
    .then((response: AxiosResponse): Array<Program> => response.data);
}

async function getShortPrograms(
  companyId: string | undefined,
  activePeriod: string | undefined
): Promise<Array<Program>> {
  return httpClient
    .get(
      `company/${companyId}/programs/short${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`
    )
    .then((response: AxiosResponse): Array<Program> => response.data);
}

async function getGLAllocationPrograms(
  companyId: string,
  activePeriod: string | undefined
): Promise<Array<Program>> {
  return httpClient
    .get(
      `company/${companyId}/programs/grant-transaction/allocation${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`
    )
    .then((response: AxiosResponse): Array<Program> => response.data);
}

async function getPRAllocationPrograms(
  companyId: string,
  activePeriod: string | undefined
): Promise<Array<Program>> {
  return httpClient
    .get(
      `company/${companyId}/programs/payroll-register/allocation${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`
    )
    .then((response: AxiosResponse): Array<Program> => response.data);
}

async function getGLPurgePrograms(
  companyId: string,
  activePeriod: string | undefined
): Promise<Array<Program>> {
  return httpClient
    .get(
      `company/${companyId}/programs/grant-transaction/purge${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`
    )
    .then((response: AxiosResponse): Array<Program> => response.data);
}

async function getPRPurgePrograms(
  companyId: string,
  activePeriod: string | undefined
): Promise<Array<Program>> {
  return httpClient
    .get(
      `company/${companyId}/programs/payroll-register/purge${
        activePeriod ? `?activePeriod=${activePeriod}` : ""
      }`
    )
    .then((response: AxiosResponse): Array<Program> => response.data);
}

async function getProgram(
  companyId: string | undefined,
  progarmId: string | undefined
): Promise<Program> {
  return httpClient
    .get(`company/${companyId}/programs/${progarmId}`)
    .then((response: AxiosResponse): Program => response.data);
}

async function createProgram(
  companyId: string | undefined,
  program: FieldValues
): Promise<Program> {
  return httpClient
    .post(`company/${companyId}/programs`, {
      ...program,
      company: { id: companyId },
      activated: true,
    })
    .then((response: AxiosResponse): Program => response.data);
}

async function updateProgram(
  companyId: string,
  program: FieldValues
): Promise<Program> {
  return httpClient
    .put(`company/${companyId}/programs/${program.id}`, program)
    .then((response: AxiosResponse): Program => response.data);
}

async function uploadPrograms(
  data: FormData,
  companyId: string | undefined
): Promise<UploadResponse> {
  return httpClient
    .post(`company/${companyId}/programs/file-upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse): UploadResponse => response.data);
}

const programsApi = {
  getPrograms,
  getShortPrograms,
  getGLAllocationPrograms,
  getPRAllocationPrograms,
  getPRPurgePrograms,
  getGLPurgePrograms,
  uploadPrograms,
  createProgram,
  updateProgram,
  getProgram,
};

export default programsApi;
