import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface FormAutocompleteProps {
  label: string;
  error?: string | undefined;
  inputProps: UseFormRegisterReturn;
  property: string;
  readonly?: boolean;
  options?: Array<{ code: string; name: string }>;
}

export default function FormAutocompleteComponent({
  label,
  error,
  inputProps,
  property,
  readonly = false,
  options = [],
}: FormAutocompleteProps): JSX.Element {
  return (
    <div className="mb-3 form-field ">
      <label htmlFor={property} className="form-label">
        {label}
      </label>
      <input
        readOnly={readonly}
        type="text"
        className="form-control"
        id={property}
        list={property + "_options"}
        {...inputProps}
      />
      <datalist id={property + "_options"}>
        {options.map(({ code, name }) => (
          <option key={code} value={code} label={name} />
        ))}
      </datalist>
      {error && <div className="error">{error}</div>}
    </div>
  );
}
