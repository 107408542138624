import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import "./company-routes.component.scss";
import CompanyComponent from "../../company/company.component";
import React, { useContext, useEffect } from "react";
import MCOAComponent from "../../mcoa/MCOA.component";
import { COMPANY_PARTS, ROUTE_PATHS } from "../../../consts";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Programs from "../../programs/programs.component";
import { CompanyContext } from "../../../context/company-context";
import companyApi from "../../../api/company-api";
import { Company } from "../../../interfaces/interfaces";
import BackTo from "../../../components/shared/back-to/back-to.component";
import JobFunctions from "../../job-functions/job-functions.component";
import Grants from "../../grants/grants.component";
import GlAccountListComponent from "../../gl-account/gl-account.component";
import { DocumentsComponent } from "../../documents/documents.component";
import UserDetailsComponent from "../../user-details/user-details.component";
import PayrollRegisterComponent from "../../payroll-register/payroll-register.component";
import BillingAllocationComponent from "../../billing-allocation/billing-allocation.component";

export default function CompanyRoutesComponent(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const { setCompany, company } = useContext(CompanyContext);
  useEffect(() => {
    if (!id) {
      setCompany(null);
      return;
    }
    companyApi.getCompany(id).then((data: Company): void => setCompany(data));
    return () => {
      setCompany(null);
    };
  }, [id, setCompany]);
  return (
    <>
      <header className="company-header">
        <div>
          <BackTo
            link={`/${ROUTE_PATHS.companies}`}
            title={t(`companies.form.${id ? "editCompany" : "createCompany"}`, {
              name: company?.name,
            })}
          />
          <div className="company-id gap-2">
            <label htmlFor="id">{t("company.tabs.companyForm.id")}</label>
            <div>
              <input
                type="text"
                readOnly
                className="form-control"
                id="id"
                value={company?.id8Digits}
              />
            </div>
          </div>
        </div>
        <nav className="nav nav-pills nav-fill">
          {Object.values(COMPANY_PARTS).map((item: string) => (
            <NavLink
              key={item}
              className={(navData) =>
                navData.isActive
                  ? "nav-link grant-budget active"
                  : "nav-link grant-budget"
              }
              to={item}
            >
              {t(`company.tabs.${item}.title`)}
            </NavLink>
          ))}
        </nav>
      </header>
      {company && (
        <div className="content">
          <Routes>
            <Route path="/details" element={<CompanyComponent />} />
            <Route path="/users" element={<UserDetailsComponent />} />
            <Route path="/mcoa" element={<MCOAComponent />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/job-functions" element={<JobFunctions />} />
            <Route path="/grants/*" element={<Grants />} />
            <Route path="/gl" element={<GlAccountListComponent />} />
            <Route path="/documents/*" element={<DocumentsComponent />} />
            <Route
              path="/billing-allocation"
              element={<BillingAllocationComponent />}
            />
            <Route
              path="/payrollRegister/"
              element={<PayrollRegisterComponent />}
            />
            <Route path="/" element={<Navigate to="details" replace />} />
          </Routes>
        </div>
      )}
    </>
  );
}
