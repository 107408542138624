export const ACCOUNT_CLASSES = {
  EXPENSE: "Expense",
  INCOME: "Income",
  LIABILITIES: "Liabilities",
  PAYROLL_EXPENSES: "Payroll Expenses",
  ASSET: "Asset",
};

export const ACCOUNT_GROUPING_TYPE = {
  SYSTEM_ALLOCATION: "System Allocation Account",
  PROGRAM_EXPENSE: "Program Expense",
  EMPLOYEE_COMPENSATION: "Employee Compensation",
  EMPLOYEE_BENEFITS: "Employees Benefits",
};
