import React, { useContext, useMemo } from "react";
import "./company.component.scss";
import { useTranslation } from "react-i18next";
import { COMPANY_TYPES } from "../../consts";
import CompanyForm from "../../components/forms/company-form/company-form.component";
import { CompanyContext } from "../../context/company-context";

export default function CompanyComponent(): JSX.Element | null {
  const { company } = useContext(CompanyContext);
  const { t } = useTranslation();

  const renderCompanyTypesTabs = useMemo<Array<JSX.Element>>(
    (): Array<JSX.Element> =>
      Object.values(COMPANY_TYPES).map(
        (item: string, index: number): JSX.Element => (
          <li key={item} className="nav-item" role="presentation">
            <button
              className={`nav-link ${index === 0 && "active"} ${
                index > 0 && "disabled"
              }`}
              id={`${item}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#${item}`}
              type="button"
              role="tab"
              aria-controls={item}
              aria-selected={index === 0}
            >
              {t(`company.typeTabs.${item}.title`)}
            </button>
          </li>
        )
      ),
    [t]
  );

  return (
    <>
      {!company && (
        <ul className="nav nav-tabs" id="companyTypeTabs" role={"tablist"}>
          {renderCompanyTypesTabs}
        </ul>
      )}
      <div>
        <CompanyForm entity={company} />
      </div>
    </>
  );
}
