import { Company, User } from "../interfaces/interfaces";
import { httpClient } from "../core/http-client/http-client";
import { AxiosResponse } from "axios";

const baseUrl = "user-permissions/";

export const getUsersCompanies = async <T>(): Promise<T> => {
  return httpClient
    .get(`${baseUrl}users`)
    .then((response: AxiosResponse): T => response.data);
};

export const getCompaniesUsers = async (): Promise<
  Record<string, Array<User>>
> => {
  return httpClient
    .get(`${baseUrl}companies`)
    .then(
      (response: AxiosResponse): Promise<Record<string, Array<User>>> =>
        response.data
    );
};

export const createPermission = async <T>(data: T): Promise<T> => {
  return httpClient
    .post(baseUrl, data)
    .then((response: AxiosResponse): T => response.data);
};

export const getCompaniesByUser = async (
  userId: string
): Promise<Array<Company>> =>
  httpClient
    .get(`company/${userId}/companies`)
    .then((response: AxiosResponse): Array<Company> => response.data);

const permissionsApi = {
  getUsersCompanies,
  getCompaniesUsers,
  getCompaniesByUser,
  createPermission,
};

export default permissionsApi;
