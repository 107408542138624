import get from "lodash/get";
import { SortDirection } from "../table.component";

export function compare<T>(
  a: T,
  b: T,
  property: string | number | symbol,
  direction: SortDirection,
  sortType: "string" | "number"
) {
  const firstValue =
    sortType === "number"
      ? +get(a, property) || 0
      : get(a, property)?.toString()?.toLowerCase() || "";
  const secondValue =
    sortType === "number"
      ? +get(b, property) || 0
      : get(b, property)?.toString()?.toLowerCase() || "";
  if (firstValue < secondValue) {
    return direction === "up" ? -1 : 1;
  }
  if (firstValue > secondValue) {
    return direction === "up" ? 1 : -1;
  }
  return 0;
}
