import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import { GlTransaction } from "../interfaces/interfaces";

async function getPeriodsToGrants(
  companyId: string
): Promise<{ string: Array<string> }> {
  return httpClient
    .get(
      `company/${companyId}/document-management/accounting-periods-to-grants`
    )
    .then((response: AxiosResponse) => response.data);
}

async function getTransactions(
  companyId: string,
  accountingPeriod: string,
  grantCode: string
): Promise<GlTransaction[]> {
  return httpClient
    .get(
      `company/${companyId}/document-management/grant-transanctions?accountingPeriod=${accountingPeriod}${
        grantCode && `&grantCode=${grantCode}`
      }`
    )
    .then((response: AxiosResponse) => response.data);
}

async function uploadInvoices(
  data: FormData,
  companyId: string | undefined,
  accountingPeriod: string,
  grantCode: string
): Promise<AxiosResponse<any, any>> {
  return httpClient
    .post(
      `company/${companyId}/document-management/upload-files?accountingPeriod=${accountingPeriod}&grantCode=${grantCode}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response: AxiosResponse): AxiosResponse<any, any> => response.data);
}

const documentsApi = {
  getPeriodsToGrants,
  getTransactions,
  uploadInvoices,
};

export default documentsApi;
