import {
  Dispatch,
  EffectCallback,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import userApi from "../api/user-api";

export interface UseCountInterface {
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  setCountCircle: Dispatch<SetStateAction<number>>;
  disabled: boolean;
  resentMfaCode: () => void;
}

const useCount = (key: null | string, start: number): UseCountInterface => {
  const [count, setCount] = useState<number>(start);
  const [countCircle, setCountCircle] = useState<number>(0);

  const disabled = useMemo<boolean>(() => count > 0, [count]);

  useEffect((): ReturnType<EffectCallback> => {
    if (!key) {
      return;
    }
    const interval: ReturnType<typeof setInterval> = setInterval((): void => {
      setCount((state: number): number => {
        if (state === 0) {
          clearInterval(interval);
          return state;
        }
        return state - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [key, countCircle]);

  const resentMfaCode = useCallback((): void => {
    if (!key || disabled) {
      return;
    }
    userApi.resentMfaCode(key).then(() => {
      setCount(start);
      setCountCircle((circle: number) => circle + 1);
    });
  }, [key, setCount, setCountCircle, disabled, start]);

  return {
    count,
    setCount,
    setCountCircle,
    disabled,
    resentMfaCode,
  };
};

export default useCount;
