import React, { useContext, useEffect } from "react";
import "./company-user.component.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import CompanyComponent from "../company/company.component";
import UserDetailsComponent from "../user-details/user-details.component";
import MCOAComponent from "../mcoa/MCOA.component";
import Programs from "../programs/programs.component";
import JobFunctions from "../job-functions/job-functions.component";
import Grants from "../grants/grants.component";
import GlAccountListComponent from "../gl-account/gl-account.component";
import { DocumentsComponent } from "../documents/documents.component";
import PayrollRegisterComponent from "../payroll-register/payroll-register.component";
import { CompanyContext } from "../../context/company-context";
import permissionsApi from "../../api/permissions-api";
import { UserContext } from "../../context/user.context";
import BillingAllocationComponent from "../billing-allocation/billing-allocation.component";
import { useTranslation } from "react-i18next";
import { SettingsComponent } from "../documents/settings/settings.component";
import { DocumentList } from "../documents/list/list.component";
import DashboardRoutes from "../dashboard/dashboard-routes.component";

export default function CompanyUserComponent(): JSX.Element | null {
  const { user } = useContext(UserContext);
  const { setCompany, company } = useContext(CompanyContext);
  const { t } = useTranslation();

  useEffect(() => {
    permissionsApi.getCompaniesByUser(user.id).then((data) => {
      setCompany(data[0]);
    });
  }, [setCompany, user.id]);
  return company?.id ? (
    <div className="company-root">
      <div className="header">
        <div className="company-id gap-2">
          <label htmlFor="id">{t("company.tabs.companyForm.id")}</label>
          <div>
            <input
              type="text"
              readOnly
              className="form-control"
              id="id"
              value={company?.id8Digits}
            />
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/company" element={<CompanyComponent />} />
        <Route path="/dashboard/*" element={<DashboardRoutes />} />
        <Route path="/user-details" element={<UserDetailsComponent />} />
        <Route path="/mcoa" element={<MCOAComponent />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/job-functions" element={<JobFunctions />} />
        <Route path="/grants/*" element={<Grants />} />
        <Route path="/gl" element={<GlAccountListComponent />} />
        <Route path="/documents" element={<DocumentList />} />
        <Route path="/documents/settings" element={<SettingsComponent />} />
        <Route path="/documents/settings" element={<DocumentsComponent />} />
        <Route path="/payroll" element={<PayrollRegisterComponent />} />
        <Route
          path="/billing-allocation"
          element={<BillingAllocationComponent />}
        />
        <Route path="/*" element={<Navigate to="dashboard" replace />} />
      </Routes>
    </div>
  ) : null;
}
