import { AxiosResponse } from "axios";
import { httpClient } from "../core/http-client/http-client";
import { Company, User } from "../interfaces/interfaces";
import utils from "./utils";
import { USERS_ROLES_TO_DTO } from "../consts";

const baseUrl = "admin/users";
export const buildFromDto = (user: User): User => ({
  ...user,
  role: utils.getRole(user.authorities),
});
export const getUser = async (login: string | null): Promise<User> => {
  return httpClient
    .get(`${baseUrl}/${login}`)
    .then((response: AxiosResponse): User => buildFromDto(response.data));
};

export const getUsers = async (): Promise<Array<User>> => {
  return httpClient
    .get(`${baseUrl}/?size=500`)
    .then(
      (response: AxiosResponse): Array<User> => response.data.map(buildFromDto)
    );
};

export const createUser = async (user: User): Promise<User> => {
  return httpClient
    .post(baseUrl, {
      ...user,
      authorities: [USERS_ROLES_TO_DTO[user.role]],
    })
    .then((response: AxiosResponse) => buildFromDto(response.data));
};

export const resentMfaCode = async (mfaKey: string): Promise<string> => {
  return httpClient
    .post("mfa/resend-mfacode", { mfaKey })
    .then((response: AxiosResponse): string => response.data);
};

export const updateUer = async <T>(user: T): Promise<T> => {
  return httpClient
    .put(baseUrl, user)
    .then((response: AxiosResponse): T => response.data);
};

export const deleteUser = async <T>(login: string): Promise<T> => {
  return httpClient
    .delete(`${baseUrl}/${login}`)
    .then((response: AxiosResponse) => response.data);
};

export const sendInvite = async <T>(id: string | undefined): Promise<T> => {
  return httpClient
    .post(`${baseUrl}/send-invite/${id}`)
    .then((response: AxiosResponse) => response.data);
};

export const getUserCompanies = async (
  userId: string | undefined
): Promise<Company[]> => {
  return httpClient
    .get(`company/${userId}/companies`)
    .then((response: AxiosResponse) => response.data);
};

const userApi = {
  getUser,
  getUsers,
  createUser,
  resentMfaCode,
  deleteUser,
  sendInvite,
  updateUer,
  getUserCompanies,
};

export default userApi;
