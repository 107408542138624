import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./grant.form.scss";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Company, Grant } from "../../../interfaces/interfaces";
import { formatNumber } from "../../../utils";
import { CompanyContext } from "../../../context/company-context";
import { CURRENCY_SIGNS } from "../../../consts";
import programsApi from "../../../api/programs-api";
import { useErrorHandler } from "../../../hooks/useErrorHandler";
import { INPUT_TYPES, PHONE_NUMBER_VALIDATOR } from "../../../consts/forms";
import FormSelectComponent from "../form-fields/form-select.component";
import FormInputComponent from "../form-fields/form-input.component";
import grantApi from "../../../api/grants-api";
import FormPhoneComponent from "../form-fields/form-phone.component";

const GRANT_CLASSIFICATIONS = {
  government: "Government",
  private: "Private",
};

interface GrantFormProps {
  entity?: Grant | null;
  submit: (data: FieldValues) => void;
}

interface FormInput {
  property: keyof Grant | "program.id";
  type: "text" | "select" | "date" | "radio" | "phone";
  options?: Array<{ value: string; label: string }>;
  visible: boolean;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  pattern?: RegExp;
}

export default function GrantForm({
  entity,
  submit,
}: GrantFormProps): JSX.Element {
  const { company }: Record<"company", Company> = useContext(CompanyContext);
  const [programs, setPrograms] = useState<{ value: string; label: string }[]>([
    { value: "", label: "" },
  ]);
  const [grants, setGrants] = useState<Grant[]>([]);
  const errorHandler = useErrorHandler();
  const isGrantOffset = entity?.grantOffset;

  const defaultFormValues = useMemo(() => {
    const programId = programs?.[0]?.value || "";
    return {
      code: "",
      name: "",
      program: {
        id: programId,
      },
      grantMatchType: "Matching",
      matchRequirement: "Yes",
      matchingGrantCodeId: "",
      description: "",
      grantClassification: GRANT_CLASSIFICATIONS.government,
      grantSubClassification: "Federal",
      federalAgancy: "",
      federalId: "",
      status: undefined,
      billingFrequency: "Annual",
      grantBudgetType: "CONTIGUOUS",
      totalGrantAward: "",
      grantBudgetStart: undefined,
      grantBudgetEnd: undefined,
      phoneNumber: "",
    };
  }, [programs]);

  const {
    register,
    watch,
    handleSubmit,
    clearErrors,
    resetField,
    formState: { errors, isDirty },
  } = useForm({
    ...(entity
      ? {
          defaultValues: {
            ...entity,
            totalGrantAward: formatNumber(+entity.totalGrantAward),
            program: entity.program || {
              id: "all",
            },
          },
        }
      : {
          defaultValues: { ...defaultFormValues },
        }),
  });

  const loadPrograms = useCallback(() => {
    programsApi
      .getPrograms(company.id)
      .then(
        (data) => {
          const programOptions = data.map(({ code, id }) => ({
            value: id,
            label: code,
          }));
          if (isGrantOffset) {
            programOptions.push({ value: "all", label: "All" });
          }
          setPrograms(programOptions);
        },
        (err) => errorHandler(err)
      )
      .finally(() => {
        resetField("program.id");
      });
  }, [company.id, errorHandler, isGrantOffset, resetField]);

  const { t } = useTranslation();

  const grantClassification = watch("grantClassification");
  const grantMatchType = watch("grantMatchType");
  const program = watch("program.id");
  const matchRequirement = watch("matchRequirement");
  const startDate = watch("grantBudgetStart");
  const endDate = watch("grantBudgetEnd");
  const phoneNumber = watch("phoneNumber");

  useEffect(() => {
    if (endDate && startDate && endDate > startDate) {
      clearErrors();
    }
  }, [clearErrors, endDate, startDate]);

  const loadGrants = useCallback(() => {
    if (program) {
      grantApi.getGrants(company.id, `programId.in=${program}`).then(
        (data) =>
          setGrants(data.filter((item) => +item?.program?.id === +program)),
        (err) => errorHandler(err)
      );
    }
  }, [company.id, errorHandler, program]);

  useEffect(() => {
    loadGrants();
  }, [loadGrants]);

  useEffect(() => {
    loadPrograms();
  }, [loadPrograms]);

  const formKey = useMemo(
    () => programs.length + grants.length,
    [programs, grants]
  );

  const INPUTS = useMemo<Array<Array<FormInput>>>(
    () => [
      [
        {
          property: "code",
          type: INPUT_TYPES.text,
          visible: true,
          required: true,
          error: errors?.code?.message,
        },
        {
          property: "name",
          type: INPUT_TYPES.text,
          visible: true,
          disabled: isGrantOffset,
          required: true,
          error: errors?.name?.message,
        },
        {
          property: "program.id",
          type: INPUT_TYPES.select,
          disabled: isGrantOffset,
          options: programs,
          visible: true,
          required: true,
          error: errors?.program?.id?.message,
        },
        {
          property: "grantMatchType",
          type: INPUT_TYPES.select,
          options: [
            { value: "Matching", label: "Matching" },
            { value: "Funding", label: "Funding" },
          ],
          visible: !isGrantOffset,
          required: true,
          error: errors?.grantMatchType?.message,
        },
        {
          property: "matchRequirement",
          type: INPUT_TYPES.select,
          options: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ],
          visible: !isGrantOffset && grantMatchType === "Funding",
          required: true,
          error: errors?.matchRequirement?.message,
        },
        {
          property: "matchingGrantCodeId",
          type: INPUT_TYPES.select,
          options: grants.map(({ id, code }) => ({ value: id, label: code })),
          visible:
            !isGrantOffset &&
            grantMatchType === "Funding" &&
            matchRequirement === "Yes",
          required: true,
          error: errors?.matchingGrantCodeId?.message,
        },
      ],
      [
        {
          property: "description",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
          required: true,
          error: errors?.description?.message,
        },
        {
          property: "contactId",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
        },
        {
          property: "grantClassification",
          type: INPUT_TYPES.select,
          options: [
            { value: "Government", label: "Government" },
            { value: "Private", label: "Private" },
          ],
          visible: !isGrantOffset,
          required: true,
          error: errors?.grantClassification?.message,
        },
        {
          property: "grantSubClassification",
          type: INPUT_TYPES.select,
          options:
            grantClassification === GRANT_CLASSIFICATIONS.government
              ? [
                  { value: "Federal", label: "Federal" },
                  { value: "State", label: "State" },
                  { value: "Local", label: "Local" },
                ]
              : [
                  { value: "Corporate", label: "Corporate" },
                  { value: "Foundation", label: "Foundation" },
                  { value: "Other", label: "Other" },
                ],
          visible: !isGrantOffset,
          required: true,
          error: errors?.grantSubClassification?.message,
        },
        {
          property: "federalAgancy",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
        },
        {
          property: "federalId",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
        },
      ],
      [
        {
          property: "status",
          type: INPUT_TYPES.radio,
          visible: true,
        },
        {
          property: "billingFrequency",
          type: INPUT_TYPES.select,
          options: [
            { value: "Annual", label: "Annual" },
            { value: "Monthly", label: "Monthly" },
            { value: "Semi_Annual", label: "Semi Annual" },
            { value: "Quarterly", label: "Quarterly" },
          ],
          visible: !isGrantOffset,
          required: true,
          error: errors?.billingFrequency?.message,
        },
        {
          property: "grantBudgetType",
          type: INPUT_TYPES.select,
          options: [
            { value: "CONTIGUOUS", label: "Contiguous" },
            { value: "ANNUAL", label: "Annual" },
          ],
          visible: !isGrantOffset,
          required: true,
          error: errors?.grantBudgetType?.message,
        },
      ],
      [
        {
          property: "totalGrantAward",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
          required: true,
          error: errors?.totalGrantAward?.message,
        },
        {
          property: "period",
          type: INPUT_TYPES.date,
          disabled: isGrantOffset,
          visible: true,
        },
        {
          property: "phoneNumber",
          type: INPUT_TYPES.phone,
          visible: !isGrantOffset,
        },
        {
          property: "emailAddress",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
        },
        {
          property: "zipCode",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
        },
        { property: "state", type: INPUT_TYPES.text, visible: !isGrantOffset },
        { property: "city", type: INPUT_TYPES.text, visible: !isGrantOffset },
        {
          property: "emailAddresses",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
        },
        {
          property: "emailAddresses2",
          type: INPUT_TYPES.text,
          visible: !isGrantOffset,
        },
      ],
    ],
    [
      errors?.code?.message,
      errors?.name?.message,
      errors?.program?.id?.message,
      errors?.grantMatchType?.message,
      errors?.matchRequirement?.message,
      errors?.matchingGrantCodeId?.message,
      errors?.description?.message,
      errors?.grantClassification?.message,
      errors?.grantSubClassification?.message,
      errors?.billingFrequency?.message,
      errors?.grantBudgetType?.message,
      errors?.totalGrantAward?.message,
      programs,
      isGrantOffset,
      grantMatchType,
      grants,
      matchRequirement,
      grantClassification,
    ]
  );

  const isColumnNotEmpty = useCallback(
    (column: FormInput[]) => column.some((item) => item.visible),
    []
  );

  return (
    <form id="grant-form" key={formKey} onSubmit={handleSubmit(submit)}>
      <div className="form-header">
        <button disabled={!isDirty} type="submit" className="btn btn-primary">
          {t("general.update")}
        </button>
      </div>
      <div className="grant gap-4 row">
        {INPUTS.map((item: Array<FormInput>, index): JSX.Element | null =>
          isColumnNotEmpty(item) ? (
            <div key={item.length + index} className="col">
              {item.map((input: FormInput): JSX.Element | null => {
                if (!input.visible) {
                  return null;
                }
                switch (input.type) {
                  case INPUT_TYPES.date: {
                    return (
                      <div key={input.property} className="mb-3">
                        <div className="period gap-4">
                          <div>
                            <FormInputComponent
                              readonly={input.disabled}
                              type="date"
                              label={t("company.tabs.grants.form.startDate")}
                              inputProps={register("grantBudgetStart", {
                                required: {
                                  value: true,
                                  message: t(
                                    "general.form.validation.required"
                                  ),
                                },
                              })}
                              property={"startDate"}
                              error={errors?.grantBudgetStart?.message}
                            />
                          </div>
                          <div>
                            <FormInputComponent
                              label={t("company.tabs.grants.form.endDate")}
                              type="date"
                              readonly={input.disabled}
                              error={errors?.grantBudgetEnd?.message}
                              inputProps={register("grantBudgetEnd", {
                                required: {
                                  value: true,
                                  message: t(
                                    "general.form.validation.required"
                                  ),
                                },
                                validate: () =>
                                  (startDate &&
                                    endDate &&
                                    startDate <= endDate) ||
                                  t("general.form.validation.dateBigger"),
                              })}
                              property={"endDate"}
                            />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                  }
                  case INPUT_TYPES.phone: {
                    return (
                      <FormPhoneComponent
                        key={input.property}
                        error={errors?.phoneNumber?.message}
                        value={phoneNumber}
                        formProps={register("phoneNumber", {
                          pattern: {
                            value: PHONE_NUMBER_VALIDATOR,
                            message: t("general.form.validation.phone"),
                          },
                        })}
                      />
                    );
                    break;
                  }
                  case INPUT_TYPES.radio: {
                    return (
                      <div key={input.property}>
                        <div>{t("company.tabs.grants.form.status")}</div>
                        <div className="mb-3 btn-group" role="group">
                          <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="type1">
                              {t("general.active")}
                            </label>
                            <input
                              disabled
                              className="form-check-input"
                              type="radio"
                              id="type1"
                              {...register("isActive")}
                              value="active"
                            />
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              disabled
                              className="form-check-input"
                              type="radio"
                              id="type2"
                              {...register("isActive")}
                              value="inactive"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="typeSubsidiary"
                            >
                              {t("general.inactive")}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                    break;
                  }
                  case INPUT_TYPES.select: {
                    return (
                      <FormSelectComponent
                        readonly={input.disabled}
                        key={input.property}
                        label={t(`company.tabs.grants.form.${input.property}`)}
                        inputProps={register(
                          input.property,
                          input?.required
                            ? {
                                required: {
                                  value: true,
                                  message: t(
                                    "general.form.validation.required"
                                  ),
                                },
                              }
                            : undefined
                        )}
                        property={input.property}
                        error={input.error}
                        {...(input.options && { options: input.options })}
                      />
                    );
                  }
                  default:
                    return (
                      <FormInputComponent
                        key={input.property}
                        readonly={input.disabled}
                        label={t(`company.tabs.grants.form.${input.property}`, {
                          currency: CURRENCY_SIGNS[company.currencyName],
                        })}
                        inputProps={register(
                          input.property,
                          input?.required || input?.pattern
                            ? {
                                ...(input?.required && {
                                  required: {
                                    value: true,
                                    message: t(
                                      "general.form.validation.required"
                                    ),
                                  },
                                }),
                              }
                            : undefined
                        )}
                        property={input.property}
                        error={input.error}
                      />
                    );
                }
              })}
            </div>
          ) : null
        )}
      </div>
    </form>
  );
}
