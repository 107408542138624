import React from "react";
import "./documents.component.scss";
import { Routes } from "react-router-dom";
import { Route } from "react-router";
import { DocumentList } from "./list/list.component";
import { SettingsComponent } from "./settings/settings.component";

export function DocumentsComponent(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<DocumentList />} />
      <Route path="/settings" element={<SettingsComponent />} />
    </Routes>
  );
}
