import { JobFunction } from "../../interfaces/interfaces";
import { TFunction } from "i18next";

export const getColumns = (
  t: TFunction
): {
  header: string;
  property: keyof JobFunction;
  sort?: boolean;
}[] => [
  {
    header: t("company.tabs.jobFunctions.list.description"),
    property: "description",
    sort: true,
  },
  {
    header: t("company.tabs.jobFunctions.list.acronym"),
    property: "acronym",
    sort: true,
  },
  {
    header: t("company.tabs.jobFunctions.list.region"),
    property: "region",
    sort: true,
  },
  {
    header: t("company.tabs.jobFunctions.list.type"),
    property: "type",
    sort: true,
  },
];
