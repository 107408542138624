import React from "react";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { UseFormRegisterReturn } from "react-hook-form";
import "./form-phone.component.scss";

interface FormPhonePros {
  disabled?: boolean;
  value: string;
  formProps: UseFormRegisterReturn;
  error?: string;
}

export default function FormPhoneComponent({
  disabled,
  value,
  formProps,
  error,
}: FormPhonePros): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="mb-3 phone-number">
      <label htmlFor="address" className="form-label">
        {t("company.tabs.companyForm.phoneNumber")}
      </label>
      <InputMask
        mask="(999) 999-9999"
        disabled={disabled}
        value={value || ""}
        {...formProps}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
}
