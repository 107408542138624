import { createContext, useEffect, useState } from "react";
import { User } from "../interfaces/interfaces";
import { getUser } from "../api/user-api";
import storage from "../core/storage/storage";

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext({} as any);

const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (loaded) {
      return;
    }
    const id: string | null = storage.get("id");
    getUser(id).then(
      (res: User) => {
        setUser(res);
        setLoaded(true);
      },
      () => {
        setLoaded(true);
        document.cookie = "";
        sessionStorage.clear();
      }
    );
  }, [loaded]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loaded,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
